@import '../../../../../sass/manifest';

.IncidentAddressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  padding: 0.45rem 0;
  font-size: 0.875rem;
  color: $v2maingreen;
  background-color: #ffff;

  p {
    font-weight: 600;
  }
}

@media only screen and (min-width: 1200px) {
  .IncidentAddressContainer {
    background-color: transparent;
    justify-content: flex-start;
    font-size: 1.05rem;
    padding: 0.2rem 0;
  }
}

@import '../../../sass/manifest';

.MainContainer {
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  align-items: center;
  justify-content: center;
  background-color: $v2maingreen;
  background: $radialBG;
  gap: 0.8rem;

  p {
    font-size: 0.8rem;
    text-align: center;
    text-transform: capitalize;
  }

  .Actions {
    padding: 0.8rem 0;
    border-top: 1px solid $periopsisGray;
    width: 90vw;
    width: 90dvw;
    display: flex;
    justify-content: space-between;
  }

  .ErrorContainer {
    display: flex;

    .LeftImageContainer,
    .RightImageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45vw;
      width: 45dvw;
      padding: 0 0.3rem;
      margin: 0 0.3rem;

      img {
        width: 90%;
      }
    }

    .LeftImageContainer {
      border-right: 1px solid $periopsisGray;

      img {
        animation: satallite 2.5s linear infinite alternate;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .MainContainer {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    align-items: center;
    justify-content: center;
    background-color: $v2maingreen;
    background: radial-gradient(
      circle,
      rgba(0, 76, 108, 1) 0%,
      rgba(0, 98, 139, 1) 48%,
      rgba(0, 79, 112, 1) 100%
    );
    gap: 0.8rem;

    p {
      font-size: 0.8rem;
      text-align: center;
      text-transform: capitalize;
    }

    .Actions {
      padding: 0.8rem 0;
      border-top: 1px solid $periopsisGray;
      width: 90vw;
      width: 90dvw;
      display: flex;
      justify-content: space-between;
    }

    .ErrorContainer {
      display: flex;

      .LeftImageContainer,
      .RightImageContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45vw;
        width: 45dvw;
        padding: 0 0.3rem;
        margin: 0 0.3rem;

        img {
          width: 90%;
        }
      }

      .LeftImageContainer {
        border-right: 1px solid $periopsisGray;

        img {
          animation: satallite 2.5s linear infinite alternate;
        }
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .MainContainer {
    gap: 1rem;

    p {
      font-size: 0.9rem;
      text-transform: uppercase;
    }

    .Actions {
      width: 70vw;
      width: 70dvw;
    }

    .ErrorContainer {
      display: flex;

      .LeftImageContainer,
      .RightImageContainer {
        width: 35vw;
        width: 35dvw;

        img {
          width: 90%;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .MainContainer {
    gap: 1.5rem;

    p {
      font-size: 1.05rem;
    }

    .Actions {
      width: 60vw;
      width: 60dvw;
    }

    .ErrorContainer {
      display: flex;

      .LeftImageContainer,
      .RightImageContainer {
        width: 30vw;
        width: 30dvw;

        img {
          width: 90%;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .MainContainer {
    p {
      font-size: 1.35rem;
      text-transform: uppercase;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .MainContainer {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    align-items: center;
    justify-content: center;
    background-color: $v2maingreen;
    background: radial-gradient(
      circle,
      rgba(0, 76, 108, 1) 0%,
      rgba(0, 98, 139, 1) 48%,
      rgba(0, 79, 112, 1) 100%
    );
    gap: 2.5rem;

    p {
      font-size: 1.55rem;
      text-transform: uppercase;
    }

    .Actions {
      padding: 0.8rem 0;
      border-top: 1px solid $periopsisGray;
      width: 50vw;
      width: 50dvw;
      display: flex;
      justify-content: space-between;
    }

    .ErrorContainer {
      display: flex;

      .LeftImageContainer,
      .RightImageContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25vw;
        width: 25dvw;
        padding: 0 0.3rem;
        margin: 0 0.3rem;

        img {
          width: 85%;
        }
      }

      .LeftImageContainer {
        border-right: 1px solid $periopsisGray;

        img {
          animation: satallite 2.5s linear infinite alternate;
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .MainContainer {
    p {
      font-size: 1.75rem;
    }
  }
}

@keyframes satallite {
  0% {
    transform: translate(8px, 10px) rotate(2deg) scale(1.03);
    opacity: 0.9;
  }

  50% {
    transform: translate(0, 0) rotate(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translate(-8px, -10px) rotate(-2deg) scale(0.98);
    opacity: 0.9;
  }
}

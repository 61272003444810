@import '../../../../sass/manifest';

.TableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  thead,
  tbody {
    width: inherit;
  }

  thead tr,
  tbody tr {
    display: flex;
    width: inherit;
  }

  thead tr th,
  tbody tr td {
    width: inherit;
    border: 1px solid #f0f0f0;
    padding: 0.15rem;
    text-align: center;
    color: $v2maingreen;
    font-size: 0.7rem;
  }

  thead tr th {
    background-color: $v2maingreen;
    padding: 0.1rem;
    color: #fff;
    font-weight: 500;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .TableContainer {
    thead tr th,
    tbody tr td {
      padding: 0.2rem;

      font-size: 0.9rem;
    }

    thead tr th {
      padding: 0.3rem;

      font-weight: 500;
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .TableContainer {
    thead tr th,
    tbody tr td {
      font-size: 1.02rem;
    }

    thead tr th {
      padding: 0.35rem;
      font-weight: 600;
    }
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

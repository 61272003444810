.ToastStyledMessageContainer {
  display: flex;
  flex-direction: column;
  padding: 0.2rem;

  h4 {
    font-weight: 800;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #fff;
    text-transform: capitalize;
  }

  p {
    margin: 0.3rem 0 0.45rem 0;
    text-align: left;
    font-size: 0.9rem;
  }

  ul {
    padding: 0.5rem;
    list-style: square;
    li {
      margin: 0.45rem 0;

      &::first-letter {
        font-weight: 800;
        text-transform: capitalize;
      }
    }
  }
}

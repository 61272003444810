@import '../../../../../sass/manifest';

.IconsContainer {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0.4rem 0.3rem;
  gap: 0.6rem;

  li {
    display: flex;
    flex-direction: column;
    .IconNameSection {
      display: grid;
      grid-template-columns: 40px 1fr;
      align-items: center;
      gap: 0.1rem;
    }

    svg {
      color: $periopsisOrange;
    }

    .InformationText {
      font-size: 0.85rem;
      color: $lightGray;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .IconsContainer {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0.5rem 0.8rem;
    gap: 0.9rem;

    li {
      display: flex;
      flex-direction: column;
      .IconNameSection {
        display: grid;
        grid-template-columns: 50px 1fr;
        align-items: center;
        gap: 0.2rem;
      }

      svg {
        color: $periopsisOrange;
      }

      .InformationText {
        font-size: 0.98rem;
        color: $lightGray;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../sass/manifest';

.NavContainer {
  position: relative;
  .Navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: linear-gradient(
      to right,
      $v2maingreen,
      lighten($v2maingreen, 15)
    );
    background-color: $v2maingreen;
    padding: 1rem;
    position: fixed;
    max-width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 70px;

    // box-shadow: $box-shadow;

    &.Center {
      justify-content: center;
    }

    .MenuMobile {
      margin-right: -0.55rem;
      transform: scale(0.8);
    }

    .MenuDesktop {
      display: none;
    }

    .MenuLinks {
      display: none;
    }
  }

  .UserBadgeMenuContainer {
    position: fixed;
    top: -200px;
    right: 10px;
    transition: all 0.2s ease-in-out;
    z-index: 5;

    &.show {
      top: 70px;
    }
  }

  .NotificationsMenuContainer {
    position: fixed;
    z-index: 6;
    top: -200%;
    transition: all 0.2s ease-in-out;
    right: 5%;

    &.show {
      top: 76px;
    }
  }
}

@media (min-width: 920px) {
  .NavContainer {
    .Navbar {
      .MenuMobile {
        display: none;
      }
      .MenuDesktop {
        display: flex;
        align-items: center;
      }

      .MenuLinks {
        display: flex;
        width: 65%;
        margin: 0 auto;
      }
    }
  }
}

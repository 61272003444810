@import '../../../../../../../sass/manifest';

.OrderDataCard {
  border: 1px solid $v2maingreen;
  padding: 1.5rem 0.7rem;
  border-radius: 1.02rem;
  position: relative;
  background-color: lighten($lightGray, 32%);

  .OrderDataHeading {
    position: absolute;
    bottom: 100%;
    left: 1rem;
    transform: translate(0, 50%);

    h3 {
      padding: 0.4rem 0.9rem;
      font-size: 0.95rem;
      font-weight: 500;
      background-color: $v2maingreen;
      color: #fff;
      border-radius: 6px;
    }
  }
}

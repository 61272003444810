@import '../../../../sass/manifest';

.NavNotificationIcon {
  margin-right: 1rem;
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;

  justify-content: center;
  font-size: 1.2rem;
  border-radius: 50%;
  background-color: #fff;
  color: $v2maingreen;
  border: 1px solid #fff;
  cursor: pointer;

  .NotificationNumber {
    position: absolute;
    width: 1.1rem;
    height: 1.1rem;
    top: -25%;
    right: -25%;
    background-color: $danger;
    border-radius: 50%;
    box-shadow: 1px 1px 3px black;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 100;
  }
}

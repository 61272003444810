@import '../../../../../../sass/manifest';

.IncidentReportItemContainer {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  color: $v2maingreen;

  .ReportSummary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $v2maingreen;
    padding: 0.8rem 0.1rem;

    .Content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;

      .Reported {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        h3 {
          font-size: 1.55rem;
        }
      }
    }
  }

  .Image {
    width: 70px;
    height: 70px;
  }

  .ContentContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid $v2maingreen;
  }

  .MessageItemBtn {
    padding: 0.68rem 0.2rem;
    width: 9rem;
    max-width: 9rem;
    border-radius: 0.75rem;
    font-size: 1rem;
  }
}

@import '../../../../sass/manifest';

.NoNotificationSelected {
  display: none;
  flex-direction: column;
  min-height: 50vh;
  min-height: 50dvh;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  h2,
  p {
    color: $lightGray;
    text-align: center;
  }
}

.DetailsContent {
  display: none;
  flex-direction: column;
  gap: 0.6rem;
  color: $v2maingreen;
  margin-top: 1.05rem;

  h1 {
    font-size: 1.45rem;
    text-transform: uppercase;
  }

  p {
    line-height: 1.3;
  }

  .IncidentCardsContainar {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin: 1.3rem 0;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .NoNotificationSelected {
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    min-height: 50dvh;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    h2,
    p {
      color: $lightGray;
      text-align: center;
    }
  }

  .DetailsContent {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    color: $v2maingreen;
    margin-top: 0.6rem;

    h1 {
      font-size: 1.02rem;
      text-transform: uppercase;
    }

    p {
      line-height: 1.3;
      font-size: 0.8rem;
    }

    .IncidentCardsContainar {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      margin: 1.3rem 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  .DetailsContent {
    gap: 0.6rem;

    margin-top: 1.05rem;

    h1 {
      font-size: 1.08rem;
    }

    p {
      font-size: 0.84rem;
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .DetailsContent {
    h1 {
      font-size: 1.12rem;
    }

    p {
      font-size: 0.92rem;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .DetailsContent {
    gap: 0.6rem;
    margin-top: 1.05rem;

    h1 {
      font-size: 1.45rem;
      text-transform: uppercase;
    }

    p {
      line-height: 1.3;
      font-size: 1.09rem;
    }
  }
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../../sass/manifest';

.RangeSelectInputSection {
  display: flex;
  gap: 0.8rem;
  flex-direction: column;

  .Separator {
    display: none;
  }

  .DateSelectionContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: $v2maingreen;

    font-weight: 600;

    &.InvalidDate {
      input {
        background-color: lighten($danger, 25%);
        color: #fff;
      }
    }

    input {
      background-color: transparent;
      border: 0 none;
      border-bottom: 1px solid $lightGray;
      padding: 0.3rem 0.8rem;
      font-size: 1.05rem;
      width: 100%;
      color: $lightGray;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .RangeSelectInputSection {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.8rem;
    justify-content: space-between;

    .Separator {
      display: block;
    }

    .DateSelectionContainer {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      color: $v2maingreen;

      font-weight: 600;

      &.InvalidDate {
        input {
          color: #fff;
        }
      }

      input {
        background-color: transparent;
        border: 0 none;
        border-bottom: 1px solid $lightGray;
        padding: 0.3rem 0.8rem;
        font-size: 1.05rem;
        width: 100%;
        color: $lightGray;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../../sass/manifest';

.SignIn {
  @include FlexCenter;
  margin-top: 0.4rem;
  color: darkgray;
  font-size: 0.8rem;
  bottom: 0;

  button {
    color: darken($nav-bg-light-blue, 8);
    margin-left: 0.25rem;
    cursor: pointer;
    background-color: transparent;
    border: 0 none;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .SignIn {
    @include FlexCenter;
    margin-top: 0.4rem;
    color: darkgray;
    font-size: 0.8rem;
    bottom: 0;

    button {
      color: darken($nav-bg-light-blue, 8);
      margin-left: 0.25rem;
      cursor: pointer;
      background-color: transparent;
      border: 0 none;
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../sass/manifest';

.AddToListForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
}

.Heading {
  display: flex;

  h3 {
    font-size: 0.8rem;
    color: $v2maingreen;
    text-transform: uppercase;
  }
}

.ListSection {
  display: flex;
  flex-direction: column;
  padding: 0.55rem 0rem;

  .ListContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    list-style: none;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .AddToListForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.2rem;
  }

  .Heading {
    display: flex;

    h3 {
      font-size: 0.95rem;
      color: $v2maingreen;
      text-transform: uppercase;
    }
  }

  .ListSection {
    display: flex;
    flex-direction: column;
    padding: 0.95rem 0.8rem;

    .ListContainer {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      list-style: none;
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

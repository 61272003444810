@import '../../../../sass/manifest';

.SignUpFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow-x: hidden;
  height: max-content;
  overflow-y: hidden;
  padding: 0.5rem 0;

  .PagesContainer {
    display: flex;
    width: 100%;
    position: relative;
    min-height: 60vh;
    min-height: 60dvh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .SignUpFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: 100%;
    overflow-x: hidden;

    .PagesContainer {
      display: flex;
      width: 100%;
      position: relative;
      min-height: 60vh;
      min-height: 60dvh;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .SignUpFormContainer {
    .PagesContainer {
      min-height: 58vh;
      min-height: 58dvh;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .SignUpFormContainer {
    padding: 0.6rem 0;
    width: 92%;
    .PagesContainer {
      min-height: 56vh;
      min-height: 56dvh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: $lightGray;
        border-radius: 20px;
        box-shadow: inset 1px 1px 3px black;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $v2maingreen;
        border-radius: 20px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .SignUpFormContainer {
    padding: 0.85rem 0;
    width: 95%;
    min-height: 100%;

    .PagesContainer {
      min-height: 61vh;
      min-height: 61dvh;
      overflow-y: auto;
      scrollbar-width: thin;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .SignUpFormContainer {
    padding: 1.2rem 0;
    width: 90%;
    height: unset;

    .PagesContainer {
      min-height: 55vh;
      min-height: 55dvh;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .SignUpFormContainer {
    width: 80%;
  }
}

@media only screen and (min-width: 1920px) {
  .SignUpFormContainer {
    width: 70%;

    .PagesContainer {
      min-height: 77vh;
      min-height: 77dvh;
    }
  }
}

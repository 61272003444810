@import '../../../../sass/manifest';

.HeadingSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.55rem 0.5rem;
  background-color: $v2maingreen;
  color: #fff;

  h1 {
    font-size: 1.05rem;
  }
}

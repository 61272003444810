@import '../../../../../sass/manifest';

.LoginForm {
  .Actions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    button {
      width: 100%;
    }
  }
}

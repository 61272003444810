@import '../../../../../sass/manifest';

.LoginForm {
  .RecaptchaContainer {
    display: flex;
    margin: 0.5rem 0;
  }

  .Actions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    button {
      width: 100%;
    }
  }

  .OptionalResendSection {
    margin: 0.5rem 0 0 0;
    display: flex;
    justify-content: flex-end;
    color: $v2maingreen;
    button {
      background-color: transparent;
      border: 0 none;
      padding: 0.3rem 0;
      cursor: pointer;
      color: $primary;

      &:disabled {
        color: $gray;
        cursor: not-allowed;
      }
    }
  }
}

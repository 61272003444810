@import '../../../../../../../sass/manifest';

.OrderCompletionContainer {
  display: flex;
  flex-direction: column;
  max-width: 85%;
  width: 85%;
  margin: 0 auto;
  gap: 0.95rem;
  color: $v2maingreen;

  .Timer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
    font-size: 0.9rem;
  }

  .Success {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    p {
      @include introParagraph;
    }
  }
  .Error {
  }

  .OrderCompletionActions {
    margin-top: 2rem;
    justify-content: space-between;

    button,
    div {
      width: max-content;
    }
  }
}

@import '../../../sass/variables.scss';
@import '../../../sass/mixins.scss';

.HeadingBarContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0.7rem;
  color: #fff;
  background-color: $v2maingreen;

  .HeadingSection {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    svg {
      margin-right: 0.2rem;
    }

    button {
      background-color: transparent;
      border: 0 none;
      color: #fff;
      font-size: 1.1rem;
      cursor: pointer;
    }
  }

  &.Primary {
    @include primary;
  }
  &.Info {
    @include info;
  }
  &.Danger {
    @include danger;
  }
  &.Warning {
    @include warning;
  }

  &.Success {
    @include success;
  }

  &.Main {
    @include main;
  }

  &.Orange {
    @include orange;
  }

  .AdditionalHeadingData {
    display: flex;
    width: 100%;
    gap: 1rem;
    margin-top: 0.4rem;
    align-items: center;

    p {
      margin: 0;
      padding: 0;
    }
  }
}

@media (min-width: 800px) {
  .HeadingBarContainer {
    flex-direction: row;

    .AdditionalHeadingData {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      width: max-content;
      gap: 1rem;
      margin-top: unset;
      align-items: center;
    }
  }
}

@import '../../../../../../sass/manifest';

.ErrorActions {
  max-width: 80%;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 0.6rem;
  gap: 0.5rem;

  .ButtonSection {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 0.8rem;
  }

  .ContractDetailsContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.9rem;
    background-color: #fff;
    color: $v2maingreen;
    max-height: unset;
    max-height: unset;
    @include scroll-Y;
    .HeadingSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      padding: 0.3rem 0;
      margin-bottom: 0.3rem;
      border-bottom: 1px solid $periopsisOrange;

      h1 {
        text-transform: uppercase;
        font-size: 0.95rem;
        text-align: center;
      }

      .ContractDate {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        font-size: 0.78rem;
      }
    }

    .ContractDetailsSection {
      display: flex;
      flex-direction: column;

      padding: 0.3rem 0.35rem;
      line-height: 1.3;

      .EntryText {
        font-size: 0.85rem;
        margin-bottom: 1.5rem;

        &::first-letter {
          font-size: 1.2rem;
          font-weight: 600;
        }
      }

      .Section {
        display: flex;
        flex-direction: column;
        padding: 0.3rem;
        gap: 0.6rem;

        h2 {
          font-size: 1.05rem;
        }

        p {
          font-size: 0.8rem;
        }

        .ProformaInvoiceLinkSection {
          display: flex;
          gap: 0.5rem;
          align-items: center;
        }

        .ProformaInvoiceLink {
          padding: 0.3rem;
          cursor: pointer;
          background-color: transparent;
          border: 0 none;
          color: $periopsisOrange;
          text-decoration: underline;
        }

        .ListSection {
          display: flex;
          flex-direction: column;
          margin: 0.8rem 0;

          h3 {
            text-transform: uppercase;
            font-size: 0.8rem;
            padding-bottom: 0.3rem;
            margin-bottom: 0.3rem;
            border-bottom: 1px solid $v2maingreen;
          }

          .ListSectionText {
            padding: 0.3rem 0;
          }

          .AreasContainer {
            display: grid;
            grid-template-columns: 1fr;
            list-style: none;
            gap: 0.3rem;

            li {
              border-bottom: 1px solid $v2maingreen;
              font-size: 0.8rem;
            }
          }

          .ServicesList {
            display: flex;
            flex-direction: column;
            padding: 0.8rem;
            list-style: none;
            gap: 0.4rem;
          }
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 0.4rem;
    gap: 0.5rem;

    .ButtonSection {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      gap: 0.8rem;
    }

    .ContractDetailsContainer {
      display: flex;
      flex-direction: column;
      padding: 1rem 1.3rem;
      background-color: #fff;
      color: $v2maingreen;
      max-height: none;
      max-height: none;
      @include scroll-Y;
      .HeadingSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;
        padding: 0.5rem 0;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid $periopsisOrange;

        h1 {
          text-transform: uppercase;
          font-size: 1rem;
        }

        .ContractDate {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          font-size: 0.92rem;
        }
      }

      .ContractDetailsSection {
        display: flex;
        flex-direction: column;

        padding: 0.5rem 1.08rem;
        line-height: 1.3;

        .EntryText {
          font-size: 0.85rem;
          margin-bottom: 1.5rem;

          &::first-letter {
            font-size: 1.2rem;
            font-weight: 600;
          }
        }

        .Section {
          display: flex;
          flex-direction: column;
          padding: 0.4rem 0.8rem;
          gap: 0.6rem;

          p {
            font-size: 0.82rem;
          }
          h2 {
            font-size: 1.15rem;
          }

          .ProformaInvoiceLinkSection {
            display: flex;
            gap: 0.5rem;
            align-items: center;
          }

          .ProformaInvoiceLink {
            padding: 0.3rem;
            cursor: pointer;
            background-color: transparent;
            border: 0 none;
            color: $periopsisOrange;
            text-decoration: underline;
          }

          .ListSection {
            display: flex;
            flex-direction: column;
            margin: 0.8rem 0;

            h3 {
              text-transform: uppercase;
              font-size: 1rem;
              padding-bottom: 0.3rem;
              margin-bottom: 0.3rem;
              border-bottom: 1px solid $v2maingreen;
            }

            .ListSectionText {
              padding: 0.6rem 0;
            }

            .AreasContainer {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              list-style: none;
              gap: 0.9rem 1.05rem;

              li {
                border-bottom: 1px solid $v2maingreen;
              }
            }

            .ServicesList {
              display: flex;
              flex-direction: column;
              padding: 0.8rem;
              list-style: none;
              gap: 0.4rem;
              font-size: 0.82rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 0.4rem;
    gap: 0.5rem;

    .ButtonSection {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      gap: 0.8rem;
    }

    .ContractDetailsContainer {
      display: flex;
      flex-direction: column;
      padding: 1rem 1.3rem;
      background-color: #fff;
      color: $v2maingreen;
      max-height: 71vh;
      max-height: 71dvh;
      @include scroll-Y;
      .HeadingSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;
        padding: 0.5rem 0;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid $periopsisOrange;

        h1 {
          text-transform: uppercase;
          font-size: 1.65rem;
        }

        .ContractDate {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          font-size: 1rem;
        }
      }

      .ContractDetailsSection {
        display: flex;
        flex-direction: column;

        padding: 0.5rem 1.08rem;
        line-height: 1.3;

        .EntryText {
          font-size: 1.05rem;
          margin-bottom: 1.5rem;

          &::first-letter {
            font-size: 1.4rem;
            font-weight: 600;
          }
        }

        .Section {
          display: flex;
          flex-direction: column;
          padding: 0.4rem 0.8rem;
          gap: 0.6rem;

          p {
            font-size: 1rem;
          }
          h2 {
            font-size: 1.25rem;
          }

          .ProformaInvoiceLinkSection {
            display: flex;
            gap: 0.5rem;
            align-items: center;
          }

          .ProformaInvoiceLink {
            padding: 0.3rem;
            cursor: pointer;
            background-color: transparent;
            border: 0 none;
            color: $periopsisOrange;
            text-decoration: underline;
          }

          .ListSection {
            display: flex;
            flex-direction: column;
            margin: 0.8rem 0;

            h3 {
              text-transform: uppercase;
              font-size: 1.2rem;
              padding-bottom: 0.3rem;
              margin-bottom: 0.3rem;
              border-bottom: 1px solid $v2maingreen;
            }

            .ListSectionText {
              padding: 0.6rem 0;
            }

            .AreasContainer {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              list-style: none;
              gap: 0.9rem 1.05rem;

              li {
                border-bottom: 1px solid $v2maingreen;
              }
            }

            .ServicesList {
              display: flex;
              flex-direction: column;
              padding: 0.8rem;
              list-style: none;
              gap: 0.4rem;
              font-size: 1.05rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .Wrapper {
    .ContractDetailsContainer {
      max-height: 75vh;
      max-height: 75dvh;
    }
  }
}

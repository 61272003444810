@import '../../../sass/variables.scss';

@import '../../../sass/mixins.scss';

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.3rem;

  .Language {
    display: flex;
    font-size: 0.8rem;
    color: #fff;
    align-items: center;
    padding: 0.5rem 0 0.2rem 0;
    gap: 0.4rem;
    background-color: transparent;
    border: 0 none;

    svg {
      font-size: 1rem;
    }
  }
}

.UserBadgeContainer {
  @include FlexCenter;
  padding: 0.3rem 0.7rem;
  background-color: #fff;
  border-radius: 0.9rem;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  position: relative;
  gap: 0.3rem;

  &:hover {
    // box-shadow: none;
    border: 1px solid $v2maingreen;
  }

  svg,
  span {
    margin: 0;
    color: darken($v2maingreen, 4);
    font-size: 0.78rem;
    font-weight: 600;
  }

  svg {
    font-size: 1.3rem;
  }
}

.NavBarHomeSettings {
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
}

@media (min-width: 920px) {
  .Wrapper {
    .Language {
      display: none;
    }
  }

  .UserBadgeContainer {
    .BadgeMenu {
      position: absolute;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      top: 35px;
      right: 10px;
      width: max-content;
      min-width: 14rem;
      box-shadow: 1px 1px 3px black;
      min-height: 8rem;

      .MenuOptions {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

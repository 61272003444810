@import '../../../../../../sass/manifest';

.CheckoutContentContainer {
  padding: 1rem 0;

  .CheckoutContainer {
    display: flex;
    justify-content: space-between;
    gap: 1.3rem;

    .LeftSection,
    .RightSection {
      display: flex;
      flex-direction: column;
      gap: 1.05rem;

      .PaymentOptions {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
      }

      .BankTransferNote {
        text-align: left;
        margin-top: 0.4rem;
        color: $lightGray;
        padding: 0.3rem;
        font-size: 0.83rem;
      }
    }

    .LeftSection {
      flex: 1;
      min-width: 55%;

      .CheckoutActions {
        width: 100%;
        margin-top: 0;
        padding: 0;

        button {
          width: 100%;
        }
      }
    }

    .RightSection {
      flex: 1;
    }
    .PaymentTypeContainer {
      display: flex;
      width: 100%;
      gap: 0.5rem;
      .PaymentTypeOption {
        display: flex;
        width: 50%;
        align-items: center;
        gap: 0.5rem;
        padding: 0.6rem 0.45rem;
        background-color: transparent;
        border: 1px solid $v2maingreen;
        color: $v2maingreen;
        font-weight: 600;
      }
    }
  }
}

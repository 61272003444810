@import '../../../../../../../sass/manifest';

.ReceiptInvoiceOptionContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    @include introParagraph;
    color: darken($lightGray, 10%);
  }
}

.LoginForm {
  .CaptchaContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 0;
  }

  .Actions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    gap: 0.8rem;
    .Button {
      min-width: 50%;
    }
  }
}

.LanguageSelectionPart {
  margin-top: 2rem;
}

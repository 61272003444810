@import '../../../sass/manifest';

.MainContainer {
  display: flex;
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  background-color: $v2maingreen;

  .ContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin: 0 auto;
    .PeriopsisLogoContainer {
      display: flex;
      width: 40vw;
      width: 40dvw;
      transform: translate(0, -3rem);

      img {
        max-width: 100%;
        animation: imageAnimate 0.8s linear infinite alternate;
      }
    }
  }
}

@keyframes imageAnimate {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@import '../../../../sass/manifest';
.FormContainer {
  display: flex;
  flex-direction: column;
  z-index: 90000;
  background-color: #fffffff9;
  backdrop-filter: 10px;
  width: 95%;
  height: 95%;
  padding: 0;

  position: relative;
  border-radius: 10px;
  border: 1px dotted $v2maingreen;

  .ContentContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 99%;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;
    @include scroll-Y;
    max-height: 82.5vh;
    max-height: 82.5dvh;
    height: 82vh;
    height: 82dvh;

    .Wrapper {
      display: flex;
      flex-direction: column;
      width: 96%;
      margin: 0.3rem auto;
    }
  }

  .FormHeadingSection {
    display: flex;
    padding: 1.35rem 0.6rem;
    margin-bottom: 1.05rem;
    border-bottom: 1px solid $lightGray;
    align-items: center;
    justify-content: space-between;
    color: $v2maingreen;
    font-size: 0.95rem;

    &.Scrolled {
      box-shadow: 2px 2px 10px rgb(49, 49, 49);
    }

    .CloseAndInformation {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      button {
        padding: 0.3rem;
        background-color: transparent;
        border: 0 none;
        cursor: pointer;
        svg {
          font-size: 1.15rem;
          color: $v2maingreen;
        }
      }

      .InfoButton {
        svg {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .FormContainer {
    width: 90%;
    height: 95%;

    .ContentContainer {
      gap: 1.6rem;
      width: 99%;

      .Wrapper {
        width: 90%;
      }
    }

    .FormHeadingSection {
      padding: 1.5rem 0.9rem;
      margin-bottom: 1.05rem;

      font-size: 0.95rem;

      .CloseAndInformation {
        gap: 0.6rem;

        button {
          padding: 0.3rem;

          svg {
            font-size: 1.35rem;
          }
        }

        .InfoButton {
          svg {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .FormContainer {
    width: 85%;
    height: 95%;

    .ContentContainer {
      gap: 1.8rem;
      width: 99%;

      .Wrapper {
        width: 90%;
      }
    }

    .FormHeadingSection {
      padding: 1.65rem 0.9rem;
      margin-bottom: 1.05rem;

      font-size: 0.95rem;

      .CloseAndInformation {
        gap: 0.6rem;

        button {
          padding: 0.3rem;

          svg {
            font-size: 1.35rem;
          }
        }

        .InfoButton {
          svg {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .FormContainer {
    width: 85%;
    height: 95%;
    padding: 0;

    .ContentContainer {
      .Wrapper {
        width: 88%;
      }
    }

    .FormHeadingSection {
      padding: 1.65rem 0.9rem;
      margin-bottom: 1.05rem;

      font-size: 1.05rem;

      .CloseAndInformation {
        gap: 0.6rem;

        button {
          padding: 0.3rem;

          svg {
            font-size: 1.35rem;
          }
        }

        .InfoButton {
          svg {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .FormContainer {
    width: 85%;
    height: 95%;

    .ContentContainer {
      gap: 1.8rem;
      width: 99%;

      max-height: 82.5vh;
      max-height: 82.5dvh;
      height: 82vh;
      height: 82dvh;

      .Wrapper {
        width: 80%;
        margin: 0.3rem auto;
      }
    }

    .FormHeadingSection {
      padding: 1.65rem 0.9rem;
      margin-bottom: 1.05rem;

      font-size: 1.15rem;

      .CloseAndInformation {
        gap: 0.6rem;

        button {
          padding: 0.3rem;

          svg {
            font-size: 1.35rem;
          }
        }

        .InfoButton {
          svg {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .FormContainer {
    width: 85%;
    height: 95%;

    .ContentContainer {
      .Wrapper {
        width: 70%;
        margin: 0.3rem auto;
      }
    }

    .FormHeadingSection {
      padding: 1.65rem 0.9rem;
      margin-bottom: 1.05rem;
      font-size: 1.25rem;

      .CloseAndInformation {
        gap: 0.6rem;

        button {
          padding: 0.3rem;

          svg {
            font-size: 1.35rem;
          }
        }

        .InfoButton {
          svg {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
}

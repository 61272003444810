@import '../../../../../../sass/variables.scss';

.SelectionForm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;

  svg {
    font-size: 1.2rem;
    margin-left: 0.6rem;
    color: $v2maingreen;
  }
}

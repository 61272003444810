.SkeletonLoadingContainer {
  opacity: 0.8;
  width: 100%;
  max-width: 100%;
  height: 0.5rem;
  animation: skeleton-loading 1.2s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba(133, 160, 173, 0.98);
  }

  100% {
    background-color: rgba(224, 231, 235, 0.45);
  }
}

@import '../../../sass/variables.scss';

.NoDataContainer {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
  text-align: center;
  margin: 0 auto;

  svg {
    font-size: 3.2rem;
    margin-bottom: 0.5rem;
    color: lighten($danger, 15);
  }

  p {
    color: $lightGray;
    font-size: 0.85rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .NoDataContainer {
    max-width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
    text-align: center;
    margin: 0 auto;

    svg {
      font-size: 5.5rem;
      margin-bottom: 1.2rem;
      color: lighten($danger, 15);
    }

    p {
      color: $lightGray;
      font-size: 1.1rem;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../../sass/manifest';

.Address {
  padding: 0.6rem 0.3rem 0.3rem 0.3rem;
  margin-top: 0.4rem;
  border-top: 1px solid $lightGray;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  color: darken($lightGray, 9%);

  .AddressOptions {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-top: 0.8rem;
  }

  .AddressHeading {
    display: flex;
    justify-content: space-between;
    h4 {
      font-size: 0.85rem;
    }

    button {
      cursor: pointer;
      background-color: transparent;
      border: 0 none;
      font-size: 0.8rem;
      color: $v2maingreen;
    }
  }
}

.AddressOrganizationData {
  padding-top: 0.4rem;
  h5 {
    font-size: 0.83rem;
  }
  span {
    font-size: 0.78rem;
  }
}

.AddressText {
  letter-spacing: 1.05px;
  font-size: 0.8rem;
}

.AddressOptionsActions {
  justify-content: space-between;

  .ActionButton {
    max-width: max-content;
  }
}

.AddressLimitMessage {
  padding: 0.2rem 0;
  font-size: 0.8rem;
  color: $danger;
}

@import '../../../sass/variables.scss';
@import '../../../sass/mixins.scss';

.SideDrawer {
  display: flex;
  position: fixed;
  width: 80%;
  max-width: 400px;
  height: 100vh;
  z-index: 20000;
  background-color: #fff;
  left: 0;
  top: 0;
  box-shadow: 4px 0px 3px rgba(0, 0, 0, 0.5);
  transform: translate(-110%);
  transition: transform 0.25s ease-in-out;
  flex-direction: column;
  overflow-y: auto;

  .SelectLanguage {
    width: 100%;
  }

  .MenuSection {
    height: 100%;
  }

  .BrandingSection {
    display: flex;
    background-color: $v2maingreen;
    flex-direction: column;
    padding: 0.5rem 0.3rem;
    margin: 0;
    width: 100%;

    .LogoContainer {
      width: 80%;
      margin: 0.25rem auto;
    }

    .UserContainer {
      width: 80%;
      margin: 0 auto;
    }
  }

  &.Open {
    transform: translateX(0);
  }
}

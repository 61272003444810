@import '../../../sass/manifest';

.RemoveButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0;
  width: 32px;
  height: 32px;
  color: $periopsisOrange;
  border-radius: 50%;
  cursor: pointer;
  border: 0 none;

  svg {
    font-size: 2.2rem;
  }
}

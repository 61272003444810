.OrganizationFormContainer {
  display: flex;
  flex-direction: column;
  gap: 1.05rem;
  padding: 1.2rem 0rem;

  .OrganiationUserForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.05rem 3.5rem;
    margin-bottom: 2.8rem;

    .PasswordStrengthContainer {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .OrganizationFormContainer {
    display: flex;
    flex-direction: column;
    gap: 1.05rem;
    padding: 1.2rem 0rem;

    .OrganiationUserForm {
      display: flex;
      flex-direction: column;
      gap: 1.05rem 3.5rem;
      margin-bottom: 2.8rem;

      .PasswordStrengthContainer {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .OrganizationFormContainer {
    display: flex;
    flex-direction: column;
    gap: 1.05rem;
    padding: 1.2rem 0rem;

    .OrganiationUserForm {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.05rem 3.5rem;
      margin-bottom: 2.8rem;

      .PasswordStrengthContainer {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

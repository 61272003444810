@import '../../../../../../../../sass/manifest';

.PackageDescription {
  width: 100%;
  top: 100%;
  bottom: -100%;
  position: absolute;
  background: rgb(0, 76, 108);
  background: $gradientBG;
  z-index: 999;
  padding: 1.05rem 1.25rem;

  transition: all 0.22s ease-in-out;
  max-height: 100%;
  overflow: hidden;

  &.Show {
    top: 0;
    bottom: 0;
  }

  .DescriptionContainer {
    max-height: 21rem;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: $v2maingreen;
    scrollbar-track-color: #fff;

    p {
      font-size: 0.85rem;
      line-height: 1.5;
      letter-spacing: 0.5px;
      padding: 0 0.5rem;
    }

    &::-webkit-scrollbar {
      width: 0.3rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
      box-shadow: inset 1px 1px 2px black;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $v2maingreen;
      border-radius: 5px;
    }
  }

  .PackageTypeSection {
    margin-bottom: 0.9rem;
    font-size: 0.75rem;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid greenyellow;
    display: flex;
    width: 100%;

    h3 {
      font-weight: 600;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .PackageDescription {
    .DescriptionContainer {
      p {
        font-size: 0.77rem;
        line-height: 1.4;
        letter-spacing: 0.5px;
        padding: 0 0.32rem;
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .PackageDescription {
    .DescriptionContainer {
      p {
        font-size: 0.78rem;
      }
    }
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .PackageDescription {
    .DescriptionContainer {
      p {
        font-size: 0.76rem;
        padding: 0 0.3rem;
      }
    }
  }
}

@media (min-width: 768px) {
}

@media (min-width: 900px) {
}

@media (min-width: 1000px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1300px) {
}

@media (min-width: 1536px) {
  .PackageDescription {
    .DescriptionContainer {
      p {
        font-size: 0.78rem;
        line-height: 1.3;
        letter-spacing: 0.4px;
        padding: 0 0.3rem;
      }
    }
  }
}

@media (min-width: 1920px) {
  .PackageDescription {
    .DescriptionContainer {
      p {
        font-size: 0.79rem;
        line-height: 1.3;
        letter-spacing: 0.36px;
        padding: 0 0.35rem;
      }
    }
  }
}

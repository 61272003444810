@import '../../../../../../../sass/manifest';

.OptionContainer {
  display: flex;
  flex-direction: column;
  padding: 0.55rem;
  border: 1px solid $v2maingreen;
  gap: 0.5rem;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  color: $v2maingreen;

  .Content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .LabelContentSection {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    p {
      font-size: 0.95rem;
      font-weight: 700;
    }
  }
}

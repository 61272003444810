@import '../../../../../../sass/manifest';

.CountWrapper {
  .IncidentsCount {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.45rem;
      height: 2.45rem;
      background-color: $v2maingreen;
      font-size: 0.95rem;
      font-weight: 600;
      color: #fff;
      border-radius: 50%;
    }
  }
}

.PlainCountWrapper {
  font-size: 0.78rem;

  p span {
    font-weight: 600;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .CountWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .IncidentsCount {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.45rem;
        height: 2.45rem;
        background-color: $v2maingreen;
        font-size: 0.95rem;
        font-weight: 600;
        color: #fff;
        border-radius: 50%;
      }
    }

    & > p {
      font-size: 0.82rem;
      margin-top: 0.25rem;
    }
  }

  .PlainCountWrapper {
    font-size: 0.82rem;

    p span {
      font-weight: 600;
    }
  }
}

@media only screen and (min-width: 768px) {
  .CountWrapper {
    .IncidentsCount {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.25rem;
        height: 2.25rem;
        background-color: $v2maingreen;
        font-size: 0.9rem;
        font-weight: 600;
        color: #fff;
        border-radius: 50%;
      }
    }
  }

  .PlainCountWrapper {
    font-size: 0.86rem;

    p span {
      font-weight: 600;
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .PlainCountWrapper {
    font-size: 0.95rem;

    p span {
      font-weight: 600;
    }
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
  .PlainCountWrapper {
    font-size: 1.05rem;

    p span {
      font-weight: 600;
    }
  }
}

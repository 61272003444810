@import '../../../../sass/manifest';

.Header {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  .Heading {
    font-size: 0.72rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.2rem 0.1rem;
  }

  .ClosableNote {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem;
    background-color: #fff;
    color: $v2maingreen;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      cursor: pointer;
      border: 0 none;
      border-radius: 50%;
      background-color: transparent;

      svg {
        color: $v2maingreen;
        font-size: 0.95rem;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .Header {
    width: 100%;
    gap: 1rem;

    .Heading {
      font-size: 0.78rem;
      font-weight: 500;

      padding: 0.2rem 0.1rem;
    }

    .ClosableNote {
      padding: 0.8rem;

      button {
        width: 25px;
        height: 25px;

        svg {
          font-size: 1.05rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .Header {
    width: 100%;
    gap: 1.2rem;

    .Heading {
      font-size: 0.92rem;
      font-weight: 500;
      padding: 0.2rem 0.1rem;
    }

    .ClosableNote {
      padding: 0.9rem 1.05rem;

      button {
        width: 25px;
        height: 25px;

        svg {
          font-size: 1.15rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

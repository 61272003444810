@import '../../../../../../../../../sass/manifest';
.Wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  .CloseBtnSection {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 0 0 0.3rem 0;
    button {
      padding: 0.1rem 0.4rem;
      background-color: transparent;
      border: 0 none;
      color: $v2maingreen;
      cursor: pointer;
    }
  }

  .SelectedPolygonsContainer {
    border-spacing: 0;

    tr th {
      border-right: 1px solid #fff;

      &:last-child {
        border-right: 0 none;
      }
    }

    tr td {
      border: 1px solid $v2maingreen;
      border-top: 0 none;
      border-left: 0 none;
      border-right: 0 none;
    }

    thead {
      width: 100%;

      tr th {
        padding: 0.5rem 0;
        background-color: $v2maingreen;
        color: #fff;
        font-size: 0.9rem;
        font-weight: 500;
      }
    }

    tbody {
      width: 100%;
      font-size: 0.9rem;

      tr {
        color: $v2maingreen;
        transition: all 0.2s linear;
        cursor: pointer;

        &:nth-child(2n) {
          background-color: lighten($lightGray, 20%);
        }

        &:hover {
          background-color: lighten($lightGray, 5%);
          color: #fff;
        }
      }

      tr td {
        padding: 0.6rem 0.2rem;
        text-align: center;

        &:first-child {
          text-align: left;
          padding-left: 0.5rem;
        }

        &:last-child {
          text-align: right;
          padding-right: 0.6rem;
        }
      }
    }
  }
}

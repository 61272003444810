@import '../../../../sass/manifest';

.MessageItemContainer {
  display: flex;
  flex-direction: column;
  padding: 0.1rem 0.5rem;
  width: 100%;
  background-color: #fff;

  .contentContainer {
    display: flex;
    flex-direction: column;
    padding: 0.6rem 0.2rem;
    border-bottom: 1px solid $v2maingreen;
    align-items: flex-start;
    justify-content: center;

    .IncidentDetails {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      margin-bottom: 1rem;
      .Image {
        height: 3rem;
        width: 3rem;
      }
    }

    .Actions {
      display: flex;
      gap: 0.65rem;
      .MessageItemBtn {
        padding: 0.6rem 0.15rem;
        width: 8rem;
        max-width: 8rem;
        border-radius: 0.75rem;
        font-size: 0.85rem;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .MessageItemContainer {
    display: flex;
    flex-direction: column;
    padding: 0.1rem 0.5rem;
    width: 100%;
    background-color: #fff;

    .contentContainer {
      display: flex;
      flex-direction: row;
      padding: 0.7rem 0.1rem;
      border-bottom: 1px solid $v2maingreen;
      align-items: center;
      justify-content: space-between;

      .IncidentDetails {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-bottom: unset;
        .Image {
          height: 3.3rem;
          width: 3.3rem;
        }
      }

      .Actions {
        display: flex;
        gap: 0.65rem;
        .MessageItemBtn {
          padding: 0.6rem 0.2rem;
          width: 6.2rem;
          max-width: 6.2rem;
          border-radius: 0.75rem;
          font-size: 0.72rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .MessageItemContainer {
    display: flex;
    flex-direction: column;
    padding: 0.1rem 0.5rem;
    width: 100%;
    background-color: #fff;

    .contentContainer {
      display: flex;
      flex-direction: row;
      padding: 0.7rem 0.1rem;
      border-bottom: 1px solid $v2maingreen;
      align-items: center;
      justify-content: space-between;

      .IncidentDetails {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.2rem;
        margin-bottom: unset;
        .Image {
          height: 3.8rem;
          width: 3.8rem;
        }
      }

      .Actions {
        display: flex;
        gap: 0.65rem;
        .MessageItemBtn {
          padding: 0.68rem 0.2rem;
          width: 6.3rem;
          max-width: 6.3rem;
          border-radius: 0.75rem;
          font-size: 0.75rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .MessageItemContainer {
    padding: 0.1rem 0.7rem;

    .contentContainer {
      padding: 1rem 0.2rem;

      .IncidentDetails {
        gap: 1.1rem;
        .Image {
          height: 4rem;
          width: 4rem;
        }
      }

      .Actions {
        gap: 0.65rem;
        .MessageItemBtn {
          padding: 0.65rem 0.1rem;
          width: 8rem;
          max-width: 8rem;
          border-radius: 0.75rem;
          font-size: 0.95rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .MessageItemContainer {
    padding: 0.2rem 0.8rem;

    .contentContainer {
      padding: 1.05rem 0.2rem;

      .IncidentDetails {
        gap: 1.2rem;
        .Image {
          height: 4.8rem;
          width: 4.8rem;
        }
      }

      .Actions {
        display: flex;
        gap: 0.65rem;
        .MessageItemBtn {
          padding: 0.68rem 0.2rem;
          width: 9rem;
          max-width: 9rem;
          border-radius: 0.75rem;
          font-size: 1rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

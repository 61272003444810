@import '../../../sass/manifest';

.ServiceSelectSection {
  display: flex;
  flex-direction: column;
  max-width: 40%;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin: auto;
  min-height: 70vh;
  min-height: 70dvh;
  gap: 0.8rem;

  img {
    max-width: 100%;
  }

  p {
    text-align: center;
    font-size: 1.08rem;
    line-height: 1.3;
    color: $lightGray;

    &::first-letter {
      font-size: 1.6rem;
      font-weight: 600;
      color: $v2maingreen;
    }
  }
  .Actions {
    width: 100%;
    justify-content: center;

    .StartButton {
      width: 30%;
      padding: 0.5rem 0;
    }
  }
}

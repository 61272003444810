@import '../../../../../../sass/manifest';

.ListItemContainerMobile {
  display: flex;
  padding: 0.38rem 0rem;
  border-bottom: 1px solid $lightGray;

  .DataContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    p {
      font-size: 0.8rem;
      color: $v2maingreen;
    }
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .ListItemContainerMobile {
    display: none;
  }
}

@import '../../../../sass/manifest';

.DownloadLink {
  text-decoration: none;
  color: $v2maingreen;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  padding: 0.3rem;
}

.SubServiceListWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style: none;
  padding: 0 0.8rem;
  height: 0;
  overflow: hidden;
  transition: all 0.2s linear;

  &.Show {
    @include scroll-Y;
    height: max-content;

    li {
      padding-bottom: 0.2rem;
      border-bottom: 1px solid lighten($lightGray, 15%);
    }
  }

  li {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.PricesNote {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding-bottom: 1.5rem;

  h3 {
    font-size: 1.12rem;
    color: $danger;
  }

  p {
    font-size: 1.05rem;
    color: $danger;
    line-height: 1.3;
  }
}

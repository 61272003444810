@import '../../../sass/manifest';

.IncidentActionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-top: 0.65rem;
  margin-top: 0.35rem;
  border-top: 1px solid $v2maingreen;
  padding-bottom: 0.6rem;

  .ActionItem {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    color: $v2maingreen;
    font-size: 0.85rem;

    svg {
      font-size: 1.45rem;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .IncidentActionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-top: 0.45rem;
    margin-top: 0.3rem;
    border-top: 1px solid $v2maingreen;
    padding-bottom: 0.5rem;

    .ActionItem {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.1rem;
      color: $v2maingreen;
      font-size: 0.79rem;

      svg {
        font-size: 1.35rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .IncidentActionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-top: 0.45rem;
    margin-top: 0.3rem;
    border-top: 1px solid $v2maingreen;
    padding-bottom: 0.5rem;

    .ActionItem {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.1rem;
      color: $v2maingreen;
      font-size: 0.79rem;

      svg {
        font-size: 1.35rem;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .IncidentActionsContainer {
    padding-top: 0.45rem;
    margin-top: 0.3rem;
    padding-bottom: 0.5rem;

    .ActionItem {
      gap: 0.1rem;
      font-size: 0.7rem;

      svg {
        font-size: 1.3rem;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .IncidentActionsContainer {
    padding-top: 0.65rem;
    margin-top: 0.35rem;
    padding-bottom: 0.6rem;

    .ActionItem {
      gap: 0.2rem;
      font-size: 0.8rem;

      svg {
        font-size: 1.45rem;
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .IncidentActionsContainer {
    .ActionItem {
      gap: 0.2rem;
      font-size: 0.8rem;

      svg {
        font-size: 1.45rem;
      }
    }
  }
}

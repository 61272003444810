@import '../../../../../sass/manifest';

.StatisticsWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.1rem;

  .ChartSection {
    display: flex;
    flex: 2;
    height: 67vh;
    height: 67dvh;
    align-items: center;
    justify-content: center;
    canvas {
      max-width: 60rem;
      max-height: 35rem;
      cursor: pointer;
    }

    &.Fullsceen {
      canvas {
        max-width: 90vw;
        max-width: 90dvw;
        max-height: 65vh;
        max-height: 65dvh;
        cursor: pointer;
      }
    }
  }

  .DataTableSection {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .StatisticsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 1rem;
    gap: 1rem;

    .ChartSection {
      display: flex;
      flex: 2;
      height: 67vh;
      height: 67dvh;
      align-items: center;
      justify-content: center;
      width: 100%;
      canvas {
        max-width: 60rem;
        max-height: 35rem;
        cursor: pointer;
      }
    }

    .DataTableSection {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 800px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .StatisticsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.1rem;
    gap: 1rem;

    .ChartSection {
      display: flex;
      flex: 2;
      height: 65vh;
      height: 65dvh;
      align-items: center;
      justify-content: center;
      width: unset;
      canvas {
        width: 63rem !important;
        max-width: 63rem;
        max-height: 32rem;
        cursor: pointer;
      }
    }

    .DataTableSection {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .StatisticsWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.1rem;
    gap: unset;

    .ChartSection {
      display: flex;
      flex: 2;
      height: 62vh;
      height: 62dvh;
      align-items: center;
      justify-content: center;
      width: unset;
      canvas {
        max-width: 54rem;
        max-height: 35rem;
        cursor: pointer;
      }
    }

    .DataTableSection {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: unset;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .StatisticsWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.1rem;
    gap: unset;

    .ChartSection {
      display: flex;
      flex: 2;
      height: 67vh;
      height: 67dvh;
      align-items: center;
      justify-content: center;
      width: unset;
      canvas {
        max-width: 62rem;
        max-height: 35rem;
        cursor: pointer;
      }
    }

    .DataTableSection {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: unset;
    }
  }
}

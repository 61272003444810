@import '../../../../../../../sass/manifest';

.SubservicesContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  right: unset;
  gap: 1.2rem;
  width: 100%;
  left: unset;
  height: 100%;
  padding: 0 0 0.8rem 0.95rem;
  margin-top: 2rem;

  .HeadingSection {
    display: flex;
    align-items: center;

    h3 {
      font-size: 1.08rem;
      color: $v2maingreen;
    }
  }
  .SaveAndContinue {
    display: flex;

    width: max-content;
    margin: 1.2rem 0;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}
@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media (min-width: 768px) {
}

@media (min-width: 900px) {
  .SubservicesContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 5px;
    gap: 1.2rem;
    width: 100%;
    left: 38%;

    height: 100%;
    padding: 0 0 0.8rem 0.95rem;
    margin-top: unset;

    .HeadingSection {
      display: flex;
      align-items: center;

      h3 {
        font-size: 1.08rem;
        color: $v2maingreen;
      }
    }
    .SaveAndContinue {
      display: flex;

      width: max-content;
      margin: 1.2rem 0;
    }
  }
}

@media (min-width: 1000px) {
}

@media (min-width: 1200px) {
  .SubservicesContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 5px;
    gap: 1.2rem;
    width: 100%;
    left: 38%;

    height: 100%;
    padding: 0 0 0.8rem 0.95rem;
    margin-top: unset;

    .HeadingSection {
      display: flex;
      align-items: center;

      h3 {
        font-size: 1.08rem;
        color: $v2maingreen;
      }
    }
    .SaveAndContinue {
      display: flex;

      width: max-content;
      margin: 1.2rem 0;
    }
  }
}

@media (min-width: 1536px) {
}

@media (min-width: 1920px) {
}

.CleanForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  margin-top: 1rem;

  .Options {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .SubmitButton {
    padding: 0.6rem 1.5rem;
  }
}

@import '../../../sass/manifest';
.OptionWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid $v2maingreen;
  border-radius: 1rem;

  &.Selected {
    box-shadow: 0 0 4px $v2maingreen;
  }

  .DeleteContainer {
    display: flex;
    padding: 0.4rem 0.8rem;
    font-size: 1.34rem;
    align-items: center;
    justify-content: flex-end;
    color: $danger;

    svg {
      cursor: pointer;
    }
  }

  .AddressOptionContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    cursor: pointer;

    .Label {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      width: 100%;
      padding: 1.2rem 0.8rem;
      min-height: 8.2rem;

      .LabelContent {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .AddressContent {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;

          .OrganizationDetails {
            display: flex;
            flex-direction: column;
            margin: 0.1rem 0 0 0;

            h3 {
              font-size: 0.92rem;
            }

            p {
              font-size: 0.85rem;
            }
          }
        }
      }
    }

    // .RadioButton {
    //   width: 1.3rem;
    //   height: 1.3rem;
    //   border-radius: 50%;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   border: 2px solid $v2maingreen;
    //   position: relative;
    //   box-shadow: inset 1px 1px 3px $lightGray;
    //   min-width: 1.3rem;

    //   .Dot {
    //     width: 0.65rem;
    //     height: 0.65rem;
    //     background-color: $v2maingreen;

    //     position: absolute;
    //     border-radius: 50%;
    //     left: 50%;
    //     top: 50%;
    //     transform: translate(-50%, -50%);
    //     display: none;
    //   }

    //   &.Selected {
    //     .Dot {
    //       display: flex;
    //     }
    //   }
    // }

    input {
      display: none;
    }
  }
}

@import '../../../../../sass/manifest';

.Container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  position: relative;

  .ForgotPasswordUserEmailContainer {
    display: flex;
    max-width: 100%;
    gap: 1rem;
    margin: 0 auto;
    min-height: max-content;
    padding: 0.6rem 0.8rem;
    background-color: unset;

    .BtnContainer {
      width: 100%;
      align-items: center;
      justify-content: center;

      button {
        width: 100%;
      }
    }

    .HeadSection {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;

      .HeadingSection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $v2maingreen;
        padding: 0.3rem 0;
        margin-bottom: 0.2rem;
        border-bottom: 1px solid $periopsisOrange;
        font-size: 0.92rem;

        svg {
          font-size: 1.05rem;
        }
      }

      p {
        font-size: 0.75rem;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .Container {
    .ForgotPasswordUserEmailContainer {
      padding: 0.6rem 0.8rem;

      .HeadSection {
        gap: 0.1rem;

        .HeadingSection {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $v2maingreen;
          padding: 0.3rem 0;
          margin-bottom: 0.2rem;
          border-bottom: 1px solid $periopsisOrange;
          font-size: 0.85rem;

          svg {
            font-size: 1rem;
          }
        }

        p {
          font-size: 0.75rem;
        }
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .Container {
    .ForgotPasswordUserEmailContainer {
      padding: 0.6rem 0.8rem;

      .HeadSection {
        gap: 0.1rem;

        .HeadingSection {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $v2maingreen;
          padding: 0.3rem 0;
          margin-bottom: 0.2rem;
          border-bottom: 1px solid $periopsisOrange;
          font-size: 0.85rem;

          svg {
            font-size: 1rem;
          }
        }

        p {
          font-size: 0.75rem;
        }
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .Container {
    margin: 0 auto;

    .ForgotPasswordUserEmailContainer {
      .HeadSection {
        gap: 0.2rem;

        .HeadingSection {
          font-size: 0.98rem;

          svg {
            font-size: 1.08rem;
          }
        }

        p {
          font-size: 0.85rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .Container {
    .ForgotPasswordUserEmailContainer {
      .HeadSection {
        gap: 0.2rem;

        .HeadingSection {
          font-size: 1rem;

          svg {
            font-size: 1.1rem;
          }
        }

        p {
          font-size: 0.89rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .Container {
    .ForgotPasswordUserEmailContainer {
      .HeadSection {
        gap: 0.2rem;

        .HeadingSection {
          font-size: 1.05rem;

          svg {
            font-size: 1.15rem;
          }
        }

        p {
          font-size: 0.94rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
}

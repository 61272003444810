@import '../../../sass/variables.scss';
@import '../../../sass/mixins.scss';

.NoData {
  @include FlexCenter;
  flex-direction: column;
  width: 100%;
  padding: 1.4rem 0.8rem;
  font-size: 0.92rem;
  color: lighten($gray, 5);
}

@media (min-width: 800px) {
  .NoData {
    padding: 1.2rem;
    font-size: large;
  }
}

@import '../../../../../../sass/manifest';

.Content {
  @include scroll-Y;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  padding: 0.5rem 0.9rem;
  scroll-snap-align: center;
  max-height: 61vh;
  max-height: 61dvh;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .Content {
    @include scroll-Y;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    padding: 1rem 2rem;
    scroll-snap-align: center;
    max-height: unset;
    max-height: unset;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .Content {
    @include scroll-Y;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    padding: 1rem 2rem;
    scroll-snap-align: center;
    max-height: 61vh;
    max-height: 61dvh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

.DocumentListWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.65rem;

  .DocumentListContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.65rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .DocumentListWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.65rem;

    .DocumentListContainer {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.65rem;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .DocumentListWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.65rem;

    .DocumentListContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.65rem;
    }
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

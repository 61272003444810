@import '../../../../../sass/manifest';

.ActionRowItem {
  display: grid;
  grid-template-columns: 20px 2fr max-content;
  gap: 0.4rem;
  align-items: center;
  width: 95%;
  padding: 0.55rem 0.2rem;
  border-bottom: 1px solid $v2maingreen;
  cursor: pointer;
  transition: all 0.25s linear;

  &:hover {
    background-color: lighten($v2maingreen, 10%);

    p,
    data {
      color: #fff;
      transition: all 0.25s linear;
    }
  }

  span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  &.ColorBorder {
    span {
      border: 1px solid $v2maingreen;
    }
  }

  p,
  data {
    color: $v2maingreen;
    font-size: 0.82rem;
  }

  data {
    font-weight: 500;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .ActionRowItem {
    gap: 0.9rem;
    width: 90%;
    padding: 0.65rem 0.2rem;

    p,
    data {
      font-size: 0.95rem;
    }

    data {
      font-weight: 600;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 800px) {
}

@media only screen and (min-width: 900px) {
  .ActionRowItem {
    gap: 1rem;

    width: 80%;
    padding: 0.85rem 0.2rem;

    p,
    data {
      font-size: unset;
    }

    data {
      font-weight: 600;
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

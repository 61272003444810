@import '../../../../sass/manifest';

.NavLinksContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-height: 100%;
  margin-top: 0.1rem;

  .NavLinksContainer_Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .NavLinksContainer_ContentLinks {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      min-width: 100%;
      height: max-content;
    }

    .NavLoginLink {
      a {
        text-decoration: none;
        padding: 0.4rem;
        color: $gray;

        box-shadow: $inset-box-shadow;
        border-radius: 0.6rem;
        font-size: 0.8rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 4.2rem;

        svg {
          font-size: large;
          margin-right: 0.2rem;
        }

        &:hover {
          box-shadow: $box-shadow;
        }
      }
    }

    .NavLinksContainer_ContentLinks {
      display: flex;
      width: 70%;
      align-items: flex-start;
      justify-content: space-around;
    }
  }
}

@media (min-width: 920px) {
  .NavLinksContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;

    .NavLinksContainer_Content {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;

      justify-content: space-between;

      .NavLinksContainer_ContentLinks {
        display: grid;
        grid-template-columns: 1fr;
        min-width: 100%;
      }

      .NavLoginLink {
        a {
          text-decoration: none;
          padding: 0.4rem;
          color: #fff;

          box-shadow: inset 1px 1px 3px black;
          border-radius: 0.6rem;
          font-size: 0.8rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 4.2rem;

          svg {
            font-size: large;
            margin-right: 0.2rem;
          }

          &:hover {
            box-shadow: 1px 1px 3px black;
          }
        }
      }

      .NavLinksContainer_ContentLinks {
        display: flex;
        flex-direction: row;
        width: 70%;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
}

@import '../../../../../../sass/manifest';

.BankDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 0.5rem 0;
  margin-bottom: 0.9rem;
  border-bottom: 1px solid $lightGray;

  .BankLogoContainer {
    display: flex;
    max-width: 100%;
    padding: 0.4rem 0;

    img {
      max-width: 12rem;
    }
  }

  .BankAddressSection {
    display: flex;
    flex-direction: column;
    padding: 0.3rem;
    gap: 0.2rem;
    font-size: 0.8rem;

    label {
      color: darken($lightGray, 10%);
      font-weight: 600;
    }

    address {
      color: darken($lightGray, 10%);
    }
  }

  .DataRow {
    display: flex;
    justify-content: space-between;
  }
}

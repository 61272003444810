@import '../../../../../../../../sass/manifest';

.PaymentProcessingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  gap: 2rem;
  flex-direction: column;
  padding: 1.8rem 0;
  color: $v2maingreen;
  text-transform: uppercase;
  letter-spacing: 1px;

  &.Small {
    gap: 1rem;
    padding: 0.6rem 0;
  }

  .Processing {
    width: 3.8rem;
    height: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $v2maingreen;
    color: #fff;
    font-size: 2rem;
    border-radius: 50%;
    animation: coin 1.95s linear 0s infinite alternate;

    span {
      animation: currency 1.95s linear 0s infinite alternate;
    }

    &.Small {
      width: 3.3rem;
      height: 3.3rem;
    }
  }

  .PleaseWaitContainer {
    display: flex;
    align-items: center;
    gap: 0.4rem;

    div {
      width: 5px;
      height: 5px;
      background-color: $v2maingreen;
      margin-top: 8px;
      border-radius: 50%;
    }
    .FirstDot {
      animation: dot 0.9s linear 0s infinite alternate;
    }

    .SecondDot {
      animation: dot 0.8s linear 0.2s infinite alternate;
    }

    .ThirdDot {
      animation: dot 0.7s linear 0.4s infinite alternate;
    }
  }
}

@keyframes dot {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes coin {
  0% {
    transform: rotateY(0deg);
    filter: blur('0px');
    box-shadow: 0 0 0 $v2maingreen;
  }
  100% {
    transform: rotateY(359deg);
    filter: blur('10px');
    box-shadow: 0 0 20px $v2maingreen;
  }
}

@keyframes currency {
  0% {
    transform: rotateY(0deg);
    filter: blur('0px');
  }
  100% {
    transform: rotateY(-359deg);
    filter: blur('10px');
  }
}

@import '../../../../../sass/manifest';

.HeadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  max-width: 92vw;
  max-width: 92dvw;
  padding: 1.2rem 0;

  .ImageContainer {
    display: flex;
    width: 5rem;
    min-width: 4rem;

    img {
      max-width: 95%;
      height: auto;
    }
  }

  .TextSection {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: center;
    color: $lightGray;

    h2 {
      font-size: 0.95rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .HeadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    max-width: 98vw;
    max-width: 98dvw;
    padding: 1.2rem 0 1.55rem 0;

    .ImageContainer {
      display: flex;
      width: 5rem;
      min-width: 4rem;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .TextSection {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: flex-start;
      justify-content: center;
      color: $lightGray;

      h2 {
        font-size: 0.92rem;
      }

      p {
        font-size: 0.78rem;
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .HeadingContainer {
    gap: 0.8rem;
    max-width: 95vw;
    max-width: 95dvw;
    padding: 1.3rem 0;

    .ImageContainer {
      display: flex;
      width: 5rem;
      min-width: 5rem;

      img {
        max-width: 95%;
        height: auto;
      }
    }

    .TextSection {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: flex-start;
      justify-content: center;
      color: $lightGray;

      h2 {
        font-size: 0.96rem;
      }

      p {
        font-size: 0.8rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .HeadingContainer {
    gap: 0.8rem;
    max-width: 66vw;
    max-width: 66dvw;
    padding: 1.55rem 0;

    .ImageContainer {
      width: 6rem;
      min-width: 6rem;

      img {
        max-width: 100%;
      }
    }

    .TextSection {
      gap: 0.5rem;

      h2 {
        font-size: 1.15rem;
      }

      p {
        font-size: 0.95rem;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .HeadingContainer {
    gap: 0.8rem;
    max-width: 58vw;
    max-width: 58dvw;
    padding: 1.55rem 0;

    .ImageContainer {
      width: 6.5rem;
      min-width: 6.5rem;

      img {
        max-width: 100%;
      }
    }

    .TextSection {
      gap: 0.5rem;

      h2 {
        font-size: 1.25rem;
      }

      p {
        font-size: 1.05rem;
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .HeadingContainer {
    padding: 1.55rem 0 1.8rem 0;

    .ImageContainer {
      width: 6.3rem;
      min-width: 6.3rem;

      img {
        max-width: 100%;
      }
    }

    .TextSection {
      gap: 0.5rem;

      h2 {
        font-size: 1.35rem;
      }

      p {
        font-size: 1.08rem;
      }
    }
  }
}

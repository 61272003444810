@import '../../../sass/variables.scss';

.DataRowContainer {
  display: grid;
  grid-template-columns: 1.5fr 10px 2fr;
  grid-template-rows: 1fr;
  width: 100%;
  padding: 0.2rem 0.3rem;
  font-size: 0.73rem;
  cursor: pointer;

  p {
    color: darken($lightGray, 10%);
    &:last-child {
      text-align: end;
    }

    svg {
      margin-right: 0.3rem;
      cursor: pointer;
      transition: color 0.3s linear;
      color: $v2maingreen;
      display: none;

      &:hover {
        color: $primary;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .DataRowContainer {
    display: grid;
    grid-template-columns: 1.5fr 10px 2fr;
    grid-template-rows: 1fr;
    width: 100%;
    padding: 0.2rem 0.15rem;
    font-size: 0.68rem;
    cursor: pointer;

    p {
      color: darken($lightGray, 10%);
      &:last-child {
        text-align: end;
      }

      svg {
        margin-right: 0.3rem;
        cursor: pointer;
        transition: color 0.3s linear;
        color: $v2maingreen;
        display: none;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1000px) {
  .DataRowContainer {
    display: grid;
    grid-template-columns: 1fr 6px 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    padding: 0.2rem 0.25rem;
    font-size: 0.83rem;
    text-align: end;

    p:first-child {
      text-align: start;
      svg {
        display: unset;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
  .DataRowContainer {
    display: grid;
    grid-template-columns: 1fr 8px 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    padding: 0.2rem 0.3rem;
    font-size: 1.05rem;
    text-align: end;

    p:first-child {
      text-align: start;
      svg {
        display: unset;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../sass/manifest';

.CheckBoxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.45rem;
  width: max-content;
  cursor: pointer;
  color: $v2maingreen;
  font-size: 0.72rem;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .CheckBoxContainer {
    gap: 0.48rem;
    font-size: 0.72rem;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}
@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .CheckBoxContainer {
    gap: 0.6rem;
    font-size: 0.86rem;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 900px) {
}

@media (min-width: 1000px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1536px) {
}

@media (min-width: 1920px) {
}

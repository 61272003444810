@import '../../../../sass/manifest';
.LanguageSelectionContainer {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.6rem;

  button {
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
    transform: scale(0.9);
    border: 1px solid transparent;

    &.Selected {
      transform: scale(1.05);
      border-color: $v2maingreen;
    }
  }
}

@import '../../../sass/manifest';

.PDFButtonContainer {
  display: flex;
  padding: 0 0.8rem 0.5rem 0.6rem;
  justify-content: flex-end;

  .ReportDownload {
    display: flex;
    padding: 0.6rem 0.95rem;
    color: #fff;
    text-decoration: none;
    background-color: #e99f83;
    width: max-content;
    border-radius: 10px;
    svg {
      margin-right: 0.4rem;
    }
  }
}

@import '../../../../sass/manifest';

.FlowShape {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 1.875rem;
  background-color: $lightGray;
  cursor: help;

  span {
    font-size: 0.7rem;
    color: $v2maingreen;
    position: absolute;
    z-index: 30;
    left: 65%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
  }

  &.BackArrow {
    &::before {
      left: 0px;
      border-top: 0.9375rem solid transparent;
      border-bottom: 0.9375rem solid transparent;
      border-left: 1rem solid #f0f2f5;
    }

    &.Current {
      background-color: $v2maingreen;

      span {
        color: #fff;
      }
    }
  }

  &.FrontArrow {
    &::after {
      right: -16px;
      border-top: 0.9375rem solid transparent;
      border-bottom: 0.9375rem solid transparent;
      border-left: 1rem solid $lightGray;
      z-index: 5;
    }

    &.Current {
      background-color: $v2maingreen;

      span {
        color: #fff;
      }

      &::after {
        right: -16px;
        border-top: 0.9375rem solid transparent;
        border-bottom: 0.9375rem solid transparent;
        border-left: 1rem solid $v2maingreen;
        z-index: 10;
      }
    }
  }
  .ArrowLabel {
    display: none;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ArrowNumber {
    display: block;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}
@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media (min-width: 768px) {
}

@media (min-width: 900px) {
  .FlowShape {
    width: 8rem;

    span {
      font-size: 0.65rem;
      left: 50%;
      top: 50%;
      transform: translate(-45%, -50%);
    }

    .ArrowLabel {
      display: block;
    }

    .ArrowNumber {
      display: none;
    }
  }
}

@media (min-width: 1200px) {
  .FlowShape {
    width: 6.2rem;

    span {
      font-size: 0.7rem;
    }
  }
}

@media (min-width: 1536px) {
}

@media (min-width: 1920px) {
}

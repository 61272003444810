@import './sass/variables.scss';
@import './sass/mixins.scss';

:root {
  font-size: 1em; /*16px browsers default font size*/
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
  @include scroll-Y;
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  background-color: $body-bg;
  max-width: 100vw;
}

.stop-scrolling-on-modal {
  overflow: hidden;
}

#Error {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100%;
  max-width: 100vw;
}

.Toastify__toast-container {
  z-index: 35000 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: $v2maingreen !important;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 45000;
  background: rgba(255, 255, 255, 0.95);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 620px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  // box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  box-shadow: 2px 2px 5px black;
  color: $gray;
  max-width: 95vw;
  font-size: 0.96rem;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  font-size: 1.35rem;
  margin-top: 0;
  margin-bottom: 0.45rem;
  border-bottom: 1px solid $v2maingreen;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  // justify-content: flex-start;
  width: 65%;
  margin: 0 auto;
  justify-content: space-evenly;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  // background: #333;
  background-color: $v2maingreen;
  border: none;
  display: inline-block;
  padding: 0.5rem 1.3rem;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: darken($v2maingreen, 6);
    box-shadow: 0 0 10px $v2maingreen;
    transform: scale(1.03);
  }

  &:active {
    background-color: lighten($v2maingreen, 3);
    box-shadow: inset 1px 1px 3px black;
    transform: scale(0.95);
  }
}

.mapboxgl-popup-content {
  border-radius: 1rem !important;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@import '../../../../../../../../../sass/manifest';

.UpdateSectionSelection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.9rem;
}

.DomainError {
  padding: 0.6rem;
  background-color: $danger;
  color: #fff;
  font-size: 0.95rem;
  line-height: 1.35;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

.RegistrationForm {
  display: flex;
  gap: 0.65rem;
  height: 95%;
  justify-content: space-evenly;

  .DropdownsSection {
    display: flex;
    flex-direction: column;
    gap: 0.65rem;
  }

  .FormSubmitButtons {
    gap: 0.8rem;
    .ActionButton {
      flex: 1;
      max-width: 100%;
    }
  }
}

@import '../../../../../sass/manifest';

.BorderedSectionContainer {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  padding: 0;
  border: 1px solid lighten($lightGray, 15%);
  border-radius: 15px;
  overflow: hidden;

  .Heading {
    display: flex;
    padding: 0.75rem 0.75rem 0.5rem 0.75rem;
    margin: 0.2rem 0;

    h3 {
      display: flex;
      width: 100%;
      font-size: 0.85rem;
      text-transform: uppercase;
      padding-bottom: 0.45rem;
      border-bottom: 1px solid $periopsisOrange;
      color: $v2maingreen;
    }

    &.CloseButton {
      h3 {
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .InformationSection {
    display: flex;
    padding: 0.2rem 0.8rem;
    font-size: 0.82rem;
    line-height: 1.45;
    color: $lightGray;
  }

  .SectionContent {
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    padding: 1.3rem 0.75rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .BorderedSectionContainer {
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
    padding: 0;
    border: 1px solid lighten($lightGray, 15%);
    border-radius: 15px;
    overflow: hidden;

    .Heading {
      display: flex;
      padding: 0.75rem 0.75rem 0.5rem 0.75rem;
      margin: 0.2rem 0;

      h3 {
        display: flex;
        width: 100%;
        font-size: 0.95rem;
        text-transform: uppercase;
        padding-bottom: 0.45rem;
        border-bottom: 1px solid $periopsisOrange;
        color: $v2maingreen;
      }

      &.CloseButton {
        h3 {
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .InformationSection {
      display: flex;
      padding: 0.28rem 0.95rem;
      font-size: 0.85rem;
      line-height: 1.45;
      color: $lightGray;
    }

    .SectionContent {
      display: flex;
      flex-direction: column;
      gap: 0.9rem;
      padding: 1.3rem 0.75rem;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .BorderedSectionContainer {
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
    padding: 0;
    border: 1px solid lighten($lightGray, 15%);
    border-radius: 15px;
    overflow: hidden;

    .Heading {
      display: flex;
      padding: 1.15rem 1.12rem 0.5rem 1.12rem;
      margin: 0.2rem 0;

      h3 {
        display: flex;
        width: 100%;
        font-size: 1.05rem;
        text-transform: uppercase;
        padding-bottom: 0.55rem;
        border-bottom: 1px solid $periopsisOrange;
        color: $v2maingreen;
      }

      &.CloseButton {
        h3 {
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .InformationSection {
      display: flex;
      padding: 0.2rem 1.12rem;
      font-size: 1.05rem;
      line-height: 1.45;
      color: $lightGray;
    }

    .SectionContent {
      display: flex;
      flex-direction: column;
      gap: 0.9rem;
      padding: 2rem 1.25rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

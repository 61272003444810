.MainWindow {
  display: flex;
  flex-direction: column;
  padding: 5rem 0 0 0;
  margin: 0;
  max-width: 100%;
  width: 100%;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 800px) {
}

@media only screen and (min-width: 900px) {
  .MainWindow {
    padding-left: 6rem;
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

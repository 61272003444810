.AssignedByInfo {
  display: flex;
  align-items: center;
  position: relative;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
  }

  svg {
    font-size: 1.25rem;
  }

  .InfoBox {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 100%;
    background-color: #fff;
    z-index: 100;
    box-shadow: 2px 2px 5px rgb(69, 69, 69);
    width: max-content;
    border-radius: 15px;
    overflow: hidden;

    .DataContent {
      display: flex;
      flex-direction: column;
      padding: 0.9rem 1.2rem;
    }
  }
}

.ActionStatisticsSelection {
  display: flex;
  padding: 0.5rem 0 0.5rem 0;
  gap: 0.4rem;
  justify-content: space-between;

  .StatisticsSelect {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    width: 100%;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 800px) {
  .ActionStatisticsSelection {
    display: flex;
    padding: 0.3rem 0 0 0;
    gap: 0.3rem;
    justify-content: space-between;

    .StatisticsSelect {
      display: flex;
      gap: 0.3rem;
      justify-content: flex-start;
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
  .ActionStatisticsSelection {
    display: flex;
    padding: 0.3rem 0 0 0;
    gap: 0.4rem;
    justify-content: space-between;

    .StatisticsSelect {
      display: flex;
      gap: 0.5rem;
    }
  }
}

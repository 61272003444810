@import '../../../../sass/variables.scss';

.StepCardContainer {
  display: flex;

  width: 100%;
  //   min-height: 6rem;
  height: fit-content;
  border-left: 4px solid $v2maingreen;
  background-color: azure;

  .StepCardDataSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;

    .StepCardInstructionSection {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 0.7rem;

      .Instructions {
        display: flex;
        flex-direction: column;
        padding-left: 0.3rem;
      }

      .DurationDistance {
        display: flex;
        flex-direction: column;
        max-width: fit-content;
        align-items: flex-end;
        justify-content: center;
        border-left: 1px solid $v2maingreen;
        width: 8rem;
        padding-left: 0.45rem;
        margin-left: 0.3rem;

        & span:first-child {
          font-weight: 600;
        }
      }
    }
  }

  .IconSection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
  }
}

@media (min-width: 1040px) {
  .StepCardContainer {
    .StepCardDataSection {
      .StepCardInstructionSection {
        font-size: 0.83rem;
        padding: 0.2rem;

        .Instructions {
          padding-left: 0.4rem;
        }

        .DurationDistance {
          display: flex;
          flex-direction: column;
          max-width: fit-content;
          align-items: flex-end;
          justify-content: center;
          border-left: 1px solid $v2maingreen;
          width: 8rem;
          padding-left: 0.45rem;
          margin-left: 0.3rem;

          & span:first-child {
            font-weight: 600;
          }
        }
      }
    }

    .IconSection {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10%;
    }
  }
}

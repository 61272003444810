@import '../../../sass/manifest';

.MainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-height: 100dvh;

  .LeftContent {
    flex: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $v2maingreen;
    position: relative;
    padding: 0.9rem 0.85rem;

    .PeriopsisLogo {
      max-width: 8rem;
    }

    .HomePageRedirectSection {
      display: flex;
      width: max-content;
      height: max-content;
      position: relative;
      bottom: 0;
      align-items: center;
      justify-content: center;

      a svg {
        color: #fff;
        font-size: 1.4rem;
      }
    }
  }

  .FormContainer {
    flex: 2;
    margin-bottom: 2rem;
    flex-direction: column;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .MainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-height: 100dvh;

    .LeftContent {
      flex: unset;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: $v2maingreen;
      position: relative;
      padding: 0.8rem 0.85rem;

      .PeriopsisLogo {
        max-width: 8rem;
      }

      .HomePageRedirectSection {
        display: flex;
        width: max-content;
        height: max-content;
        position: relative;
        bottom: 0;
        align-items: center;
        justify-content: center;

        a svg {
          color: #fff;
          font-size: 1.4rem;
        }
      }
    }

    .FormContainer {
      flex: 2;
      height: 100%;
      display: flex;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .MainContainer {
    .FormContainer {
      flex: 2;
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .MainContainer {
    flex-direction: row;

    .LeftContent {
      flex: 1;
      flex-direction: column;
      padding: unset;
      justify-content: center;

      .PeriopsisLogo {
        max-width: 13rem;
      }

      .HomePageRedirectSection {
        display: flex;
        width: 100%;
        height: 25%;
        position: absolute;
        bottom: 0;
        align-items: center;
        justify-content: center;

        a svg {
          color: #fff;
          font-size: 1.9rem;
        }
      }
    }

    .FormContainer {
      flex: 4;
      margin: auto;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .MainContainer {
    .LeftContent {
      .PeriopsisLogo {
        max-width: 16rem;
      }

      .HomePageRedirectSection {
        a svg {
          font-size: 2rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .MainContainer {
    .LeftContent {
      .PeriopsisLogo {
        max-width: 19rem;
      }

      .HomePageRedirectSection {
        a svg {
          font-size: 2.2rem;
        }
      }
    }
  }
}

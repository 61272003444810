@import '../../../../sass/manifest';

.NotificationsListContainer {
  display: flex;
  flex-direction: column;
  gap: 0.45rem;

  .HeadingContainer {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: $v2maingreen;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 0 none;
      border-radius: 50%;
      cursor: pointer;

      &:focus {
        outline: 1px solid $v2maingreen;
      }
    }

    h3 {
      text-transform: uppercase;
      cursor: pointer;
      font-size: 0.9rem;
    }

    svg {
      font-size: 1rem;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .NotificationsListContainer {
    display: flex;
    flex-direction: column;
    gap: 0.45rem;

    .HeadingContainer {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      color: $v2maingreen;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 0 none;
        border-radius: 50%;
        cursor: pointer;

        &:focus {
          outline: 1px solid $v2maingreen;
        }
      }

      h3 {
        text-transform: uppercase;
        cursor: pointer;
        font-size: 0.98rem;
      }

      svg {
        font-size: 1.25rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .NotificationsListContainer {
    .HeadingContainer {
      h3 {
        font-size: 1.2rem;
      }

      svg {
        font-size: 1.2rem;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .NotificationsListContainer {
    .HeadingContainer {
      h3 {
        font-size: 1.1rem;
      }

      svg {
        font-size: 1.2rem;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .NotificationsListContainer {
    .HeadingContainer {
      h3 {
        font-size: 1.3rem;
      }

      svg {
        font-size: 1.25rem;
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../../../sass/manifest';

.CreditCardPaymentOptionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.3rem 0.6rem;

  .Images {
    display: flex;
    max-width: 100%;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin: 0.4rem auto 0 auto;
    min-height: 2.5rem;

    img {
      max-width: 2.5rem;
    }
  }

  .CardForm {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    padding: 0.5rem 0 0.8rem 0;

    .CardNumberSection {
      display: flex;
      width: 100%;
      position: relative;
      & > div {
        width: 100%;
      }

      .CardTypeImageSection {
        display: flex;
        max-width: 1.85rem;
        position: absolute;
        align-items: center;
        justify-content: center;
        right: 0.2rem;
        top: 50%;
        transform: translate(0, -50%);

        img {
          max-width: 100%;
        }
      }
    }

    .CreditCardDateSection {
      display: flex;
      width: 100%;
      gap: 0.5rem;

      .DateInput {
        display: flex;
        width: 100%;
        flex: 3;

        & > div {
          width: 100%;
        }
      }

      .CVVInput {
        display: flex;
        width: 100%;
        flex: 1;
        position: relative;

        .CVVInfo {
          position: absolute;
          background-color: transparent;
          color: $v2maingreen;
          border: 0 none;
          right: 3px;
          top: 50%;
          transform: translate(0, -50%);
          font-size: 1.05rem;
          cursor: pointer;
        }
      }
    }

    .Actions {
      margin-top: 1rem;

      button {
        width: 100%;
      }
    }
  }
}

@import '../../../sass/manifest';

.InputContainer {
  display: flex;
  flex-direction: column;

  .InputBlock {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.74rem 0 0 0;

    svg {
      color: $periopsisOrange;
      font-size: 0.92rem;
    }
  }

  .Invalid {
    border: 1px solid $danger;
    background-color: lighten($danger, 30%) !important;
    color: #fff !important;
  }

  .InvalidMessage {
    padding: 0.2rem 0 0 0.4rem;
    font-size: 0.78rem !important;
    color: $danger;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .InputContainer {
    display: flex;
    flex-direction: column;

    .InputBlock {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.74rem 0 0 0;

      svg {
        color: $periopsisOrange;
        font-size: 0.92rem;
      }
    }

    .Invalid {
      border: 1px solid $danger;
      background-color: lighten($danger, 30%) !important;
      color: #fff !important;
    }

    .InvalidMessage {
      padding: 0.2rem 0 0 0.4rem;
      font-size: 0.78rem !important;
      color: $danger;
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

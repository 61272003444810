@import '../../../../../sass/manifest';

.CloseFormContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 0.4rem;

  button {
    padding: 0.3rem;
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
    svg {
      font-size: 1.25rem;
      color: $v2maingreen;
    }
  }
}

.VisitDetailsFrom {
  gap: 0.9rem;

  .ObservedIncidentTypeField {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    width: 100%;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.1rem;
      border-radius: 50%;
      border: 0 none;
      background-color: transparent;
      cursor: pointer;

      svg {
        font-size: 1.05rem;
      }
    }
  }
}

.VisitorsFormActions {
  justify-content: flex-end;
  gap: 1rem;

  &.MainActions {
    margin-top: 0.8rem;
    padding-top: 0.8rem;
    border-top: 1px solid $periopsisGray;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .CloseFormContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 1rem;
    top: 0.4rem;

    button {
      padding: 0.5rem;
      background-color: transparent;
      border: 0 none;
      cursor: pointer;
      svg {
        font-size: 1.35rem;
        color: $v2maingreen;
      }
    }
  }

  .VisitDetailsFrom {
    gap: 1.05rem;

    .ObservedIncidentTypeField {
      display: flex;
      align-items: center;
      gap: 0.9rem;
      width: 100%;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.1rem;
        border-radius: 50%;
        border: 0 none;
        background-color: transparent;
        cursor: pointer;

        svg {
          font-size: 1.05rem;
        }
      }
    }
  }

  .VisitorsFormActions {
    justify-content: flex-end;
    gap: 1rem;

    &.MainActions {
      margin-top: 0.8rem;
      padding-top: 0.8rem;
      border-top: 1px solid $periopsisGray;
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

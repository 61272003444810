@import '../../../../../sass/manifest';

.SelectedContractSummary {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 1.3rem 0;

  .HeadingSection {
    display: flex;
    padding: 0.4rem 0;
    margin-bottom: 0.6rem;

    h3 {
      color: $v2maingreen;
      text-transform: uppercase;
      border-bottom: 1px solid $periopsisOrange;
      width: 100%;
      font-size: 1rem;
    }
  }

  .Details {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.4rem;
  }

  .ContractActions {
    padding: 0.9rem 0;
    border-bottom: 1px solid $periopsisOrange;
    justify-content: space-between;

    .ContractActionBtn {
      min-width: 22%;
      padding: 0.65rem 0;
    }
  }

  .WarningMessageSection {
    display: flex;
    padding: 0.75rem 1.05rem;
    background-color: lighten($periopsisOrange, 5%);
    margin-top: 0.4rem;
    border-radius: 0 5px 5px 0;
    border-left: 15px solid $v2maingreen;
    animation: warning 0.8s linear 3 forwards alternate;

    p {
      line-height: 1.3;
      font-size: 1rem;
      color: #fff;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .SelectedContractSummary {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0 1.3rem 0;

    .HeadingSection {
      display: flex;
      padding: 0.4rem 0;
      margin-bottom: 0.6rem;

      h3 {
        color: $v2maingreen;
        text-transform: uppercase;
        border-bottom: 1px solid $periopsisOrange;
        width: 100%;
        font-size: initial;
      }
    }

    .Details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.4rem 1.5rem;
    }

    .ContractActions {
      padding: 0.9rem 0;
      border-bottom: 1px solid $periopsisOrange;
      justify-content: space-between;

      .ContractActionBtn {
        min-width: 22%;
        padding: 0.65rem 0;
      }
    }

    .WarningMessageSection {
      display: flex;
      padding: 0.75rem 1.05rem;
      background-color: lighten($periopsisOrange, 5%);
      margin-top: 0.4rem;
      border-radius: 0 5px 5px 0;
      border-left: 15px solid $v2maingreen;
      animation: warning 0.8s linear 3 forwards alternate;

      p {
        line-height: 1.3;
        font-size: 1rem;
        color: #fff;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .SelectedContractSummary {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0 1.3rem 0;

    .HeadingSection {
      display: flex;
      padding: 0.4rem 0;
      margin-bottom: 0.6rem;

      h3 {
        color: $v2maingreen;
        text-transform: uppercase;
        border-bottom: 1px solid $periopsisOrange;
        width: 100%;
      }
    }

    .Details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.4rem 4rem;
    }

    .ContractActions {
      padding: 0.9rem 0;
      border-bottom: 1px solid $periopsisOrange;
      justify-content: space-between;

      .ContractActionBtn {
        min-width: 22%;
        padding: 0.65rem 0;
      }
    }

    .WarningMessageSection {
      display: flex;
      padding: 0.75rem 1.05rem;
      background-color: lighten($periopsisOrange, 5%);
      margin-top: 0.4rem;
      border-radius: 0 5px 5px 0;
      border-left: 15px solid $v2maingreen;
      animation: warning 0.8s linear 3 forwards alternate;

      p {
        line-height: 1.3;
        font-size: 1rem;
        color: #fff;
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@keyframes warning {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

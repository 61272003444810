@import '../../../../../../sass/manifest';

.CurrentUserContainer {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  padding: 0.75rem 0.35rem;
  border: 1px solid $v2maingreen;
  border-radius: 1rem;

  .UserData {
    display: flex;
    flex-direction: column;
    color: $v2maingreen;

    h3 {
      padding-bottom: 0.1rem;
      margin-bottom: 0.1rem;
      border-bottom: 1px solid $v2maingreen;
      font-size: 0.92rem;
    }

    .UserInformationSection {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 3rem;
    }

    .AuthContainer {
      display: flex;
      flex-direction: row;
      position: relative;
      gap: 1.5rem;
      align-items: center;
      padding: 0.3rem 0;
      width: 100%;

      .Information {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        align-items: flex-start;
        padding: 0.7rem 1.1rem;
        line-height: 1.2;

        text-align: left;
        color: $v2maingreen;

        p {
          font-size: 0.82rem;
        }
      }
    }
  }

  .Actions {
    display: flex;
    gap: 0.5rem;
    padding: 0.4rem 0;
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 900px) {
  .CurrentUserContainer {
    padding: 1.05rem 1.1rem;

    .UserData {
      h3 {
        font-size: 1.1rem;
      }

      .UserInformationSection {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5rem;

        .AuthContainer {
          .Information {
            p {
              font-size: 0.82rem;
            }
          }
        }
      }
    }

    .Actions {
      display: flex;
      gap: 0.5rem;
      padding: 0.8rem 0;
      justify-content: flex-end;
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
  .CurrentUserContainer {
    .UserData {
      .UserInformationSection {
        .AuthContainer {
          .Information {
            p {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
}

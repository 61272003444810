@import '../../../../sass/manifest';

.MainContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  .formGroup {
    position: relative;
    display: flex;
    align-items: center;

    .ShowHidePassword {
      position: absolute;
      color: #fff;
      top: 8px;
      right: 2px;
      font-size: 1.2rem;
      cursor: pointer;
    }

    .formLabel {
      position: absolute;
      top: 10px;
      left: 0;
      font-size: 0.8rem;
      color: darken($gray, 5);
      pointer-events: none;
      transition: all 0.5s ease-in-out;
      z-index: 2;
      display: flex;
      align-items: flex-end;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      .IsRequired {
        color: red;
        font-weight: 600;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: larger;
        margin: 0;
        margin-left: 10px;
        margin-top: -6px;
      }
    }

    .formInput {
      border: 0;
      border-bottom: 1px solid $lightGray;
      background: transparent;
      width: 100%;
      padding: 0.5rem 0 0.35rem 0.3rem;
      font-size: 16px;
      color: darken($gray, 5);

      &.Invalid {
        // background-color: lighten($danger, 12);
        // border-radius: 0 0.3rem 0 0;
        // box-shadow: inset 1px 1px 3px black;
        @include formElementInvalid;
      }

      &.Invalid ~ label {
        left: 0.2rem;
        color: #fff !important;
      }

      &:focus {
        border: none;
        outline: none;
        border-bottom: 1px solid $v2maingreen;
      }

      &:focus ~ label {
        top: -4px;
        font-size: 11px;
        color: darkgray;
      }
    }
  }

  .InformationBoard {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    bottom: calc(100% + 0.5rem);
    background-color: $v2maingreen;
    color: #fff;
    padding: 1rem 1.2rem 2rem 1.2rem;
    box-shadow: 1px -2px 5px rgb(54, 54, 54);
    z-index: 999;
    font-weight: 500;
    border-radius: 0.5rem 0.5rem 0 0;
    font-size: 0.73rem;
    opacity: 0.8;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .MainContainer {
    .InformationBoard {
      bottom: calc(100% + 0.2rem);
      padding: 0.3rem 1.2rem 0.4rem 1.2rem;
      font-weight: 400;
      font-size: 0.76rem;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .MainContainer {
    .InformationBoard {
      bottom: calc(100% + 0.35rem);
      padding: 0.5rem 1.2rem 0.5rem 1.2rem;
      font-weight: 400;
      font-size: 0.82rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .MainContainer {
    .InformationBoard {
      bottom: calc(100% + 0.5rem);
      padding: 0.8rem 1.2rem 1.1rem 1.2rem;
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .MainContainer {
    .InformationBoard {
      bottom: calc(100% + 0.5rem);
      padding: 0.8rem 1.2rem 1.1rem 1.2rem;
      font-weight: 500;
      font-size: 1.02rem;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .MainContainer {
    .InformationBoard {
      bottom: calc(100% + 0.5rem);
      padding: 1rem 1.2rem 1.5rem 1.2rem;
      font-weight: 500;
      font-size: 1.08rem;
    }
  }
}

@import '../../../sass/manifest';

.LanguageWindow {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25rem;
  max-width: 95%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 10px rgba(31, 31, 31, 0.85);

  .HeadingSection {
    display: flex;
    padding: 0.6rem;
    align-items: center;
    justify-content: space-between;
    color: $v2maingreen;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid $v2maingreen;

    .Header {
      display: flex;
      gap: 0.4rem;
      align-items: center;

      svg,
      h3 {
        font-size: 1.05rem;
      }
    }

    button {
      background-color: transparent;
      border: 0 none;
      font-size: 1.2rem;
    }
  }

  .Languages {
    display: flex;
    flex-direction: column;
    padding: 1.75rem 1.05rem;
    gap: 0.5rem;

    button {
      width: 100%;
      padding: 0.6rem 0;
      border: 0 none;
      background-color: $v2maingreen;
      color: $white;
      border-radius: 0.5rem;
      cursor: pointer;

      &:hover {
        background-color: lighten($v2maingreen, 10%);
      }

      &.Active {
        background-color: $periopsisOrange;

        &:hover {
          background-color: $periopsisOrange;
        }
      }
    }
  }
}

@import '../../../sass/manifest';

.SendPageContainer {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  max-width: 99%;
  margin: 0 auto;
  width: 100%;

  .Main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0 0.5rem 0;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .SendPageContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
    max-width: 98%;
    width: 98%;
    margin: 0 auto;

    .Main {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1.2rem 0 0.5rem 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  .SendPageContainer {
    padding: 0.8rem 0;
    max-width: 96%;
    width: 96%;

    .Main {
      padding: 1.3rem 0 0.6rem 0;
    }
  }
}

@media only screen and (min-width: 900px) {
  .SendPageContainer {
    padding: 0.8rem 0;
    max-width: 98%;
    width: 96%;

    .Main {
      padding: 1.3rem 0 0.6rem 0;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .SendPageContainer {
    max-width: 85%;
    width: 85%;

    .Main {
      padding: 1.8rem 0 0.9rem 0;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .SendPageContainer {
    padding: 1rem 0;
    max-width: 80%;
    width: 80%;
  }
}

@media only screen and (min-width: 1920px) {
  .SendPageContainer {
    max-width: 75%;
  }
}

@import '../../../../../sass/manifest';

.Section {
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;
  gap: 0.4rem;
  color: $v2maingreen;
  margin-bottom: 0.2rem;
  border-bottom: 1px solid $lightGray;

  &.RemoveBorder {
    border: 0 none;
  }

  .NoteContainer {
    display: flex;

    justify-content: space-between;
    position: relative;

    p {
      max-width: 92%;
      font-size: 0.82rem;
    }

    svg {
      font-size: 1.25rem;
    }
  }
}

.EditNotesActions {
  display: flex;
  align-items: center;
  gap: 0.9rem;
  position: absolute;
  right: 0.5rem;
  top: 15px;

  svg {
    cursor: pointer;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .Section {
    padding: 0.65rem 0;
    gap: 0.4rem;

    .NoteContainer {
      p {
        font-size: 0.95rem;
      }

      svg {
        font-size: 1.3rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .Section {
    padding: 0.8rem 0;
    gap: 0.4rem;

    margin-bottom: 0.3rem;

    .NoteContainer {
      p {
        max-width: 90%;
        font-size: 1.04rem;
      }

      svg {
        font-size: 1.35rem;
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

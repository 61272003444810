@import '../../../../../sass/manifest';

.TimelineItemContainer {
  display: flex;
  flex-direction: column;
  border: 4px solid transparent;
  max-width: 14.5rem;
  max-height: 14.5rem;
  min-width: 14.5rem;
  min-height: 14.5rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0;
  position: relative;
  overflow: hidden;
  scroll-snap-align: center;

  .DateContainer {
    display: flex;
    width: 100%;
    padding: 0.4rem 0;
    background-color: $v2maingreen;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.05rem;
    font-weight: 600;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    &.DetectedDateImage {
      background-color: $periopsisOrange;
    }
  }

  &.TimelineTargetBorader {
    border: 4px solid $periopsisOrange;
  }

  .CurrentIncidentImage {
    filter: grayscale(0%);
    opacity: 1;
  }

  img {
    width: 100%;
    filter: grayscale(95%);
    opacity: 0.5;
    width: 14.5rem;
    height: 14.5rem;
    object-fit: cover;
    object-position: center;
    animation: skeleton-loading 1s infinite alternate;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

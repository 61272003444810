@import '../../../sass/manifest';

.TermsAndConditionsContainer {
  position: fixed;
  top: -100%;
  bottom: 100%;
  background-color: #fff;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
  min-width: 98%;
  max-width: 98%;
  overflow: hidden;
  color: $v2maingreen;

  &.Show {
    top: 1%;
    bottom: 1%;
    z-index: 65000;
    display: flex;
    border-radius: 5px;
    box-shadow: 2px 2px 10px $v2maingreen;
  }

  .ContentContainer {
    display: flex;
    flex-direction: column;
    padding: 0.4rem 0.8rem;
    width: 100%;

    .HeadingSection {
      padding: 0.7rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background-color: $v2maingreen;
      margin-bottom: 0.55rem;
      position: relative;

      h1 {
        margin: 0;
        padding: 0;
        text-align: left;
        font-size: 1rem;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
      }

      .CloseWindow {
        position: absolute;
        right: 1rem;
        background-color: transparent;
        border: 0 none;
        font-size: 1.2rem;
        color: #fff;
        cursor: pointer;
      }
    }

    .Terms {
      max-height: 92%;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      width: 100%;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: lightgray;
        box-shadow: inset 1px 1px 3px black;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $v2maingreen;
      }

      ul {
        list-style: none;
        padding: 0.4rem 0.8rem;
        display: flex;
        flex-direction: column;
        row-gap: 1.1rem;

        li {
          h3 {
            font-size: 0.9rem;
            margin-bottom: 0.25rem;
            letter-spacing: -0.028rem;
            text-transform: capitalize;
          }

          p {
            font-size: 0.73rem;
            letter-spacing: 0.03rem;
            line-height: 1.15;
          }
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .TermsAndConditionsContainer {
    min-width: 99%;
    max-width: 99%;

    .ContentContainer {
      padding: 0.5rem 0.7rem;

      .HeadingSection {
        padding: 0.6rem 0 0.6rem 0.2rem;
        justify-content: flex-start;
        margin-bottom: 0.5rem;

        h1 {
          text-align: left;
          font-size: 1rem;
          letter-spacing: 0.05rem;
        }
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}
@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .TermsAndConditionsContainer {
    min-width: 95%;
    max-width: 95%;

    .ContentContainer {
      padding: 0.9rem 1.2rem;

      .HeadingSection {
        padding: 0.75rem 0;
        justify-content: center;
        margin-bottom: 0.5rem;

        h1 {
          text-align: center;
          font-size: 1.1rem;
          letter-spacing: 0.1rem;
        }
      }

      .Terms {
        ul {
          padding: 0.4rem 0.9rem;

          li {
            h3 {
              font-size: 0.92rem;
            }

            p {
              font-size: 0.78rem;
              letter-spacing: 0.03rem;
              line-height: 1.13;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .TermsAndConditionsContainer {
    min-width: 90%;
    max-width: 90%;
  }
}

@media (min-width: 900px) {
  .TermsAndConditionsContainer {
    min-width: 70%;
    max-width: 70%;

    .ContentContainer {
      padding: 0.9rem 1.2rem;

      .HeadingSection {
        padding: 0.9rem 0;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.55rem;

        h1 {
          text-align: center;
          font-size: 1.2rem;
          letter-spacing: 0.1rem;
        }
      }

      .Terms {
        ul {
          padding: 0.5rem 1rem;
          display: flex;
          flex-direction: column;
          row-gap: 1.1rem;

          li {
            h3 {
              font-size: 0.95rem;
              margin-bottom: 0.25rem;
              letter-spacing: -0.028rem;
              text-transform: capitalize;
            }

            p {
              font-size: 0.81rem;
              letter-spacing: 0.04rem;
              line-height: 1.15;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .TermsAndConditionsContainer {
    min-width: 55%;
    max-width: 55%;

    .ContentContainer {
      padding: 0.9rem 1.1rem;

      .HeadingSection {
        padding: 0.78rem 0;
        margin-bottom: 0.53rem;

        h1 {
          text-align: center;
          font-size: 1.15rem;
          letter-spacing: 0.05rem;
        }
      }

      .Terms {
        ul {
          padding: 0.45rem 1rem;
          row-gap: 1.04rem;

          li {
            h3 {
              font-size: 0.92rem;
              margin-bottom: 0.23rem;
            }

            p {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1536px) {
  .TermsAndConditionsContainer {
    min-width: 49%;
    max-width: 49%;

    .ContentContainer {
      padding: 0.9rem 1.15rem;

      .HeadingSection {
        padding: 0.9rem 0;
        margin-bottom: 0.55rem;

        h1 {
          text-align: center;
          font-size: 1.25rem;
          letter-spacing: 0.05rem;
        }
      }

      .Terms {
        ul {
          padding: 0.5rem 1rem;
          row-gap: 1.1rem;

          li {
            h3 {
              font-size: 0.98rem;
              margin-bottom: 0.25rem;
              letter-spacing: -0.028rem;
              text-transform: capitalize;
            }

            p {
              font-size: 0.81rem;
              letter-spacing: 0.04rem;
              line-height: 1.15;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .TermsAndConditionsContainer {
    min-width: 39%;
    max-width: 39%;
  }
}

.ObservedIncidentTypeField {
  display: flex;
  align-items: center;
  gap: 0.9rem;
  width: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem;
    border-radius: 50%;
    border: 0 none;
    background-color: transparent;
    cursor: pointer;

    svg {
      font-size: 1.05rem;
    }
  }
}

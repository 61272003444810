@import '../../../sass/manifest';

.NotificationsContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 95%;
  margin: 0 auto;

  .ContentContainer {
    display: none;
    height: 70vh;
    height: 70dvh;
    margin-top: 2rem;
    gap: 1.2rem;

    .ListContainer {
      gap: 2.5rem;
    }

    .NotificationProcessContainer {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  .ContentContainerMobile {
    display: flex;

    .ListContainer {
      gap: 1.5rem;
    }

    .NotificationProcessContainer {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      align-items: center;
      justify-content: center;
      text-align: center !important;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .NotificationsContainer {
    display: flex;
    flex-direction: column;
    max-width: 95%;
    margin: 0 auto 0 auto;
    width: 95%;

    .ContentContainer {
      display: flex;
      height: 51vh;
      height: 51dvh;
      margin-top: 1rem;
      gap: 1rem;

      .ListContainer {
        gap: 2.5rem;
      }

      .NotificationProcessContainer {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        align-items: center;
        justify-content: center;
      }
    }

    .ContentContainerMobile {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) {
  .NotificationsContainer {
    max-width: 98%;
    width: 98%;

    .ContentContainer {
      height: 70vh;
      height: 70dvh;
      margin-top: 1rem;

      .ListContainer {
        gap: 2rem;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .NotificationsContainer {
    max-width: 95%;
    width: 95%;

    .ContentContainer {
      height: 65vh;
      height: 65dvh;
      margin-top: 1.5rem;
      gap: 1rem;

      .ListContainer {
        gap: 2rem;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .NotificationsContainer {
    max-width: 85%;
    width: 85%;

    .ContentContainer {
      display: flex;
      height: 70vh;
      height: 70dvh;
      margin-top: 2rem;
      gap: 1.2rem;

      .ListContainer {
        gap: 2.5rem;
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .NotificationsContainer {
    max-width: 80%;
    width: 80%;
  }
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../sass/variables.scss';
@import '../../../../sass/mixins.scss';

.RadioButtonContainer {
  label {
    @include FlexCenter;
    color: darken($gray, 5);
    flex-direction: column;
    max-width: 6.8rem;
    min-width: 3.5rem;
    padding: 0.2rem 0.7rem;
    font-size: 0.7rem;
    cursor: pointer;
    border: 1px solid $v2maingreen;
    border-radius: 3rem;
    box-shadow: 3px 3px 6px rgb(83, 83, 83);
    margin: 0 0.2rem;
    transition: all 0.5s linear;
    gap: 0.3rem;
  }

  input[type='radio'],
  input[type='checkbox'] {
    display: none;
  }

  input[type='radio']:checked + label,
  .LabelChecked {
    color: #fff;

    background-color: $v2maingreen;
    box-shadow: inset 2px 2px 3px lighten($v2maingreen, 20%);
    cursor: pointer;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .RadioButtonContainer {
    label {
      flex-direction: row;
      max-width: 8rem;
      min-width: 3rem;
      padding: 0.3rem 0.5rem;
      min-width: 5rem;
      font-size: 0.83rem;
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

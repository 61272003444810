@import '../../../../sass/manifest';

.StatisticsMainContainer {
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0rem;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  border-top: 8px solid $v2maingreen;
  min-height: 90vh;
  min-height: 90dvh;

  .HeadingSection {
    display: flex;
    padding: 0.4rem 0.55rem;
    justify-content: space-between;
    align-items: center;

    text-transform: uppercase;
    background-color: $v2maingreen;
    color: #fff;

    h2 {
      font-weight: 600;
      font-size: 0.79rem;
    }

    .DropdownContainer {
      display: flex;
      width: 6rem;
      select {
        color: #fff !important;
        border-color: #fff;

        option {
          color: $v2maingreen;
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 800px) {
  .StatisticsMainContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.2rem;
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    border-top: 8px solid $v2maingreen;
    min-height: 83vh;
    min-height: 83dvh;

    .HeadingSection {
      display: flex;
      padding: 0.5rem 0.8rem;
      justify-content: space-between;
      align-items: center;

      text-transform: uppercase;
      background-color: $v2maingreen;
      color: #fff;

      h2 {
        font-weight: 600;
        font-size: 0.9rem;
      }

      .DropdownContainer {
        display: flex;
        width: 7rem;
        select {
          color: #fff !important;
          border-color: #fff;

          option {
            color: $v2maingreen;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .StatisticsMainContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.2rem;
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    border-top: 8px solid $v2maingreen;
    min-height: 78vh;
    min-height: 78dvh;

    .HeadingSection {
      display: flex;
      padding: 0.5rem 0.8rem;
      justify-content: space-between;
      align-items: center;

      text-transform: uppercase;
      background-color: $v2maingreen;
      color: #fff;

      h2 {
        font-weight: 600;
        font-size: 0.9rem;
      }

      .DropdownContainer {
        display: flex;
        width: 7rem;
        select {
          color: #fff !important;
          border-color: #fff;

          option {
            color: $v2maingreen;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .StatisticsMainContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.2rem;
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    border-top: 8px solid $v2maingreen;
    min-height: 79vh;
    min-height: 79dvh;

    .HeadingSection {
      display: flex;
      padding: 0.5rem 0.8rem;
      justify-content: space-between;
      align-items: center;

      text-transform: uppercase;
      background-color: $v2maingreen;
      color: #fff;

      h2 {
        font-weight: 600;
        font-size: 0.9rem;
      }

      .DropdownContainer {
        display: flex;
        width: 7rem;
        select {
          color: #fff !important;
          border-color: #fff;

          option {
            color: $v2maingreen;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .StatisticsMainContainer {
    display: flex;
    flex-direction: column;
    padding: 1.45rem 2rem;
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    border-top: 8px solid $v2maingreen;
    min-height: 83vh;
    min-height: 83dvh;

    .HeadingSection {
      display: flex;
      padding: 0.6rem 1rem;
      justify-content: space-between;
      align-items: center;

      text-transform: uppercase;
      background-color: $v2maingreen;
      color: #fff;

      h2 {
        font-weight: 600;
        font-size: 1.05rem;
      }

      .DropdownContainer {
        display: flex;
        width: 9rem;
        select {
          color: #fff !important;
          border-color: #fff;

          option {
            color: $v2maingreen;
          }
        }
      }
    }
  }
}

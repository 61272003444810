@import '../../../sass/manifest';

.ErrorMessage {
  text-align: left;
  color: #fff;
  background-color: lighten($danger, 9%);
  padding: 0.8rem 1.06rem;
  font-size: 0.83rem;
  line-height: 1.3;
  max-width: 25vw;
  max-width: 25dvw;
}

@import '../../../../sass/manifest';
.MainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  .DropdownContainer {
    display: flex;
    width: 100%;

    .DropDownButton {
      width: 2.5rem;
      color: darken($gray, 5);
      background-color: transparent;
      border: 0 none;
      border-bottom: 1px solid $lightGray;
      cursor: pointer;

      &:focus {
        background-color: $v2maingreen;
        color: #fff;
      }

      &::after {
        content: '\25bc';
        font-size: 0.8rem;
      }
    }

    .Selected {
      display: flex;
      width: 100%;

      input {
        font-size: 0.9rem;

        &::placeholder {
          font-size: 0.9rem;
        }
      }

      div {
        width: 100%;
      }
    }
  }

  .Options {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: 100%;
    z-index: 999;
    max-height: 10rem;
    height: 0rem;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: hidden;
    scrollbar-width: thin;
    transition: all 0.2s linear;
    box-shadow: 2px 5px 10px black;

    .PressEnter {
      position: absolute;
      right: 1.5rem;
      top: 9%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff;
        background-color: $v2maingreen;
        width: 100%;
        padding: 0 0.4rem;
        animation: press 0.8s linear 0s infinite alternate;
        border-radius: 4px;
        font-size: 1.15 rem;
      }
    }

    .CountryList {
      display: flex;
      width: 100%;
      flex-direction: column;
      height: inherit;
      overflow-y: auto;
    }

    input::placeholder {
      font-size: 0.9rem;
    }

    .NoCountry {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.45rem 0.3rem;
      width: 100%;
      gap: 0.4rem;

      svg {
        color: $danger;
        font-size: 0.85rem;
      }
    }

    p {
      font-size: 0.9rem;
      color: $lightGray;
    }

    &.Show {
      height: 10rem;
      max-height: 10rem;
    }

    & > button:nth-child(1) {
      border-top: 1px solid $v2maingreen;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: $lightGray;
      box-shadow: inset 1px 1px 3px black;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $v2maingreen;
      border-radius: 20px;
    }

    .Option {
      padding: 0.45rem 0.3rem;
      display: flex;
      background-color: transparent;
      border: 0 none;
      gap: 0.5rem;
      border-bottom: 1px solid $lightGray;
      color: $v2maingreen;
      cursor: pointer;

      &.Selected {
        background-color: $v2maingreen;
        color: #fff;
      }

      &:hover {
        background-color: $v2maingreen;
        color: #fff;
      }
    }
  }
}

@keyframes press {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@import '../../../../../../sass/manifest';

.MessagingDetails {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  .ReportToField {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.2rem;
    column-gap: 2rem;
  }

  .EmailsField {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    padding: 1rem 0;

    .SendCopyWrapper {
      display: flex;
      padding: 0.2rem 0;
      justify-content: flex-end;
      .SendCopy {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;
        font-size: 0.75rem;
      }
    }
  }

  .MessageField {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

.CCBCC {
  position: absolute;
  right: 0;
  font-size: 0.7rem !important;
  cursor: pointer;
  font-weight: 500 !important;

  &.ShowCCBCC {
    color: $periopsisOrange;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .MessagingDetails {
    gap: 0.9rem;

    .ReportToField {
      grid-template-columns: repeat(2, 1fr);
    }

    .EmailsField {
      .SendCopyWrapper {
        .SendCopy {
          font-size: 0.82rem;
        }
      }
    }

    .MessageField {
      gap: 2rem;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

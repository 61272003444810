.ReportWindowActions {
  display: flex;
  padding: 1rem 0;
  align-items: center;
  justify-content: space-between;

  .MainButtons {
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }

  .DashboardBtnContainer {
    display: flex;
  }

  .ReportWindowButton {
    min-width: 9.9rem;
    padding: 0.95rem 0.3rem;
    border-radius: 10px;
    font-size: 1rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .ReportWindowActions {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    justify-content: space-between;

    .MainButtons {
      display: flex;
      align-items: center;
      gap: 0.6rem;
    }

    .DashboardBtnContainer {
      display: flex;
    }

    .ReportWindowButton {
      min-width: 6.5rem;
      padding: 0.65rem 0.1rem;
      border-radius: 10px;
      font-size: 0.72rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .ReportWindowActions {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    justify-content: space-between;

    .MainButtons {
      display: flex;
      align-items: center;
      gap: 0.3rem;
    }

    .DashboardBtnContainer {
      display: flex;
    }

    .ReportWindowButton {
      min-width: 7rem;
      padding: 0.6rem 0.1rem;
      border-radius: 10px;
      font-size: 0.78rem;
    }
  }
}

@media only screen and (min-width: 900px) {
  .ReportWindowActions {
    .MainButtons {
      gap: 0.4rem;
    }

    .ReportWindowButton {
      min-width: 8.2rem;
      padding: 0.8rem 0.2rem;
      font-size: 0.86rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .ReportWindowActions {
    .MainButtons {
      gap: 0.6rem;
    }

    .ReportWindowButton {
      min-width: 9.5rem;
      padding: 0.8rem 0.3rem;

      font-size: 0.95rem;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .ReportWindowActions {
    .MainButtons {
      gap: 0.8rem;
    }

    .ReportWindowButton {
      min-width: 9.9rem;
      padding: 0.8rem 0.3rem;

      font-size: 1rem;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .ReportWindowActions {
    .MainButtons {
      gap: 0.9rem;
    }

    .ReportWindowButton {
      min-width: 10.05rem;
      padding: 0.8rem 0.3rem;

      font-size: 1.06rem;
    }
  }
}

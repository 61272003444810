@import '../../../../sass/manifest';

.TabsContainer {
  display: flex;
  gap: 0.15rem;
  border-bottom: 15px solid $v2maingreen;

  .Tab {
    border-bottom: 1px solid $v2maingreen;
  }
}

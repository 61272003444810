@import '../../../../../../../../sass/manifest';

.Wrapper {
  display: flex;
  flex-direction: column;

  p {
    padding: 1.1rem 0 1.1rem 0;
    font-size: 0.78rem;
    letter-spacing: 1px;
    line-height: 1.3;
    color: $lightGray;
  }

  .CodeEntryContainer {
    display: flex;
    column-gap: 0.6rem;
    width: 100%;
    justify-content: center;
  }
  .Actions {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 0.9rem;

    .CodeButton {
      max-width: 100% !important;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .Wrapper {
    p {
      padding: 1.2rem 0 1.2rem 0;
      font-size: 0.85rem;
      letter-spacing: 1.1px;
    }

    .Actions {
      flex-direction: row;

      .CodeButton {
        max-width: 50% !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../sass/manifest';

.MainContainer {
  display: flex;
  flex-direction: column;
  padding: 0.8rem 0;

  h3 {
    padding: 0.5rem 0;
    background-color: $v2maingreen;
    color: #fff;
    font-size: 1.09rem;
    text-transform: uppercase;
    text-align: center;
  }

  .OrganizationUsersListWrapper {
    display: flex;
    flex-direction: column;
    padding: 0.8rem 0;

    .OrganizationUsersTable {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0rem;
      border-bottom: 1px solid $periopsisOrange;

      tr {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 0.8rem 0rem;
        width: 100%;

        th,
        td {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.95rem;
          font-weight: 500;
          padding: 0.5rem 0;
        }

        th {
          border-bottom: 1px solid $periopsisOrange;
        }
      }
    }
  }
}

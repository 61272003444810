@import '../../../../../../sass/manifest';
.SummaryWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px dotted $v2maingreen;
  border-radius: 10px;
  .SummaryContent {
    display: flex;
    flex-direction: column;
    padding: 1rem 0.4rem 0.3rem 0.4rem;

    .SelectionsList {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: $v2maingreen;
      justify-content: center;
      align-items: flex-start;

      .HeadingSection {
        display: flex;
        width: 100%;
        margin-bottom: 0.5rem;
        border-bottom: 1px dotted $v2maingreen;
        padding: 0.1rem;

        h3 {
          font-size: 0.9rem;

          &::after {
            content: ':';
            margin-left: 0.2rem;
          }
        }
      }

      &.RightSeparator {
        margin-right: 0;
        border-right: 0;
        padding: 0 0 0.9rem 0;
        width: 100%;

        ul {
          display: flex;
          flex-direction: column;
          width: 100%;
          li {
            display: flex;
            flex-direction: row;
            gap: 0.2rem;
            width: max-content;
            justify-content: space-between;
            width: 100%;
          }
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        row-gap: 0.45rem;
        font-size: 0.65rem;
        width: 100%;

        li {
          display: grid;
          grid-template-columns: 2fr 1px 1fr;
          gap: 0.45rem;

          span {
            font-weight: 600;
          }
        }

        .OverallTotal {
          padding-top: 0.3rem;
          margin-top: 0.3rem;
          border-top: 1px dotted $v2maingreen;
        }

        .AnnualPrice,
        .OverallTotal {
          font-weight: 500;
          font-size: 0.75rem;
        }
      }
    }
  }

  .ActionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.4rem 0.2rem;
    justify-content: space-between;
    margin: 0 auto;
    border-top: 1px dotted $v2maingreen;
    gap: 0.4rem;

    .Actions {
      display: flex;
      width: 100%;
      justify-content: space-between;

      button {
        width: 50%;
      }
    }

    .PDFContainer {
      display: flex;
      width: 100%;
      align-items: center;

      .ServiceDetails {
        text-decoration: none;
        color: #fff;
        padding: 0.5rem 1.3rem;
        background-color: $v2maingreen;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.35rem;
        transition: all 0.3s linear;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        width: 100%;

        svg {
          color: $danger;
        }

        &:hover {
          border-radius: 0.3rem;
          box-shadow: 0 0 10px $v2maingreen;
          background: #00628d;
        }
      }
    }

    .DeclineBtn {
      background-color: $danger;
      margin-right: 1.1rem;

      &:hover {
        box-shadow: 0 0 10px $danger;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .SummaryWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px dotted $v2maingreen;
    border-radius: 10px;
    .SummaryContent {
      display: flex;
      flex-direction: column;
      padding: 1rem 0.4rem 0.3rem 0.4rem;

      .SelectionsList {
        display: flex;
        flex-direction: column;
        width: 100%;
        color: $v2maingreen;
        justify-content: center;
        align-items: flex-start;

        .HeadingSection {
          display: flex;
          width: 100%;
          margin-bottom: 0.5rem;
          border-bottom: 1px dotted $v2maingreen;
          padding: 0.1rem;

          h3 {
            font-size: 0.9rem;

            &::after {
              content: ':';
              margin-left: 0.2rem;
            }
          }
        }

        &.RightSeparator {
          margin-right: 0;
          border-right: 0;
          padding: 0 0 0.9rem 0;
          width: 100%;

          ul {
            display: flex;
            flex-direction: column;
            width: 100%;
            li {
              display: flex;
              flex-direction: row;
              gap: 0.2rem;
              width: max-content;
              justify-content: space-between;
              width: 100%;
            }
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          list-style: none;
          row-gap: 0.45rem;
          font-size: 0.65rem;
          width: 100%;

          li {
            display: grid;
            grid-template-columns: 2fr 1px 1fr;
            gap: 0.45rem;

            span {
              font-weight: 600;
            }
          }

          .OverallTotal {
            padding-top: 0.3rem;
            margin-top: 0.3rem;
            border-top: 1px dotted $v2maingreen;
          }

          .AnnualPrice,
          .OverallTotal {
            font-weight: 500;
            font-size: 0.75rem;
          }
        }
      }
    }

    .ActionContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0.4rem 0.2rem;
      justify-content: space-between;
      margin: 0 auto;
      border-top: 1px dotted $v2maingreen;
      gap: 0.4rem;

      .Actions {
        display: flex;
        width: 100%;
        justify-content: space-between;

        button {
          width: 50%;
        }
      }

      .PDFContainer {
        display: flex;
        width: 100%;
        align-items: center;

        .ServiceDetails {
          text-decoration: none;
          color: #fff;
          padding: 0.5rem 1.3rem;
          background-color: $v2maingreen;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.35rem;
          transition: all 0.3s linear;
          text-transform: uppercase;
          letter-spacing: 0.1rem;
          width: 100%;

          svg {
            color: $danger;
          }

          &:hover {
            border-radius: 0.3rem;
            box-shadow: 0 0 10px $v2maingreen;
            background: #00628d;
          }
        }
      }

      .DeclineBtn {
        background-color: $danger;
        margin-right: 1.1rem;

        &:hover {
          box-shadow: 0 0 10px $danger;
        }
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .SummaryWrapper {
    .SummaryContent {
      padding: 1rem 0;
      flex-direction: row;

      .SelectionsList {
        align-items: center;

        .HeadingSection {
          padding: 0.2rem;

          h3 {
            font-size: 0.9rem;
          }
        }

        &.RightSeparator {
          margin-right: 0.8rem;
          border-right: 1px dotted $v2maingreen;
          padding: 0 0.8rem 0 0.2rem;
          width: 40%;

          ul {
            li {
              flex-direction: column;
              gap: 0.2rem;
              width: max-content;
              justify-content: unset;
            }
          }
        }

        ul {
          row-gap: 0.6rem;
          font-size: 0.7rem;

          li {
            gap: 0.75rem;
          }

          .AnnualPrice,
          .OverallTotal {
            font-size: 0.78rem;
          }
        }
      }
    }

    .ActionContainer {
      flex-direction: row;
      width: 95%;
      padding: 0.5rem 0.2rem;
      gap: unset;

      .Actions {
        width: unset;
        justify-content: unset;

        button {
          width: unset;
        }
      }

      .PDFContainer {
        width: 60%;

        .ServiceDetails {
          padding: 0.6rem 1.4rem;
          justify-content: unset;
          letter-spacing: 0.15rem;
          width: unset;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .SummaryWrapper {
    .SummaryContent {
      padding: 1.2rem 0;

      .SelectionsList {
        .HeadingSection {
          padding: 0.3rem;

          h3 {
            font-size: 1.05rem;
          }
        }

        &.RightSeparator {
          margin-right: 0.8rem;
          border-right: 1px dotted $v2maingreen;
          padding: 0 0.8rem 0 0.2rem;
          width: 40%;
        }

        ul {
          row-gap: 0.65rem;
          font-size: 0.85rem;
          width: 100%;

          li {
            gap: 0.75rem;
          }

          .OverallTotal {
            padding-top: 0.45rem;
          }

          .AnnualPrice,
          .OverallTotal {
            font-weight: 700;
            font-size: 0.95rem;
          }
        }
      }
    }

    .ActionContainer {
      width: 95%;
      padding: 0.5rem 0;

      .PDFContainer {
        width: 60%;

        .ServiceDetails {
          padding: 0.6rem 1.4rem;
          letter-spacing: 0.15rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../../sass/manifest';

.SelectionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.6s linear;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 600;
  overflow: hidden;
  min-height: 14.3rem;

  &.WaitingInQueue {
    left: -200%;
  }

  &.Completed {
    left: 200%;
  }

  &.MakeScrollable {
    justify-content: flex-start;
    @include scroll-Y;
  }

  .ChildWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    width: 95%;
    padding: 1rem 0;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .SelectionContainer {
    display: flex;
    flex-direction: column;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    width: 100%;
    transition: all 0.6s linear;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 600;
    min-height: 16rem;

    &.WaitingInQueue {
      left: -200%;
    }

    &.Completed {
      left: 200%;
    }

    .ChildWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      width: 95%;
      padding: 1rem 0;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .SelectionContainer {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 768px) {
  .SelectionContainer {
    justify-content: center;
    margin-top: 2.85rem;

    .ChildWrapper {
      gap: 0.8rem;
      width: 93%;
      padding: 0.3rem 0;
    }
  }
}

@media only screen and (min-width: 900px) {
  .SelectionContainer {
    margin-top: unset;
    min-height: 20rem;
  }
}

@media only screen and (min-width: 1200px) {
  .SelectionContainer {
    margin-top: unset;
    min-height: 30rem;

    .ChildWrapper {
      gap: 0.8rem;
      width: 85%;
      padding: 0.3rem 0;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .SelectionContainer {
    margin-top: unset;
    min-height: 31.5rem;

    .ChildWrapper {
      gap: 0.8rem;
      width: 85%;
      padding: 0.3rem 0;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .SelectionContainer {
    margin-top: unset;
    min-height: 35rem;
  }
}

@import '../../../sass/manifest';

.PhoneNumberEntryContainer {
  display: grid;
  grid-template-columns: max-content 1fr;
  width: 100%;
  label,
  input {
    padding-left: 0.55rem !important;
  }

  &:focus-within {
    .OptionSelectFocus {
      border-bottom: 1px solid $v2maingreen;
    }
  }

  select {
    background-color: transparent;
    border: 0 none;
    border-bottom: 1px solid $lightGray;
    color: darken($gray, 5);
    cursor: pointer;

    &:focus {
      outline: 1px solid $v2maingreen;
    }

    option {
      color: darken($gray, 5);
    }
  }
}

@import '../../../../sass/variables.scss';
@import '../../../../sass/mixins.scss';

.StandardButton {
  padding: 0.4rem;
  min-width: 80px;
  border: 0 none;
  border-radius: 0.3rem;
  cursor: pointer;
  color: #fff;
  transition: all 0.2s linear;
  font-size: 0.64rem;
  height: fit-content;
  text-align: center;

  &.TabButton {
    margin: 0 2px -1px 0 !important;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .ButtonLabel {
    display: none;
  }

  .ButtonIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    max-width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;

    .MobileLabel {
      color: #fff;
      font-size: 0.58rem;
    }
  }

  &.Fake {
    text-align: center;
    background-color: $lightGray !important;
    pointer-events: none;

    &:hover {
      box-shadow: none !important;
    }
  }

  &.LinkBtn {
    text-decoration: none;
    text-align: center;
  }

  &.Primary {
    @include primary($hasHoverEffect: true);
  }

  &.PrimaryLight {
    @include primaryLight($hasHoverEffect: true);
  }

  &.Peach {
    @include peach($hasHoverEffect: true);
  }

  &.PrimaryLightBlue {
    @include primaryLightBlue($hasHoverEffect: true);
  }

  &.Info {
    @include info($hasHoverEffect: true);
  }
  &.Danger {
    @include danger($hasHoverEffect: true);
  }
  &.Warning {
    @include warning($hasHoverEffect: true);
  }

  &.Success {
    @include success($hasHoverEffect: true);
  }

  &.Main {
    @include main($hasHoverEffect: true);
  }

  &.Orange {
    @include orange($hasHoverEffect: true);
  }

  &.Plain {
    @include plain($hasHoverEffect: true);
  }

  &:active {
    box-shadow: inset 1px 1px 3px black !important;
    transform: scale(0.99);
  }
}

@media only screen and (min-width: 768px) {
  .StandardButton {
    font-size: 0.8rem;

    .ButtonIcon {
      display: none;
    }

    .ButtonLabel {
      display: block;
    }
  }
}

@media only screen and (min-width: 900px) {
  .StandardButton {
    font-size: 0.83rem;

    .ButtonIcon {
      display: none;
    }

    .ButtonLabel {
      display: block;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .StandardButton {
    font-size: 0.85rem;

    .ButtonIcon {
      display: none;
    }

    .ButtonLabel {
      display: block;
    }
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

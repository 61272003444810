@import '../../../../../../sass/manifest';

.PasswordStrengthTestContainer {
  display: flex;
  flex-direction: column;
  padding: 0.8rem 0 0 0;
  .StrengthScore {
    width: 100%;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    position: relative;

    .StrengthBar {
      height: 100%;
      transition: width 0.3s ease-in-out;
      .StrengthLabel {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
        color: #fff;
      }

      &.strength-0 {
        background-color: #f44336;
        .StrengthLabel {
          color: #f44336;
        }
      }
      &.strength-1 {
        background-color: #ff9800;
        .StrengthLabel {
          color: #ff9800;
        }
      }
      &.strength-2 {
        background-color: #ffc107;
        .StrengthLabel {
          color: #4caf50;
        }
      }
      &.strength-3 {
        background-color: #4caf50;
      }
      &.strength-4 {
        background-color: $v2maingreen;
      }
    }
  }

  .TimeEstimate {
    display: flex;
    width: 100%;

    margin-top: 0.2rem;
    justify-content: space-between;
    color: $v2maingreen;
    font-size: 0.75rem;
  }
}

@import '../../../../../sass/variables.scss';
@import '../../../../../sass/mixins.scss';

.ForgotPass,
.Register {
  p,
  span {
    color: darkgrey;
  }
}

.ForgotPass {
  display: flex;
  padding: 0.2em;
  color: $white;
  margin-top: 0.5em;
  font-size: 0.8rem;
  justify-content: flex-end;
  cursor: pointer;

  .ForgotPasswordButton {
    border: 0 none;
    background-color: transparent;
    color: darkgrey;
    cursor: pointer;
  }
}

.Register {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  color: $white;
  font-size: 0.8rem;

  button,
  a {
    color: darken($nav-bg-light-blue, 8);
    margin-left: 0.25rem;
    cursor: pointer;
    background-color: transparent;
    border: 0 none;
  }

  a {
    text-decoration: none;
    margin-left: 0.4rem;
  }
}

@media (min-width: 800px) {
  .Register {
    margin-top: 2rem;
    color: $white;
    font-size: 0.8rem;
  }
}

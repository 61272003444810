@import '../../../../../../sass/manifest';

.MobileListItem {
  display: block;
  list-style: none;
  padding: 0.4rem;
  margin: 0.4rem 0;
  border: 1px solid $lightGray;
  border-radius: 10px;

  .ActionSection {
    display: flex;
    padding: 0.4rem 0;
    justify-content: flex-end;
    margin-top: 0.2rem;
    border-top: 1px solid $lightGray;
  }
}

@media only screen and (min-width: 900px) {
  .MobileListItem {
    display: none;
  }
}

@import '../../../sass/manifest';

.MessageSubject {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  color: $v2maingreen;

  h3 {
    font-size: 1.05rem;
    font-weight: 600;
  }

  span {
    font-size: 0.85rem;
    font-weight: 400;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .MessageSubject {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    color: $v2maingreen;

    h3 {
      font-size: 1.15rem;
      font-weight: 600;
    }

    span {
      font-size: 0.92rem;
      font-weight: 400;
    }
  }
}

@media only screen and (min-width: 768px) {
  .MessageSubject {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    color: $v2maingreen;

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
    }

    span {
      font-size: 0.95rem;
      font-weight: 400;
    }
  }
}

@media only screen and (min-width: 900px) {
  .MessageSubject {
    gap: 0.35rem;

    h3 {
      font-size: 1.35rem;
      font-weight: 600;
    }

    span {
      font-size: 0.98rem;
      font-weight: 400;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .MessageSubject {
    h3 {
      font-size: 1.55rem;
    }

    span {
      font-size: 1.05rem;
    }
  }
}

@import '../../../../../sass/manifest';

.VisitorDetailsEntryField {
  display: flex;
  flex-direction: column;
  gap: 1.05rem;
  padding: 1.2rem;

  .InputFields {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .LoadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25vh;
    height: 25dvh;
  }
}

.VisitorsFormActions {
  justify-content: flex-end;
  gap: 1rem;

  &.MainActions {
    margin-top: 0.8rem;
    padding-top: 0;
    border-top: 1px solid $periopsisGray;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .VisitorDetailsEntryField {
    display: flex;
    flex-direction: column;
    gap: 1.05rem;
    padding: 1.2rem;

    .InputFields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
    }

    .LoadingContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 25vh;
      height: 25dvh;
    }
  }

  .VisitorsFormActions {
    justify-content: flex-end;
    gap: 1rem;

    &.MainActions {
      margin-top: 0.8rem;
      padding-top: 0.8rem;
      border-top: 1px solid $periopsisGray;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../../sass/manifest';

.ObservedIncidentTypesContainer {
  display: flex;
  flex-direction: column;
  padding: 1.05rem;
  border-radius: 0.4rem;
  position: absolute;
  z-index: 60000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  height: 85vh;
  height: 85dvh;
  max-height: max-content;
  width: 94vw;
  width: 94dvw;

  .LoadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 85vh;
    height: 85dvh;
  }

  .HeadingSection {
    display: flex;
    padding: 0.3rem;
    margin-bottom: 1.05rem;
    border-bottom: 1px solid $lightGray;
    align-items: center;
    justify-content: space-between;
    color: $v2maingreen;
    font-size: 0.9rem;

    button {
      padding: 0.3rem;
      background-color: transparent;
      border: 0 none;
      cursor: pointer;
      svg {
        font-size: 0.92rem;
        color: $v2maingreen;
      }
    }
  }

  p {
    display: flex;
    width: 100%;
    padding: 0 0 0.3rem 0;
    color: $lightGray;
    font-size: 0.82rem;
  }

  .DynamicSearchSection {
    display: flex;
    width: 100%;
    margin: 0.3rem 0;
  }

  .ObservedIncidentTypesList {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 1rem 0rem;
    gap: 0.2rem;
    max-height: 35vh;
    max-height: 35dvh;
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll-Y;

    li {
      width: 100%;

      button {
        width: 100%;
        padding: 0.6rem 0.45rem;
        text-align: left;
        font-size: 0.78rem;
        color: $v2maingreen;
        font-weight: 500;
        border: 0 none;
        background-color: transparent;
        cursor: pointer;
        transition: all 0.25s linear;

        &:hover {
          background-color: $v2maingreen;
          color: #fff;
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .ObservedIncidentTypesContainer {
    padding: 1rem;
    height: 65vh;
    height: 65dvh;
    width: 75vw;
    width: 75dvw;

    .LoadingContainer {
      height: 65vh;
      height: 65dvh;
    }

    .HeadingSection {
      padding: 0.4rem;
      margin-bottom: 1rem;
      font-size: 0.92rem;

      button {
        padding: 0.3rem;

        svg {
          font-size: 1rem;
        }
      }
    }

    p {
      padding: 0 0 0.5rem 0;
      font-size: 0.88rem;
    }

    .ObservedIncidentTypesList {
      padding: 1rem 0.1rem;
      gap: 0.15rem;
      max-height: 25vh;
      max-height: 25dvh;

      li {
        width: 100%;

        button {
          padding: 0.5rem 0.4rem;
          font-size: 0.96rem;
          font-weight: 600;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .ObservedIncidentTypesContainer {
    padding: 1rem;
    height: 60vh;
    height: 60dvh;
    width: 60vw;
    width: 60dvw;

    .LoadingContainer {
      height: 60vh;
      height: 60dvh;
    }

    .HeadingSection {
      padding: 0.4rem;
      margin-bottom: 1rem;
      font-size: 0.98rem;

      button {
        padding: 0.3rem;

        svg {
          font-size: 1rem;
        }
      }
    }

    p {
      padding: 0 0 0.5rem 0;
      font-size: 0.92rem;
    }

    .ObservedIncidentTypesList {
      padding: 1rem 0.1rem;
      gap: 0.15rem;
      max-height: 28vh;
      max-height: 28dvh;

      li {
        width: 100%;

        button {
          padding: 0.5rem 0.4rem;
          font-size: 0.96rem;
          font-weight: 600;
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .ObservedIncidentTypesContainer {
    padding: 1.05rem;

    height: 50vh;
    height: 50dvh;

    width: 45vw;
    width: 45dvw;

    .LoadingContainer {
      width: 100%;
      height: 45vh;
      height: 45dvh;
    }

    .HeadingSection {
      padding: 0.6rem;
      margin-bottom: 1.05rem;
      font-size: 1rem;

      button {
        padding: 0.3rem;

        svg {
          font-size: 1.2rem;
        }
      }
    }

    p {
      padding: 0 0 0.6rem 0;
      font-size: 0.9rem;
    }

    .DynamicSearchSection {
      margin: 0.4rem 0;
    }

    .ObservedIncidentTypesList {
      padding: 1rem 0.3rem;
      gap: 0.2rem;
      max-height: 20vh;
      max-height: 20dvh;

      li {
        width: 100%;

        button {
          padding: 0.6rem 0.45rem;

          font-size: 1.05rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .ObservedIncidentTypesContainer {
    padding: 1.05rem;

    height: 45vh;
    height: 45dvh;

    width: 40vw;
    width: 40dvw;

    .LoadingContainer {
      width: 100%;
      height: 45vh;
      height: 45dvh;
    }

    .HeadingSection {
      font-size: 1.05rem;
    }

    p {
      padding: 0 0 0.6rem 0;
      font-size: 0.9rem;
    }

    .DynamicSearchSection {
      margin: 0.4rem 0;
    }

    .ObservedIncidentTypesList {
      padding: 1rem 0.3rem;
      gap: 0.2rem;
      max-height: 20vh;
      max-height: 20dvh;

      li {
        width: 100%;

        button {
          padding: 0.6rem 0.45rem;
          font-size: 1.05rem;
        }
      }
    }
  }
}

@import '../../../sass/variables.scss';

.PopUpContainer {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: $lightGray;
  width: max-content;
  min-width: 18.5rem;

  .ContentContainer {
    display: flex;
    flex-direction: column;
    padding: 0.45rem 0.95rem;
    .UpperContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.4rem;

      .InfoContainer {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        color: $v2maingreen;

        h2 {
          font-size: 1.15rem;
        }

        p {
          font-size: 0.95rem;
        }
      }

      .IconContainer {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;

        img {
          max-width: 100%;
        }
      }
    }

    .ImageContainer {
      display: flex;
      max-width: 100%;

      img {
        max-width: 100%;
        width: 100%;
        height: 10rem;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

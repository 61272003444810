@import '../../../../../../sass/manifest';

.ReportWrapper {
  display: flex;
  flex-direction: column;
  padding: 1.2rem 0;
  gap: 0.8rem;

  .ReportNavigator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;

    .NavigationButtons {
      display: flex;
      gap: 0.8rem;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.1rem;
        font-size: 1.1rem;
        cursor: pointer;
        border: 0 none;
        background-color: transparent;

        svg {
          color: $v2maingreen;
        }

        &:disabled {
          opacity: 0.5;

          svg {
            color: $lightGray;
          }
        }
      }
    }
  }
}

.SendActions {
  padding: 0.5rem;
}

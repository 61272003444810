@import '../../../../sass/variables.scss';
@import '../../../../sass/mixins.scss';

.Wrapper {
  display: flex;
  flex-direction: column;
  height: 70vh;
  height: 70dvh;
  justify-content: center;
}

.UsernameFormContainer {
  @include formModalContent;
}

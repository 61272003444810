@import '../../../../sass/variables.scss';
@import '../../../../sass/mixins.scss';

.DashboardContainer {
  @include displayFlex;
  flex-direction: column;
  position: relative;
  width: initial;
  margin: 0 auto;
  gap: 1rem;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;

  .FilterMainContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    width: 100dvw;
    max-width: 100vw;
    max-width: 100dvw;
    background-color: #f0f2f5;
    z-index: 1;
  }

  .ListWrapper {
    display: flex;
    overflow-y: hidden;
    overflow-x: hidden;

    .IncidentListContainer {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.5rem;
      width: max-content;
      margin: 0;
      padding: 0.5rem 0 1.5rem 0;
      justify-items: center;
    }
  }

  .CenterMap {
    justify-content: center;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .ListWrapper {
    .IncidentListContainer {
      grid-template-columns: repeat(2, 1fr);
      gap: 0.8rem;
      margin: 0 auto;
      padding: 0.3rem 0 1rem 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  .DashboardContainer {
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .DashboardContainer {
    width: 100%;

    .ListWrapper {
      .IncidentListContainer {
        grid-template-columns: repeat(3, 1fr);
        gap: 0.85rem;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .DashboardContainer {
    .ListWrapper {
      .IncidentListContainer {
        gap: 0.8rem;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .DashboardContainer {
    width: 100%;

    .FilterMainContainer {
      width: 93%;
    }

    .ListWrapper {
      overflow-y: hidden;
      overflow-x: hidden;
      //   @include scroll-Y;
      max-height: 65vh;
      max-height: 65dvh;

      .IncidentListContainer {
        gap: 0.85rem;
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .DashboardContainer {
    .ListWrapper {
      max-height: 67vh;
      max-height: 67dvh;
    }
  }
}

@media only screen and (min-width: 1360px) {
  .DashboardContainer {
    .ListWrapper {
      max-height: 66vh;
      max-height: 66dvh;

      .IncidentListContainer {
        grid-template-columns: repeat(4, 1fr);
        gap: 0.85rem;
      }
    }
  }
}

@media only screen and (min-width: 1420px) {
  .DashboardContainer {
    .ListWrapper {
      max-height: 70vh;
      max-height: 70dvh;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .DashboardContainer {
    width: 100%;

    .ListWrapper {
      max-height: 67vh;
      max-height: 67dvh;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .DashboardContainer {
    .ListWrapper {
      max-height: 69vh;
      max-height: 69dvh;
    }
  }
}

@media only screen and (min-width: 1680px) {
  .DashboardContainer {
    .ListWrapper {
      max-height: 73vh;
      max-height: 73dvh;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .DashboardContainer {
    width: 90%;

    .FilterMainContainer {
      width: 100%;
    }

    .ListWrapper {
      max-height: 74vh;
      max-height: 74dvh;

      .IncidentListContainer {
        grid-template-columns: repeat(4, 1fr);
        gap: 1.2rem;
      }
    }
  }
}

@import '../../../../../sass/manifest';

.ContractsList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &.Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.WarningMessageSection {
  display: flex;
  font-size: 0.95rem;
  margin: 0.4rem 0;
  color: darken($gray, 10%);
  line-height: 1.25;
}

@import '../../../../sass/manifest';

.Wrapper {
  display: flex;
  flex-direction: column;
  height: 75vh;
  height: 75dvh;
  padding: 2.5rem 0;

  justify-content: center;
}
.PasswordFormContainer {
  @include formModalContent;
  display: flex;
  flex-direction: column;
}

.PasswordConditionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  svg {
    color: #fff;
    cursor: pointer;
  }
}

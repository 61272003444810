@import '../../../../../../sass/manifest';

.ContractListItemContainer {
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0.7rem;
  width: 100%;
  background-color: #e3e3e3;
  opacity: 0.7;
  border-radius: 10px;
  border: 2px solid transparent;

  &.Selected {
    background-color: #fff;
    opacity: 1;
  }

  .HeadingSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: $v2maingreen;
    padding: 0.2rem 0 0.2rem 0;
    gap: 0.65rem;

    button {
      width: 100%;
      margin-bottom: 0.6rem;
    }

    .LeftSection {
      display: flex;
      gap: 0.6rem;
      align-items: center;

      h2 {
        font-size: 1.08rem;
      }

      p {
        font-size: 0.85rem;
      }
    }

    svg {
      font-size: 1.2rem;
    }
  }

  .Status {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    .StatusItem {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: $v2maingreen;
      font-size: 0.85rem;
      padding: 0.1rem 0 0.1rem 0;

      h5 {
        text-transform: uppercase;
        font-weight: 600;

        &::after {
          content: ':';
          padding: 0 0.2rem;
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .ContractListItemContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.05rem;
    width: 100%;
    background-color: #e3e3e3;
    opacity: 0.7;
    border-radius: 10px;
    border: 2px solid transparent;

    &.Selected {
      background-color: #fff;
      opacity: 1;
    }

    .HeadingSection {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: $v2maingreen;
      padding: 0.2rem 0 0.5rem 0;
      gap: unset;

      button {
        width: unset;
        margin-bottom: unset;
      }

      .LeftSection {
        display: flex;
        gap: 0.6rem;
        align-items: center;

        h2 {
          font-size: initial;
        }

        p {
          font-size: initial;
        }
      }

      svg {
        font-size: 2.1rem;
      }
    }

    .Status {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .StatusItem {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: $v2maingreen;
        font-size: unset;
        padding: 0.8rem 0 0.3rem 0;

        h5 {
          text-transform: uppercase;
          font-weight: 600;

          &::after {
            content: ':';
            padding: 0 0.2rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../sass/manifest';

.CurrentUsers {
  display: flex;
  flex-direction: column;
  padding: 0.8rem 0;
  .HeadingSection {
    display: flex;
    width: 100%;
    background-color: $v2maingreen;
    align-items: center;
    justify-content: center;
    position: relative;

    h3 {
      padding: 0.5rem 0;
      color: #fff;
      font-size: 1rem;
      text-transform: uppercase;
    }

    .RemainingUsers {
      display: none;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      background-color: $periopsisOrange;
      color: #fff;
      border-radius: 50%;
      font-size: 0.9rem;
      position: absolute;
      right: 5px;
    }
  }

  p {
    font-size: 0.875rem;
    line-height: 1.3;
    padding: 0.3rem 0;
  }

  .UserListContainer {
    display: flex;
    flex-direction: column;
    padding: 0.9rem 0;
    gap: 0.8rem;

    .CompleteUpdateContainer {
      display: flex;
      flex-direction: column;
      margin: 0.3rem 0;
      gap: 0.5rem;

      .InfoMessage {
        font-size: 0.875rem;
        color: $danger;
      }

      .TableAction {
        display: flex;
        margin-top: 0.3rem;
        justify-content: flex-end;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .CurrentUsers {
    padding: 0.5rem 0;
    .HeadingSection {
      h3 {
        font-size: 1rem;
      }

      .RemainingUsers {
        display: flex;
        width: 25px;
        height: 25px;
        font-size: 0.85rem;
      }
    }

    .UserListContainer {
      padding: 0.9rem 0.35rem;
      gap: 0.8rem;

      .CompleteUpdateContainer {
        margin: 0.5rem 0;

        .InfoMessage {
          font-size: 1rem;
        }

        .TableAction {
          margin-top: 0.8rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .CurrentUsers {
    padding: 0.8rem 0;
    .HeadingSection {
      h3 {
        padding: 0.5rem 0;
        font-size: 1.09rem;
      }

      .RemainingUsers {
        width: 28px;
        height: 28px;
      }
    }

    p {
      font-size: 1.05rem;
      padding: 0.3rem 0;
    }

    .UserListContainer {
      padding: 0.9rem 0.65rem;

      .CompleteUpdateContainer {
        .InfoMessage {
          font-size: 1.15rem;
        }
      }
    }
  }
}

.MainContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 99%;
  width: 99%;

  .Actions {
    display: flex;
    gap: 0.1rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .MainContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 95%;
    width: 95%;

    .Actions {
      display: flex;
      gap: 0.1rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../sass/manifest';

.ProgressWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
  min-height: 65dvh;
}

.CloseVisitorsForm {
  display: flex;
  padding: 0.4rem 0.3rem;
  justify-content: flex-end;

  svg {
    font-size: 1.25rem;
  }
}

.VisitorsListLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  min-height: 10rem;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../sass/variables.scss';

.MarkerImage {
  max-width: 65px;
  max-height: 65px;
  box-shadow: 2px 2px 4px black;
  border-radius: 50%;
  opacity: 0.85;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    transform: scale(1.8);
    opacity: 1;
  }
}

.PopUp {
  min-width: max-content;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    right: 5px;
    top: 5px;
    background-color: #fff;
    padding: 0.2rem;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-weight: 700;
    color: $v2maingreen;
    transition: all 0.3s linear;

    &:hover {
      background-color: $v2maingreen;
      color: #fff;
    }
  }

  &.DecreaseOpacity {
    opacity: 0.65;

    &:hover {
      opacity: 0.98;
    }
  }
}

@import '../../../../../../../sass/manifest';

.PackageCardContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: clamp(36%, 50%, 220px);
  background: rgb(0, 76, 108);
  background: $gradientBG;
  align-items: center;
  border-radius: 15px;
  color: #fff;
  width: max-content;
  min-width: 85%;
  min-height: 410px;
  justify-content: space-between;
  transition: all 0.24s linear;
  cursor: pointer;
  border: 1px solid rgba(172, 255, 47, 0.1);
  overflow: hidden;
  position: relative;
  opacity: 1;

  &.ThreePackView {
    position: relative;
    left: unset;
    transform: unset;

    &.OnLeftPosition {
      transform: unset;
    }

    &.KeepAtCenter {
      transform: unset;
    }

    &.SubservicesPosition {
      transform: unset;
    }

    &.Opac {
      opacity: 1;
    }

    &.Hidden {
      display: none;
      opacity: 0;
    }
  }

  &:hover {
    box-shadow: 0px 0px 0px rgb(48, 48, 48);
    border: 1px solid rgba(172, 255, 47, 0.5);
  }

  .PackageInfo {
    display: flex;
    flex-direction: column;
    width: 100%;

    .HeadingSection {
      padding: 0.4rem 0;
      text-align: center;
    }

    .PriceSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.1rem;
      h4 {
        font-size: 1.55rem;
      }
    }
  }

  .ServicesList {
    display: flex;
    flex-direction: column;
    gap: 0.22rem;
    list-style: none;
    font-size: 0.74rem;
    margin-block: 1rem;
    justify-content: flex-start;
    padding-right: 3px;

    max-height: 154px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
      box-shadow: inset 1px 1px 2px gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $v2maingreen;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
    }
  }

  .TermsAndConditions {
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;

    .BrochureLink {
      text-decoration: none;
    }

    button {
      background-color: transparent;

      border: 0 none;
    }

    .BrochureLink,
    button {
      font-size: 0.72rem;
      color: #fff;
      cursor: pointer;
    }
  }

  .Actions {
    display: flex;
    width: 100%;
    margin: 0.5rem 0;

    justify-content: center;
    .CardAction {
      font-size: 0.75rem;
      letter-spacing: 0;
      border-radius: 12px;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .PackageCardContainer {
    min-width: 88%;
    min-height: 410px;

    .ServicesList {
      gap: 0.23rem;
      font-size: 0.72rem;
    }

    .TermsAndConditions {
      gap: 0.35rem;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .PackageCardContainer {
    min-width: 90%;
    min-height: 418px;

    .ServicesList {
      gap: 0.23rem;
      font-size: 0.72rem;
    }

    .TermsAndConditions {
      gap: 0.35rem;
    }
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .PackageCardContainer {
    min-width: 50%;
    min-height: 410px;

    .ServicesList {
      font-size: 0.73rem;
      margin-block: 1rem;
    }
  }
}

@media (min-width: 768px) {
  .PackageCardContainer {
    min-width: 45%;
    min-height: 410px;

    .ServicesList {
      font-size: 0.73rem;
    }
  }
}

@media (min-width: 900px) {
  .PackageCardContainer {
    min-width: 34%;
    min-height: 400px;

    &.ThreePackView {
      position: absolute;
      left: 50%;
      transform: translate(10%, 0);

      &.OnLeftPosition {
        transform: translate(-110%, 0);
      }

      &.KeepAtCenter {
        transform: translate(-50%, 0);
      }

      &.SubservicesPosition {
        transform: translate(-150%, 0);
      }

      &.Opac {
        opacity: 0.4;
      }

      &.Hidden {
        display: none;
        opacity: 0;
      }
    }

    .ServicesList {
      font-size: 0.73rem;
      max-height: 154px;
    }

    .Actions {
      margin: 0.5rem 0;
      .CardAction {
        font-size: 0.75rem;
      }
    }
  }
}

@media (min-width: 1000px) {
  .PackageCardContainer {
    min-width: 31%;
    min-height: 400px;
    position: relative;

    .PackageInfo {
      .HeadingSection {
        padding: 0.4rem 0;
      }

      .PriceSection {
        margin-bottom: 0.05rem;
        h4 {
          font-size: 1.5rem;
        }
      }
    }

    .ServicesList {
      gap: 0.22rem;
      font-size: 0.73rem;
      margin-block: 1rem;
      padding-right: 3px;
      max-height: 154px;
    }

    .TermsAndConditions {
      gap: 0.35rem;
      margin-bottom: 0.5rem;
      .BrochureLink,
      button {
        font-size: 0.72rem;
      }
    }

    .Actions {
      margin: 0.5rem 0;
      .CardAction {
        font-size: 0.75rem;
      }
    }
  }
}

@media (min-width: 1200px) {
  .PackageCardContainer {
    padding: 1rem;
    min-width: 37%;
    min-height: 400px;

    .PackageInfo {
      .HeadingSection {
        padding: 0.4rem 0;
      }

      .PriceSection {
        margin-bottom: 0.05rem;
        h4 {
          font-size: 1.56rem;
        }
      }
    }

    .ServicesList {
      gap: 0.22rem;
      font-size: 0.72rem;
      padding-right: 3px;
      max-height: 154px;
    }

    .TermsAndConditions {
      gap: 0.35rem;
      margin-bottom: 0.5rem;
    }

    .Actions {
      margin: 0.5rem 0;
      .CardAction {
        font-size: 0.75rem;
      }
    }
  }
}

@media (min-width: 1300px) {
  .PackageCardContainer {
    padding: 1rem;
    min-width: 35%;
    min-height: 400px;

    .PackageInfo {
      .HeadingSection {
        padding: 0.4rem 0;
      }
      .PriceSection {
        h4 {
          font-size: 1.55rem;
        }
      }
    }

    .ServicesList {
      gap: 0.22rem;
      font-size: 0.73rem;
      padding-right: 3px;
      max-height: 154px;
    }

    .TermsAndConditions {
      gap: 0.35rem;
      width: 100%;
      margin-bottom: 0.5rem;
    }

    .Actions {
      margin: 0.5rem 0;
      .CardAction {
        font-size: 0.75rem;
      }
    }
  }
}

@media (min-width: 1536px) {
  .PackageCardContainer {
    padding: 1rem;
    min-width: 34%;
    min-height: 415px;
    .PackageInfo {
      .HeadingSection {
        padding: 0.4rem 0;
      }

      .PriceSection {
        margin-bottom: 0.1rem;
        h4 {
          font-size: 1.55rem;
        }
      }
    }

    .ServicesList {
      gap: 0.22rem;
      font-size: 0.73rem;
      margin-block: 1rem;
      max-height: 154px;
    }

    .TermsAndConditions {
      gap: 0.35rem;
      width: 100%;
      margin-bottom: 0.5rem;
    }

    .Actions {
      margin: 0.5rem 0;
      .CardAction {
        font-size: 0.75rem;
      }
    }
  }
}

@media (min-width: 1920px) {
  .PackageCardContainer {
    padding: 1rem;
    min-width: 33%;
    min-height: 420px;

    .PackageInfo {
      .HeadingSection {
        padding: 0.4rem 0;
      }

      .PriceSection {
        margin-bottom: 0.1rem;
        h4 {
          font-size: 1.55rem;
        }
      }
    }

    .ServicesList {
      gap: 0.22rem;
      font-size: 0.73rem;
      margin-block: 1rem;
      padding-right: 3px;
      max-height: 154px;
    }

    .TermsAndConditions {
      gap: 0.35rem;
      margin-bottom: 0.5rem;
    }

    .Actions {
      margin: 0.5rem 0;
      .CardAction {
        font-size: 0.75rem;
      }
    }
  }
}

.PricingItem {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  align-items: center;

  .CalculationPresentation {
    display: flex;
    gap: 0.1rem;
    font-size: 0.73rem;
  }
}

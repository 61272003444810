@import '../../../../sass/manifest';

.DetailsOfMessage {
  display: flex;
  padding: 2.8rem 3.5rem;
  justify-content: space-between;

  .ReportDetails {
    display: flex;
    flex-direction: column;
    width: max-content;
    gap: 0.3rem;
  }

  .MessageSection,
  .NoAdditionalMessage {
    padding-left: 2.8rem;
  }

  .MessageSection {
    display: flex;
    flex-direction: column;
    max-width: 60%;
    width: 50%;
    color: $v2maingreen;
    line-height: 1.35;
    gap: 0.5rem;

    .LocationOfIncident {
      display: flex;
      padding: 1rem 0 0.3rem 0;
      justify-content: flex-end;

      a {
        text-decoration: none;
        padding: 0.65rem 1.2rem;
        background-color: $v2maingreen;
        color: #fff;
        border-radius: 10px;
      }
    }
  }

  .NoAdditionalMessage {
    display: flex;
    max-width: 60%;
    width: 50%;
    align-items: center;
    justify-content: center;

    p {
      font-size: 1.05rem;
      color: $lightGray;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .DetailsOfMessage {
    display: flex;
    flex-direction: column;
    padding: 1.2rem 2rem;
    justify-content: space-between;
    gap: 0.8rem;

    .ReportDetails {
      display: flex;
      flex-direction: column;
      width: max-content;
      gap: 0.3rem;
      justify-content: center;
      width: 100%;
      border-bottom: 1px solid $lightGray;
    }

    .MessageSection,
    .NoAdditionalMessage {
      padding-left: 0;
      border-left: 0 none;
    }

    .MessageSection {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: 100%;
      color: $v2maingreen;
      line-height: 1.35;
      gap: 0.5rem;
      font-size: 0.85rem;

      .LocationOfIncident {
        display: flex;
        padding: 1rem 0 0.3rem 0;
        justify-content: flex-end;

        a {
          text-decoration: none;
          padding: 0.6rem 1rem;
          background-color: $v2maingreen;
          color: #fff;
          border-radius: 10px;
        }
      }
    }

    .NoAdditionalMessage {
      display: flex;
      max-width: 60%;
      width: 50%;
      align-items: center;
      justify-content: center;

      p {
        font-size: 1rem;
        color: $lightGray;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .DetailsOfMessage {
    padding: 1.1rem 1.8rem;

    .MessageSection {
      font-size: 0.92rem;
    }

    .NoAdditionalMessage {
      display: flex;
      max-width: 60%;
      width: 50%;
      align-items: center;
      justify-content: center;

      p {
        font-size: 1rem;
        color: $lightGray;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
  .DetailsOfMessage {
    display: flex;
    flex-direction: row;
    padding: 2.8rem 3.5rem;
    justify-content: space-between;

    .ReportDetails {
      display: flex;
      flex-direction: column;
      width: max-content;
      gap: 0.3rem;
      justify-content: unset;
      width: 49%;
      border-bottom: 0 none;
    }

    .MessageSection,
    .NoAdditionalMessage {
      padding-left: 2.8rem;
      border-left: 1px solid $lightGray;
    }

    .MessageSection {
      display: flex;
      flex-direction: column;
      max-width: 60%;
      width: 50%;
      color: $v2maingreen;
      line-height: 1.35;
      gap: 0.5rem;

      .LocationOfIncident {
        display: flex;
        padding: 1rem 0 0.3rem 0;
        justify-content: flex-end;

        a {
          text-decoration: none;
          padding: 0.65rem 1.2rem;
          background-color: $v2maingreen;
          color: #fff;
          border-radius: 10px;
        }
      }
    }

    .NoAdditionalMessage {
      display: flex;
      max-width: 60%;
      width: 50%;
      align-items: center;
      justify-content: center;

      p {
        font-size: 1.05rem;
        color: $lightGray;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .DetailsOfMessage {
    display: flex;
    padding: 2.8rem 3.5rem;
    justify-content: space-between;

    .ReportDetails {
      display: flex;
      flex-direction: column;
      width: max-content;
      gap: 0.3rem;
    }

    .MessageSection,
    .NoAdditionalMessage {
      padding-left: 2.8rem;
      border-left: 1px solid $lightGray;
    }

    .MessageSection {
      display: flex;
      flex-direction: column;
      max-width: 60%;
      width: 50%;
      color: $v2maingreen;
      line-height: 1.35;
      gap: 0.5rem;

      .LocationOfIncident {
        display: flex;
        padding: 1rem 0 0.3rem 0;
        justify-content: flex-end;

        a {
          text-decoration: none;
          padding: 0.65rem 1.2rem;
          background-color: $v2maingreen;
          color: #fff;
          border-radius: 10px;
        }
      }
    }

    .NoAdditionalMessage {
      display: flex;
      max-width: 60%;
      width: 50%;
      align-items: center;
      justify-content: center;

      p {
        font-size: 1.05rem;
        color: $lightGray;
      }
    }
  }
}

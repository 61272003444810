@import '../../../../../../../../../sass/manifest';

.CodeViewContainer {
  display: flex;
  width: 100%;
  padding: 0.9rem 0;

  align-items: center;
  justify-content: space-between;
  opacity: 0.3;

  &.Centered {
    justify-content: center;
  }

  .CodeItems {
    display: flex;
    gap: 0.55rem;
    flex: 1;
    justify-content: center;
    margin-left: 2.7rem;

    .CodeItem {
      font-size: 1.35rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 0.55rem;
      color: $v2maingreen;
    }

    .CodeDot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $lightGray;
    }
  }
}

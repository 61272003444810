@import '../../../../../../sass/manifest';

.TimeLineFullScreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background: rgba(1, 58, 83, 0.5);
  background: linear-gradient(
    126deg,
    rgba(1, 58, 83, 1) 0%,
    rgba(0, 63, 89, 0.55) 35%,
    rgba(0, 76, 108, 0.25) 100%
  );
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;

  .LogoContainer {
    position: absolute;
    max-width: 10rem;
    top: 15px;
    left: 15px;
  }

  .CloseButtonContainer {
    position: absolute;
    top: 15px;
    right: 15px;

    .CloseButton {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 0 none;
      background-color: $periopsisOrange;
      border-radius: 50%;
      svg {
        font-size: 2.5rem;
        color: #fff;
      }
    }
  }

  .ImageContainer {
    display: flex;
    width: 98vw;
    width: 98dvw;
    height: 84vh;
    height: 84dvh;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scroll-snap-type: x mandatory;

    margin-top: 20px;

    .ImageWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .ImageNumber {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $periopsisOrangeOpac;
        font-size: 0.9rem;
        color: #fff;
        border-radius: 50%;
        box-shadow: 3px 3px 10px black;
      }

      .DetectionDateImageMessage {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem 1.5rem;
        color: #fff;
        gap: 0.5rem;
        opacity: 0.55;

        .DateType {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 0.3rem;

          h2 {
            font-size: 1.25rem;
          }

          p {
            font-size: 0.9rem;
          }
        }

        .DetectedDate {
          padding: 0.5rem 1.2rem;
          border-radius: 10px;
          font-size: 1.05rem;
        }
      }
    }

    img {
      min-width: 98vw;
      min-width: 98dvw;
      width: 98vw;
      width: 98dvw;
      height: 84vh;
      height: 84dvh;
      object-fit: cover;
      object-position: center;
      scroll-snap-align: center;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .TimeLineFullScreen {
    .LogoContainer {
      position: absolute;
      max-width: 8rem;
      top: 15px;
      left: 15px;
    }

    .CloseButtonContainer {
      .CloseButton {
        svg {
          font-size: 2rem;
        }
      }
    }

    .ImageContainer {
      width: 68vw;
      width: 68dvw;
      height: 53vw;
      height: 53dvw;
      margin-top: unset;

      .ImageWrapper {
        .ImageNumber {
          width: 2rem;
          height: 2rem;
          display: flex;
          font-size: 0.85rem;
        }

        .DetectionDateImageMessage {
          padding: 0.3rem 1rem;
          gap: unset;

          .DateType {
            gap: 0.2rem;

            h2 {
              font-size: 1.05rem;
            }

            p {
              font-size: 0.8rem;
            }
          }

          .DetectedDate {
            padding: 0.3rem 0.8rem;

            font-size: 0.95rem;
          }
        }
      }

      img {
        min-width: 68vw;
        min-width: 68dvw;
        width: 68vw;
        width: 68dvw;
        height: 53vw;
        height: 53dvw;
        object-fit: contain;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .TimeLineFullScreen {
    .LogoContainer {
      max-width: 10rem;
    }

    .CloseButtonContainer {
      position: absolute;
      top: 15px;
      right: 15px;

      .CloseButton {
        svg {
          font-size: 2.2rem;
        }
      }
    }

    .ImageContainer {
      width: 55vw;
      width: 55dvw;
      height: 55vw;
      height: 55dvw;

      .ImageWrapper {
        .ImageNumber {
          top: 0px;
          right: 0px;
          width: 2.3rem;
          height: 2.3rem;
          font-size: 0.85rem;
          box-shadow: unset;
        }

        .DetectionDateImageMessage {
          position: absolute;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 0.5rem 1.5rem;
          color: #fff;

          .DateType {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.3rem;

            h2 {
              font-size: 1.15rem;
            }

            p {
              font-size: 0.85rem;
            }
          }

          .DetectedDate {
            padding: 0.2rem 0.8rem;
            border-radius: 10px;
            font-size: 0.95rem;
          }
        }
      }

      img {
        min-width: 55vw;
        width: 55vw;
        width: 55dvw;
        height: 55vw;
        height: 55dvw;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .TimeLineFullScreen {
    .ImageContainer {
      width: 52vw;
      width: 52dvw;
      height: 52vw;
      height: 52dvw;

      .ImageWrapper {
        .ImageNumber {
          top: 0px;
          right: 8px;
          width: 2.3rem;
          height: 2.3rem;
          font-size: 0.85rem;
          box-shadow: unset;
        }

        .DetectionDateImageMessage {
          position: absolute;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 0.5rem 1.5rem;
          color: #fff;

          .DateType {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.3rem;

            h2 {
              font-size: 1.15rem;
            }

            p {
              font-size: 0.85rem;
            }
          }

          .DetectedDate {
            padding: 0.2rem 0.8rem;
            border-radius: 10px;
            font-size: 0.95rem;
          }
        }
      }

      img {
        min-width: 52vw;
        width: 52vw;
        width: 52dvw;
        height: 52vw;
        height: 52dvw;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .TimeLineFullScreen {
    .ImageContainer {
      width: 49vw;
      width: 49dvw;
      height: 49vw;
      height: 49dvw;

      .ImageWrapper {
        .ImageNumber {
          top: 0px;
          right: 8px;
          width: 2.5rem;
          height: 2.5rem;
          font-size: 0.92rem;
          box-shadow: unset;
        }

        .DetectionDateImageMessage {
          padding: 0.2rem 1rem;

          .DateType {
            gap: 0.3rem;

            h2 {
              font-size: 1.25rem;
            }

            p {
              font-size: 0.92rem;
            }
          }

          .DetectedDate {
            font-size: 0.98rem;
          }
        }
      }

      img {
        min-width: 49vw;
        width: 49vw;
        width: 49dvw;
        height: 49vw;
        height: 49dvw;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .TimeLineFullScreen {
    .ImageContainer {
      width: 48.5vw;
      width: 48.5dvw;
      height: 48.5vw;
      height: 48.5dvw;

      .ImageWrapper {
        .ImageNumber {
          top: 0px;
          right: 8px;
          width: 2.5rem;
          height: 2.5rem;
          font-size: 0.92rem;
          box-shadow: unset;
        }

        .DetectionDateImageMessage {
          padding: 0.2rem 1rem;

          .DateType {
            gap: 0.3rem;

            h2 {
              font-size: 1.35rem;
            }

            p {
              font-size: 0.95rem;
            }
          }

          .DetectedDate {
            font-size: 1rem;
          }
        }
      }

      img {
        min-width: 48.5vw;
        width: 48.5vw;
        width: 48.5dvw;
        height: 48.5vw;
        height: 48.5dvw;
      }
    }
  }
}

@media only screen and (min-width: 2100px) {
  .TimeLineFullScreen {
    .ImageContainer {
      width: 40vw;
      width: 40dvw;
      height: 40vw;
      height: 40dvw;

      .ImageWrapper {
        .ImageNumber {
          top: 0px;
          right: 8px;
          width: 2.5rem;
          height: 2.5rem;
          font-size: 0.92rem;
          box-shadow: unset;
        }

        .DetectionDateImageMessage {
          padding: 0.2rem 1rem;

          .DateType {
            gap: 0.3rem;

            h2 {
              font-size: 1.35rem;
            }

            p {
              font-size: 0.95rem;
            }
          }

          .DetectedDate {
            font-size: 1rem;
          }
        }
      }

      img {
        min-width: 40vw;
        width: 40vw;
        width: 40dvw;
        height: 40vw;
        height: 40dvw;
      }
    }
  }
}

@import '../../../../../../../sass/manifest';
.LocationLinkSection {
  display: flex;
  width: 100%;
  padding: 0.3rem 0;
  justify-content: flex-end;

  a {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem;
    width: max-content;
    background-color: $v2maingreen;
    text-align: center;
    border-radius: 8px;
    font-size: 0.82rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .LocationLinkSection {
    a {
      padding: 0.6rem;
      font-size: 0.9rem;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .LocationLinkSection {
    a {
      padding: 0.8rem;
      font-size: 0.95rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

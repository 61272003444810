@import '../../../../sass/variables.scss';
@import '../../../../sass/mixins.scss';

.IncidentDashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  max-width: 100%;
  position: relative;
  margin: 0 auto;
  width: 100%;

  .LeftSide {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    transition: all 0.4s linear;
  }

  .RigthSide {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    width: 100%;
    width: 100%;

    .DetailsActions {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      margin-top: 1rem;

      .Details {
        display: flex;
        flex-direction: column;
        padding: 0.3rem 0.22rem;
        background-color: #fff;
        border-radius: 5px;
        width: 100%;

        &.Buttons {
          gap: 0.5rem;
          width: 100%;
        }

        button {
          margin: 0 0.3rem;
          padding-top: 0.615rem;
          padding-bottom: 0.615rem;
        }
      }

      .ActionsWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        width: 100%;

        .ButtonsWrapper {
          display: flex;
          flex-direction: row;
          gap: 0.6rem;
        }
      }
    }

    .RemovingIncident {
      display: flex;
      min-height: 20rem;
      align-items: center;
      justify-content: center;
      font-size: 1.15rem;
      font-weight: 600;
      animation: removing 0.6s linear infinite alternate;
    }
  }

  .TimelineUnderMap {
    display: none;
  }

  .TimelineUnderActions {
    display: flex;
    flex-direction: column;
    font-size: 0.85rem !important;
  }

  .HeadingContainer {
    display: flex;
    padding: 0.5rem 0.3rem;
    background-color: $v2maingreen;
    color: #fff;
    align-items: center;
    justify-content: space-between;
    min-height: 2rem;

    &.Centered {
      justify-content: center;
    }

    h1 {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }

    select {
      color: #fff !important;
      font-weight: 600;
      font-size: 1.09rem !important;
      border: 0 none !important;
      outline: 0 none !important;

      option {
        background-color: $v2maingreen;
      }
    }
  }
}

.IncidentsFetchProblem {
  display: flex;
  flex-direction: column;

  .ActionLinkContainer {
    padding: 0.4rem;
    max-width: max-content;
    align-self: center;

    .BackLink {
      padding: 0.4rem 0.7rem;
      color: #fff;
      text-decoration: none;
      font-weight: 500;
      background-color: $v2maingreen;
      border-radius: 5px;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .IncidentDashboardContainer {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    max-width: 100%;
    position: relative;
    margin: 0 auto;
    width: 100%;

    .LeftSide {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: 100%;
      transition: all 0.4s linear;
    }

    .RigthSide {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      width: 100%;
      width: 100%;

      .DetailsActions {
        display: flex;
        flex-direction: row;
        gap: 0.8rem;
        margin-top: 1rem;

        .Details {
          display: flex;
          flex-direction: column;
          padding: 0.5rem 0.25rem;
          background-color: #fff;
          border-radius: 5px;
          width: 40%;

          &.Buttons {
            gap: 0.5rem;
            width: 100%;
          }

          button {
            margin: 0 0.3rem;
            padding-top: 0.615rem;
            padding-bottom: 0.615rem;
          }
        }

        .ActionsWrapper {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          width: 60%;

          .ButtonsWrapper {
            display: flex;
            flex-direction: row;
            gap: 0.6rem;
          }
        }
      }

      .RemovingIncident {
        display: flex;
        min-height: 20rem;
        align-items: center;
        justify-content: center;
        font-size: 1.15rem;
        font-weight: 600;
        animation: removing 0.6s linear infinite alternate;
      }
    }

    .TimelineUnderMap {
      display: none;
    }

    .TimelineUnderActions {
      display: flex;
      flex-direction: column;
      font-size: 0.9rem !important;
    }

    .HeadingContainer {
      display: flex;
      padding: 0.5rem 0.3rem;
      background-color: $v2maingreen;
      color: #fff;
      align-items: center;
      justify-content: space-between;
      min-height: 2.5rem;

      &.Centered {
        justify-content: center;
      }

      h1 {
        font-size: 0.95rem;
      }

      p {
        font-size: 0.85rem;
      }

      select {
        color: #fff !important;
        font-weight: 600;
        font-size: 1.09rem !important;
        border: 0 none !important;
        outline: 0 none !important;

        option {
          background-color: $v2maingreen;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .IncidentDashboardContainer {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    max-width: 99%;
    position: relative;
    margin: 0 auto;
    width: 95%;

    .LeftSide {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: 100%;
      transition: all 0.4s linear;
    }

    .RigthSide {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      width: 100%;
      width: 100%;

      .DetailsActions {
        display: flex;
        flex-direction: row;
        gap: 0.8rem;
        margin-top: 1rem;

        .Details {
          display: flex;
          flex-direction: column;
          padding: 0.5rem 0.25rem;
          background-color: #fff;
          border-radius: 5px;
          width: 50%;

          &.Buttons {
            gap: 0.5rem;
          }

          button {
            margin: 0 0.3rem;
            padding-top: 0.615rem;
            padding-bottom: 0.615rem;
          }
        }

        .ActionsWrapper {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          width: 50%;

          .ButtonsWrapper {
            display: flex;
            flex-direction: row;
            gap: 0.6rem;
          }
        }
      }

      .RemovingIncident {
        display: flex;
        min-height: 20rem;
        align-items: center;
        justify-content: center;
        font-size: 1.15rem;
        font-weight: 600;
        animation: removing 0.6s linear infinite alternate;
      }
    }

    .TimelineUnderMap {
      display: none;
    }

    .TimelineUnderActions {
      display: flex;
      flex-direction: column;
      font-size: 0.95rem !important;
    }

    .HeadingContainer {
      display: flex;
      padding: 0.5rem 0.3rem;
      background-color: $v2maingreen;
      color: #fff;
      align-items: center;
      justify-content: space-between;
      min-height: 3rem;

      &.Centered {
        justify-content: center;
      }

      h1 {
        font-size: 1rem;
      }

      p {
        font-size: 0.9rem;
      }

      select {
        color: #fff !important;
        font-weight: 600;
        font-size: 1.09rem !important;
        border: 0 none !important;
        outline: 0 none !important;

        option {
          background-color: $v2maingreen;
        }
      }
    }
  }

  .IncidentsFetchProblem {
    .ActionLinkContainer {
      padding: 0.65rem;

      .BackLink {
        padding: 0.5rem 0.9rem;
        font-weight: 600;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .IncidentDashboardContainer {
    display: flex;
    flex-direction: row;
    gap: 0.6rem;
    max-width: 95%;
    width: 95%;
    position: relative;
    margin: 0 auto;

    .LeftSide {
      display: flex;
      flex-direction: column;
      max-width: 68vw;
      max-width: 68dvw;
      transition: all 0.4s linear;

      &.Shrink {
        max-width: 67.58vw;
        max-width: 67.58dvw;
      }
    }

    .TimelineUnderMap {
      display: flex;
      flex-direction: column;
      font-size: 0.95rem !important;
    }

    .TimelineUnderActions {
      display: none;
    }

    .RigthSide {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 25vw;
      max-width: 25dvw;
      width: 25vw;
      width: 25dvw;

      .DetailsActions {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        margin-top: 1rem;
        width: 100%;

        .Details {
          display: flex;
          flex-direction: column;
          padding: 0.5rem 0.25rem;
          background-color: #fff;
          border-radius: 5px;
          width: inherit;

          &.Buttons {
            gap: 0.5rem;
          }

          button {
            margin: 0 0.3rem;
            padding-top: 0.615rem;
            padding-bottom: 0.615rem;
          }
        }

        .ActionsWrapper {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          width: inherit;

          .ButtonsWrapper {
            display: flex;
            flex-direction: column;
            gap: 0.6rem;
          }
        }
      }

      .RemovingIncident {
        display: flex;
        min-height: 20rem;
        align-items: center;
        justify-content: center;
        font-size: 1.15rem;
        font-weight: 600;
        animation: removing 0.6s linear infinite alternate;
      }
    }

    .HeadingContainer {
      display: flex;
      padding: 0.5rem 0.3rem;
      background-color: $v2maingreen;
      color: #fff;
      align-items: center;
      justify-content: space-between;
      min-height: 3rem;

      &.Centered {
        justify-content: center;
      }

      h1 {
        font-size: 1rem;
      }

      p {
        font-size: 0.92rem;
      }

      select {
        color: #fff !important;
        font-weight: 600;
        font-size: 1.09rem !important;
        border: 0 none !important;
        outline: 0 none !important;

        option {
          background-color: $v2maingreen;
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
  .IncidentDashboardContainer {
    display: flex;
    flex-direction: row;
    gap: 0.6rem;
    max-width: 90%;
    position: relative;
    margin: 0 auto;

    .LeftSide {
      display: flex;
      flex-direction: column;
      max-width: 70vw;
      max-width: 70dvw;
      transition: all 0.4s linear;
    }

    .RigthSide {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 25vw;
      max-width: 25dvw;
      width: 25vw;
      width: 25dvw;

      .DetailsActions {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        margin-top: 1rem;

        .Details {
          display: flex;
          flex-direction: column;
          padding: 0.5rem 0.25rem;
          background-color: #fff;
          border-radius: 5px;

          &.Buttons {
            gap: 0.5rem;
          }

          button {
            margin: 0 0.3rem;
            padding-top: 0.615rem;
            padding-bottom: 0.615rem;
          }
        }

        .ActionsWrapper {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;

          .ButtonsWrapper {
            display: flex;
            flex-direction: column;
            gap: 0.6rem;
          }
        }
      }

      .RemovingIncident {
        display: flex;
        min-height: 20rem;
        align-items: center;
        justify-content: center;
        font-size: 1.15rem;
        font-weight: 600;
        animation: removing 0.6s linear infinite alternate;
      }
    }

    .TimelineUnderMap {
      display: flex;
      flex-direction: column;
      font-size: 1rem !important;
    }

    .TimelineUnderActions {
      display: none;
    }

    .HeadingContainer {
      display: flex;
      padding: 0.5rem 0.3rem;
      background-color: $v2maingreen;
      color: #fff;
      align-items: center;
      justify-content: space-between;
      min-height: 3.3rem;

      &.Centered {
        justify-content: center;
      }

      h1 {
        font-size: 1.05rem;
      }

      p {
        font-size: 0.92rem;
      }

      select {
        color: #fff !important;
        font-weight: 600;
        font-size: 1.09rem !important;
        border: 0 none !important;
        outline: 0 none !important;

        option {
          background-color: $v2maingreen;
        }
      }
    }
  }
}

@keyframes removing {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ReportWindowActions {
  display: flex;
  padding: 1rem 0;
  align-items: center;
  justify-content: space-between;

  .MainButtons {
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }

  .DashboardBtnContainer {
    display: flex;
  }

  .ReportWindowButton {
    min-width: 9.9rem;
    padding: 0.95rem 0.3rem;
    border-radius: 10px;
    font-size: 1rem;
  }
}

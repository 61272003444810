@import '../../../sass/manifest';

.RadioButton {
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $v2maingreen;
  position: relative;
  box-shadow: inset 1px 1px 3px $lightGray;
  min-width: 1.3rem;

  .Dot {
    width: 0.65rem;
    height: 0.65rem;
    background-color: $v2maingreen;

    position: absolute;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }

  &.Selected {
    .Dot {
      display: flex;
    }
  }
}

@import '../../../sass/variables.scss';

.ParagraphConatiner {
  display: flex;
  padding: 0.5rem 0;
  font-size: 0.9rem;
  color: $lightGray;

  .Paragraph {
    width: 11rem;
    max-width: 11rem;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

    &.NoEllipsis {
      width: 100%;
      max-width: 100%;
      white-space: initial;
      overflow: initial;
      text-overflow: initial;
    }
  }

  span {
    font-size: 0.7rem;
    font-style: italic;
    color: lighten($lightGray, 20%);
  }
}

@media (min-width: 800px) {
  .ParagraphConatiner {
    display: flex;
    padding: 0.5rem 0;
    width: 100%;
    font-size: 1.08rem;
    color: $lightGray;
    overflow: hidden;
    max-width: 100%;
    line-height: 1.25;

    .Paragraph {
      width: 100%;
      max-width: 100%;
      white-space: initial;
      overflow: initial;
      text-overflow: initial;
    }

    span {
      font-size: 0.7rem;
      font-style: italic;
      color: lighten($lightGray, 20%);
    }
  }
}

@import '../../../sass/manifest';

.ServiceRegistrationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  min-height: 80dvh;
  width: 80%;
  margin: auto;

  .UnderConstructionContainer {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2.5rem;
    align-items: center;
    justify-content: center;
    gap: 0.9rem;
    background-color: $periopsisOrange;
    border: 4px solid $v2maingreen;

    transform: rotate(-5deg);
    box-shadow: 3px 3px 15px rgb(108, 108, 108);
    border-radius: 15px;
    color: #fff;

    h3 {
      font-size: 2.8rem;
    }

    p {
      font-size: 2rem;
    }

    svg {
      font-size: 6rem;
    }
  }
}

@import '../../../../../sass/manifest';

.CardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0.65rem;
  background-color: #fff;
  border-radius: 10px;

  text-decoration: none;
  color: $v2maingreen;

  .CircleImage {
    width: 2.5rem;
    height: 2.5rem;
  }

  .Content {
    display: flex;
    align-items: center;
    padding-bottom: 0.2rem;
    margin-bottom: 0.2rem;
    justify-content: space-between;

    .ContentLeft {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }

    h3 {
      font-size: 0.95rem;
    }

    data {
      font-size: 0.78rem;
    }

    svg {
      font-size: 2rem;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .CardContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem 0.9rem;
    background-color: #fff;
    border-radius: 10px;

    text-decoration: none;
    color: $v2maingreen;

    .CircleImage {
      width: 2.5rem;
      height: 2.5rem;
    }

    .Content {
      display: flex;
      align-items: center;
      padding-bottom: 0.3rem;
      margin-bottom: 0.2rem;
      justify-content: space-between;

      .ContentLeft {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }

      h3 {
        font-size: 0.95rem;
      }

      data {
        font-size: 0.85rem;
      }

      svg {
        font-size: 2rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .CardContainer {
    padding: 0.8rem 1rem;

    .CircleImage {
      width: 3rem;
      height: 3rem;
    }

    .Content {
      padding-bottom: 0.3rem;
      margin-bottom: 0.22rem;

      .ContentLeft {
        gap: 0.5rem;
      }

      h3 {
        font-size: 1.15rem;
      }

      data {
        font-size: 0.88rem;
      }

      svg {
        font-size: 2.8rem;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .CardContainer {
    padding: 0.7rem 1.1rem;

    .CircleImage {
      width: 3.3rem;
      height: 3.3rem;
    }

    .Content {
      padding-bottom: 0.35rem;
      margin-bottom: 0.25rem;

      .ContentLeft {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }

      h3 {
        font-size: 1.15rem;
      }

      data {
        font-size: 0.85rem;
      }

      svg {
        font-size: 3.2rem;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .CardContainer {
    padding: 0.9rem 1.2rem;

    .CircleImage {
      width: 3.8rem;
      height: 3.8rem;
    }

    .Content {
      padding-bottom: 0.4rem;
      margin-bottom: 0.3rem;

      .ContentLeft {
        gap: 0.5rem;
      }

      h3 {
        font-size: 1.23rem;
      }

      data {
        font-size: 0.9rem;
      }

      svg {
        font-size: 3.5rem;
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .CardContainer {
    padding: 0.9rem 1.2rem;
    max-width: 90%;
    margin: 0 auto;

    .CircleImage {
      width: 4rem;
      height: 4rem;
    }

    .Content {
      padding-bottom: 0.4rem;
      margin-bottom: 0.3rem;

      .ContentLeft {
        gap: 0.5rem;
      }

      h3 {
        font-size: 1.28rem;
      }

      data {
        font-size: 0.92rem;
      }

      svg {
        font-size: 4rem;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .CardContainer {
    max-width: 85%;
  }
}

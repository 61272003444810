.StatisticsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.65rem;

  .ChartSection {
    display: flex;
    flex: 1;
    height: 67vh;
    height: 67dvh;
    align-items: center;
    justify-content: center;

    canvas {
      width: 20rem !important;
      max-width: 36rem;
      max-height: 36rem;
      cursor: pointer;
    }
  }

  .DataTableSection {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    margin-top: 1rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .StatisticsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.1rem;
    gap: 1rem;

    .ChartSection {
      display: flex;
      flex: 1;
      height: 67vh;
      height: 67dvh;
      align-items: center;
      justify-content: center;

      canvas {
        width: 31rem !important;
        max-width: 40rem;
        max-height: 40rem;
        cursor: pointer;
      }
    }

    .DataTableSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      width: 100%;
      margin-top: 0.8rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .StatisticsWrapper {
    .ChartSection {
      canvas {
        width: 32rem !important;
        max-width: 40rem;
        max-height: 40rem;
      }
    }

    .DataTableSection {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .StatisticsWrapper {
    flex-direction: row;
    gap: unset;

    .ChartSection {
      display: flex;
      flex: 1;
      height: 63vh;
      height: 63dvh;
      align-items: center;
      justify-content: center;

      canvas {
        width: 26rem !important;
        height: 26rem !important;
        max-width: 36rem;
        max-height: 36rem;
        cursor: pointer;
      }
    }

    .DataTableSection {
      display: flex;
      flex-direction: column;
      align-items: unset;
      flex: 1;
      width: unset;
      margin-top: 0;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .StatisticsWrapper {
    .ChartSection {
      canvas {
        width: 28rem !important;
        height: 28rem !important;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .StatisticsWrapper {
    .ChartSection {
      canvas {
        width: 32rem !important;
        height: 32rem !important;
      }
    }
  }
}

@import '../../../../../../sass/manifest';

.QuestionItemContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: $v2maingreen;
  padding: 0.8rem 0;
  margin-bottom: 0.4rem;
  border-bottom: 1px solid $periopsisOrange;

  .QuestionSection {
    display: flex;
    gap: 0.45rem;
    align-items: center;

    p {
      &::first-letter {
        font-size: 1.25rem;
        margin-right: -0.75rem;
        font-weight: 700;
        letter-spacing: 0.75rem;
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.65rem;
      height: 1.65rem;
      background-color: $periopsisOrange;
      color: #fff;
      font-size: 0.88rem;
      border-radius: 50%;
    }
  }

  .AnswersContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0 0.5rem 2.2rem;
    gap: 0.85rem;
  }
}

.Approved {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  padding: 0.2rem 0.4rem;

  font-weight: 600;

  svg {
    font-size: 1.6rem;
    color: yellowgreen;
  }
}

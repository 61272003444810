@import '../../../../../../sass/manifest';

.FetchingEmailReports {
  display: flex;
  flex-direction: column;
  min-height: 20vh;
  min-height: 20dvh;
  align-items: center;
  justify-content: center;
  gap: 0.9rem;
  p {
    font-size: 0.95rem;
    font-weight: 600;
    color: $v2maingreen;
    animation: fetching 0.6s linear infinite alternate;
    text-align: center;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .FetchingEmailReports {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    min-height: 60dvh;
    align-items: center;
    justify-content: center;
    gap: 0.9rem;
    p {
      font-size: 1.15rem;
      font-weight: 600;
      color: $v2maingreen;
      animation: fetching 0.6s linear infinite alternate;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@keyframes fetching {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

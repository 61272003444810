@import '../../../../sass/manifest';

.NotificationDetailsMobile {
  display: flex;
  flex-direction: column;

  .DetailsContent {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    color: $v2maingreen;
    margin-top: 0.3rem;
    padding: 0.45rem 0.65rem;

    h1 {
      font-size: 1rem;
      text-transform: uppercase;
    }

    p {
      line-height: 1.3;
      font-size: 0.73rem;
    }

    .IncidentCardsContainar {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      margin: 0.3rem 0;
    }
  }
}

@media only screen and (min-width: 600px) {
  .NotificationDetailsMobile {
    display: none;
  }
}

@import '../../../../../../../../sass/manifest';

.Wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;

  p {
    margin: 0.6rem 0 0 0;
    color: #fff;
    background-color: $v2maingreen;
    padding: 0.4rem 0;
    font-size: 0.8rem;
  }

  .LettersContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0.5rem 0;
    margin-top: 0.4rem;

    button {
      flex: unset;
      padding: 0.4rem 0.3rem;
      border: 0 none;
      border-right: 1px solid $lightGray;
      background-color: lighten($lightGray, 8%);
      color: #fff;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.25s linear;
      min-width: 12.5%;

      &:last-child {
        border-right: 0 none;
      }

      &.Disabled {
        opacity: 0.6;

        &:hover {
          transform: scale(1);
          background-color: lighten($lightGray, 8%);
          color: #fff;
          border-radius: 0;
          border-right: 0 none;
        }

        &.Selected {
          background-color: $v2maingreen;

          &:hover {
            transform: scale(1);
            color: #fff;
            border-radius: 0;
          }
        }
      }

      &:hover {
        background-color: lighten($lightGray, 15%);
        transform: scale(1.22);
        border-right: 0 none;
        color: $v2maingreen;
        border-radius: 6px;
      }

      &.Selected {
        background-color: $v2maingreen;

        &:hover {
          transform: scale(1);
          color: #fff;
          border-radius: 0;
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .Wrapper {
    p {
      font-size: 0.92rem;
    }

    .LettersContainer {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      padding: 0.5rem 0;
      margin-top: 0.4rem;

      button {
        flex: 1;
        padding: 0.4rem 0;
        border: 0 none;
        border-right: 1px solid $lightGray;
        background-color: lighten($lightGray, 8%);
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.25s linear;
        min-width: unset;

        &:last-child {
          border-right: 0 none;
        }

        &.Disabled {
          opacity: 0.6;

          &:hover {
            transform: scale(1);
            background-color: lighten($lightGray, 8%);
            color: #fff;
            border-radius: 0;
            border-right: 0 none;
          }

          &.Selected {
            background-color: $v2maingreen;

            &:hover {
              transform: scale(1);
              color: #fff;
              border-radius: 0;
            }
          }
        }

        &:hover {
          background-color: lighten($lightGray, 15%);
          transform: scale(1.22);
          border-right: 0 none;
          color: $v2maingreen;
          border-radius: 6px;
        }

        &.Selected {
          background-color: $v2maingreen;

          &:hover {
            transform: scale(1);
            color: #fff;
            border-radius: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .Wrapper {
    p {
      font-size: 1.08rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

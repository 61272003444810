@import '../../../sass/manifest';

.EmailEntryField {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 0.2rem 0.32rem;
  border-bottom: 1px solid $lightGray;
  max-width: 100vw;
  max-width: 100dvw;
  position: relative;

  &.Invalid {
    @include formElementInvalid;

    input[type='email'] {
      color: #fff;
    }
  }

  .InfoMessage {
    position: absolute;
    bottom: 100%;
    font-size: 0.75rem;
    padding: 0.5rem 0.85rem;
    background-color: $v2maingreen;
    color: #fff;
    box-shadow: 1px 1px 5px darkgray;
    border-radius: 5px;
    display: none;
    opacity: 0.6;
  }

  &:focus-within {
    border-bottom: 1px solid $v2maingreen;

    .InfoMessage {
      display: block;
    }

    span {
      color: $v2maingreen;
    }
  }

  span {
    font-size: 0.78rem;
    color: $lightGray;
    margin-right: 0.4rem;
    font-weight: 500;
  }

  ul {
    list-style: none;
    display: flex;
  }

  input[type='email'] {
    border: 0 none;
    outline: 0 none;
    background-color: transparent;
    padding: 0.42rem 0.2rem;
    color: $lightGray;
  }
}

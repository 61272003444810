@import '../../../../../../../../sass/manifest';

.AreaOfInterestSection {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0.6rem 0;
  margin: 0.5rem auto;
  width: 100%;

  .SelectedPolygonsButtonSection {
    display: flex;
    width: 100%;
    padding: 0.2rem 0;
    justify-content: space-between;

    button,
    a {
      display: flex;
      align-items: center;
      padding: 0;
      font-size: 0.7rem;
      background-color: transparent;
      border: 0 none;
      color: $v2maingreen;
      cursor: pointer;
    }

    a {
      text-decoration: none;
    }
  }

  .AreaSelectionSummary {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    padding: 0.5rem 0;
    margin-top: 0.3rem;
    border-bottom: 1px solid $v2maingreen;
    border-top: 1px solid $v2maingreen;
  }

  h3 {
    font-size: 1.05rem;
    color: $lightGray;
  }

  .Districts {
    display: flex;
    flex-direction: column;
    margin: 0.65rem 0;

    h4 {
      font-size: 1.15rem;
      color: $v2maingreen;
      text-align: center;
    }
  }

  .AreaOfInterestOptions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0;
    row-gap: 0.6rem;
    column-gap: 0.3rem;
    width: 100%;

    &.Centered {
      align-items: center;
      justify-content: center;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .AreaOfInterestSection {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 0.6rem 0.5rem;
    margin: 0.5rem auto;
    width: 100%;

    .SelectedPolygonsButtonSection {
      display: flex;
      width: 100%;
      padding: 0.2rem 0;
      justify-content: space-between;

      button,
      a {
        display: flex;
        align-items: center;
        padding: 0 0.3rem;
        font-size: 0.82rem;
        background-color: transparent;
        border: 0 none;
        color: $v2maingreen;
        cursor: pointer;
      }

      a {
        text-decoration: none;
      }
    }

    .AreaSelectionSummary {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      padding: 0.5rem 0;
      margin-top: 0.3rem;
      border-bottom: 1px solid $v2maingreen;
      border-top: 1px solid $v2maingreen;
    }

    h3 {
      font-size: 1.05rem;
      color: $lightGray;
    }

    .Districts {
      display: flex;
      flex-direction: column;
      margin: 0.65rem 0;

      h4 {
        font-size: 1.15rem;
        color: $v2maingreen;
        text-align: center;
      }
    }

    .AreaOfInterestOptions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 1rem;
      // gap: 0.6rem;
      row-gap: 0.75rem;
      column-gap: 0.45rem;
      justify-content: initial;
      width: 100%;

      &.Centered {
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../sass/manifest';

.CurrentUsersWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  color: $v2maingreen;

  p {
    font-size: 1.05rem;
    line-height: 1.3;
  }
}

.ManageUsersWrapper {
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.85rem;

  .ContractSummary {
    padding: 0.5rem 0.75rem;
  }
}

.UserManagementAction {
  justify-content: flex-end;

  button {
    min-width: 20%;
    padding: 0.8rem;
  }
}

.ContractDetails {
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.2rem;
    color: $v2maingreen;
    text-transform: uppercase;
    padding: 0.3rem 0;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid $periopsisOrange;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .CurrentUsersWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    color: $v2maingreen;

    p {
      font-size: 0.95rem;
      line-height: 1.3;
    }
  }

  .ManageUsersWrapper {
    display: flex;
    flex-direction: column;
    padding: 0.2rem 0.65rem;

    .ContractSummary {
      padding: 0.5rem 0.75rem;
    }
  }

  .UserManagementAction {
    justify-content: flex-end;

    button {
      min-width: 20%;
      padding: 0.8rem;
    }
  }

  .ContractDetails {
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 1.05rem;
      color: $v2maingreen;
      text-transform: uppercase;
      padding: 0.3rem 0;
      margin-bottom: 0.3rem;
      border-bottom: 1px solid $periopsisOrange;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .CurrentUsersWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    color: $v2maingreen;

    p {
      font-size: 1.05rem;
      line-height: 1.3;
    }
  }

  .ManageUsersWrapper {
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0.85rem;

    .ContractSummary {
      padding: 0.5rem 0.75rem;
    }
  }

  .UserManagementAction {
    justify-content: flex-end;

    button {
      min-width: 20%;
      padding: 0.8rem;
    }
  }

  .ContractDetails {
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 1.2rem;
      color: $v2maingreen;
      text-transform: uppercase;
      padding: 0.3rem 0;
      margin-bottom: 0.3rem;
      border-bottom: 1px solid $periopsisOrange;
    }
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../sass/manifest';

.TextAreaComponentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  margin-top: 0.4rem;

  .RemainingCharsContainer {
    display: flex;
    padding: 0.4rem 0.35rem;
    font-size: 0.8rem;
    align-items: center;
    gap: 0.5rem;
  }

  .RemainingCharsContainer p {
    padding: 0;
    margin: 0;
  }

  .TextAreaContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid $lightGray;

    color: transparent;
    text-align: center;
    position: relative;
    // overflow: hidden;

    label {
      position: absolute;
      color: darken($gray, 5);
      bottom: 0.3rem;
      transition: all 0.8s ease-in-out;
      z-index: 100;
      font-size: 0.9rem;
    }

    &:focus-within {
      label {
        bottom: 100%;
        font-size: 11px;
      }
    }

    .TextArea {
      resize: none;
      border: 0 none;
      outline: 0 none;
      background-color: transparent;
      padding: 0.2rem 0;
      font-family: Arial, Helvetica, sans-serif;
      color: darken($gray, 5);
      max-width: 90%;

      &::placeholder {
        color: transparent;
      }
    }

    &.Invalid {
      border: 1px solid $danger;
      background-color: #e76b55e4;

      .TextArea {
        background-color: #e76b55e4;
        color: #fff;
      }
    }
  }

  .ErrorMessage {
    font-size: 0.82rem;
    padding: 0 0 0.2rem 0.35rem;
    color: $danger;
  }
}

@import '../../../../sass/variables.scss';

.Chips {
  display: flex;
  align-items: center;

  font-size: 0.75rem;
  padding: 0.22rem;
  background-color: lighten($v2maingreen, 5%);
  border-radius: 2rem;
  color: #fff;
  margin: 0.1rem;

  svg {
    font-size: 0.9rem;
    margin-left: 0.17rem;
    cursor: pointer;
    color: $danger;
  }
}

@media (min-width: 800px) {
  .Chips {
    display: flex;
    align-items: center;

    font-size: 0.83rem;
    padding: 0.3rem;
    background-color: lighten($v2maingreen, 5%);
    border-radius: 2rem;
    color: #fff;
    margin: 0.1rem;

    svg {
      font-size: 1.07rem;
      margin-left: 0.3rem;
      cursor: pointer;
      color: $danger;
    }
  }
}

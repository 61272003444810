@import '../../../../../../../../sass/manifest';
.PaymentTypeOption {
  display: flex;
  width: 50%;
  align-items: center;
  gap: 0.5rem;
  padding: 0.6rem 0.45rem;
  background-color: transparent;
  border: 1px solid $v2maingreen;
  color: $v2maingreen;
  font-weight: 600;
  cursor: pointer;

  &.Selected {
    box-shadow: 0 0 5px $v2maingreen;
  }
}

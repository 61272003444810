.MapContentSection {
  position: relative;
  margin-top: 0rem;
  max-height: max-content;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .MapContainer {
    height: 680px;
    z-index: 999;
    display: flex;
  }

  .MapStyleLayers {
    position: absolute;
    z-index: 50;
    right: 10px;
    top: 180px;
  }

  .SideBar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 4px;
    z-index: 8;
    font-size: 0.7rem;
    display: none;
  }
}

@media (min-width: 800px) {
  .MapContentSection {
    .SideBar {
      font-size: 0.95rem;
    }
  }
}

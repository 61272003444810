@import '../../../../../sass/manifest';

.ResendButtonContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  align-items: flex-end;

  button {
    margin-top: 0.45rem;
    border: 0 none;
    background: transparent;
    cursor: pointer;
    color: $v2maingreen;
    font-size: 0.95rem;

    &:disabled {
      color: gray;
    }
  }
}

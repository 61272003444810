@import '../../../../sass/manifest';

.HeadingContainer {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: $v2maingreen;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0 none;
    border-radius: 50%;
    cursor: pointer;

    &:focus {
      outline: 1px solid $v2maingreen;
    }
  }
}

.NotificationsList {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .NotificationsList {
    display: flex;
    flex-direction: column;
    gap: 0.92rem;
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
  .NotificationsList {
    display: flex;
    flex-direction: column;
    gap: 0.92rem;
  }
}

@media only screen and (min-width: 1920px) {
  .NotificationsList {
    display: flex;
    flex-direction: column;
    gap: 0.92rem;
  }
}

.row {
  display: flex;
  font-size: 0.92rem;
  align-items: center;
  height: 100%;

  &.centered {
    justify-content: center;
  }
}

.ShowBtn {
  padding: 0.35rem 0.65rem;
  font-size: 0.92rem;
  text-transform: capitalize;
}

@import '../../../../../sass/manifest';

.DataTableSection {
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  margin-top: 0.5rem;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .DataTableSection {
    padding: 0 1rem;
    margin-top: 1.5rem;
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .DataTableSection {
    padding: 0 2rem;
    margin-top: 1.5rem;
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../../sass/manifest';

.CardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  position: relative;
  min-height: 428px;
  margin-top: 0.85rem;

  .NavButton {
    position: absolute;
    z-index: 5;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $v2maingreen;
    border: 0 none;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    display: none;

    &.Prev {
      left: 0;
    }

    &.Next {
      right: 0;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}
@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media (min-width: 768px) {
}

@media (min-width: 900px) {
  .CardContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    width: 100%;
    position: relative;
    min-height: 428px;
    margin-top: 0.85rem;

    .NavButton {
      position: absolute;
      z-index: 5;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $v2maingreen;
      border: 0 none;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
      display: block;

      &.Prev {
        left: 0;
      }

      &.Next {
        right: 0;
      }
    }
  }

  .MobileCardWrapper {
    display: none;
    max-width: 85%;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 0 auto;
    .MobileCardList {
      display: flex;
      width: 100%;
      min-width: 100%;
      gap: 0.3rem;
    }
  }
}

@media (min-width: 1000px) {
}

@media (min-width: 1200px) {
  .CardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1.2rem;
    width: 100%;
    position: relative;
    min-height: 428px;
    margin-top: 0.85rem;

    .NavButton {
      position: absolute;
      z-index: 5;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $v2maingreen;
      border: 0 none;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;

      &.Prev {
        left: 0;
      }

      &.Next {
        right: 0;
      }
    }
  }

  .MobileCardWrapper {
    display: none;
    max-width: 85%;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 0 auto;
    .MobileCardList {
      display: flex;
      width: 100%;
      min-width: 100%;
      gap: 0.3rem;
    }
  }
}

@media (min-width: 1536px) {
}

@media (min-width: 1920px) {
}

.StatisticsWrapper {
  display: flex;
  width: 100%;
  padding: 0rem 0.1rem;

  .ChartSection {
    display: flex;
    flex: 2;
    height: 67vh;
    height: 67dvh;
    align-items: center;
    justify-content: center;
    canvas {
      width: 19rem !important;
      height: 22rem !important;
      max-width: 85rem;
      max-height: 45rem;
      cursor: pointer;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .StatisticsWrapper {
    display: flex;
    width: 100%;
    padding: 1rem 1.1rem;

    .ChartSection {
      display: flex;
      flex: 2;
      height: 67vh;
      height: 67dvh;
      align-items: center;
      justify-content: center;
      canvas {
        width: 28rem !important;
        height: 17rem !important;
        max-width: 32rem;
        max-height: 20rem;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .StatisticsWrapper {
    .ChartSection {
      height: 56vh;
      height: 56dvh;
      canvas {
        width: 38rem !important;
        height: 20rem !important;
        max-width: 36rem;
        max-height: 22rem;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .StatisticsWrapper {
    .ChartSection {
      height: 55vh;
      height: 55dvh;
      canvas {
        width: 44rem !important;
        height: 21rem !important;
        max-width: 45rem;
        max-height: 30rem;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .StatisticsWrapper {
    .ChartSection {
      height: 55vh;
      height: 55dvh;
      canvas {
        width: 50rem !important;
        height: 25rem !important;
        max-width: 50rem;
        max-height: 30rem;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .StatisticsWrapper {
    .ChartSection {
      height: 55vh;
      height: 55dvh;
      canvas {
        width: 58rem !important;
        height: 27rem !important;
        max-width: 58rem;
        max-height: 30rem;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .StatisticsWrapper {
    display: flex;
    width: 100%;
    padding: 1rem 1.1rem;

    .ChartSection {
      height: 67vh;
      height: 67dvh;

      canvas {
        width: 90rem !important;
        height: 43rem !important;
        max-width: 90rem;
        max-height: 45rem;
        cursor: pointer;
      }
    }
  }
}

@import '../../../sass/variables.scss';
@import '../../../sass/mixins.scss';

.Logo {
  @include logo();
  @include FlexCenter;
  font-size: 1.2rem;
  text-shadow: $box-shadow;
  transition: all 0.45s ease-in-out;
  text-decoration: none;
  animation: bounce 0.6s ease-in-out 1 forwards alternate;

  img {
    width: 100%;
  }

  .imageResponsive {
    @include image-responsive;
  }
}

@keyframes bounce {
  0% {
    transform: scale(0.9);
  }

  25% {
    transform: scale(1.07);
  }

  50% {
    transform: scale(0.9);
  }

  75% {
    transform: scale(1.07);
  }

  100% {
    transform: scale(1);
  }
}

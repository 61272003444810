@import '../../../../../../../../sass/manifest';
.Wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  .SubserviceOptionContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 1.8rem;
    color: $v2maingreen;
    font-size: 0.78rem;

    .SubserviceInfoContainer {
      display: flex;
      gap: 0.3rem;
      width: max-content;

      button {
        width: 1.2rem;
        height: 1.2rem;
        background-color: transparent;
        border: 0 none;
        color: darken($v2maingreen, 10%);
        font-size: 1.01rem;
        cursor: pointer;
      }
    }
  }

  .ServiceDescription {
    display: flex;
    flex-direction: column;
    max-width: 65%;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    height: 0;
    overflow: hidden;
    transition: all 0.24s linear;
    padding: 0;
    color: $v2maingreen;

    p {
      font-size: 0rem;
      line-height: 1.32;
    }

    &.Show {
      height: max-content;
      padding: 0.5rem 0.7rem;
      overflow: auto;

      p {
        font-size: 0.8rem;
        line-height: 1.32;
      }

      .TermsAndConditionsNote {
        margin-top: 0.7rem;

        span {
          color: blue;
          cursor: pointer;
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}
@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media (min-width: 768px) {
}

@media (min-width: 900px) {
  .Wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    .SubserviceOptionContainer {
      display: flex;
      width: 65%;
      align-items: center;
      justify-content: space-between;
      gap: 1.8rem;
      color: $v2maingreen;
      font-size: 0.9rem;

      .SubserviceInfoContainer {
        display: flex;
        gap: 0.3rem;
        width: max-content;

        button {
          width: 1.2rem;
          height: 1.2rem;
          background-color: transparent;
          border: 0 none;
          color: darken($v2maingreen, 10%);
          font-size: 1.01rem;
          cursor: pointer;
        }
      }
    }

    .ServiceDescription {
      display: flex;
      flex-direction: column;
      max-width: 65%;
      background-color: #fff;
      border-radius: 0 0 10px 10px;
      height: 0;
      overflow: hidden;
      transition: all 0.24s linear;
      padding: 0;
      color: $v2maingreen;

      p {
        font-size: 0rem;
        line-height: 1.32;
      }

      &.Show {
        height: max-content;
        padding: 0.5rem 0.7rem;
        overflow: auto;

        p {
          font-size: 0.84rem;
          line-height: 1.32;
        }

        .TermsAndConditionsNote {
          margin-top: 0.7rem;

          span {
            color: blue;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (min-width: 1000px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1536px) {
}

@media (min-width: 1920px) {
}

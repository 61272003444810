@import '../../../sass/manifest';

.ListItemContainer {
  display: none;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .ListItemContainer {
    display: grid;
    grid-template-columns: 35px repeat(4, 1fr) 45px;
    width: 100%;
    padding: 0.4rem 0;
    color: $periopsisGray;
    align-items: center;
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../sass/manifest';

.WindowContainer {
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0.75rem;
  background-color: #fff;
  max-height: 65vh;
  max-height: 65dvh;
  overflow-y: auto;
  @include scroll-Y;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .WindowContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.85rem;
    background-color: #fff;
    max-height: unset;
    max-height: unset;
    overflow-y: auto;
    @include scroll-Y;
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
  .WindowContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.85rem;
    background-color: #fff;
    max-height: 55vh;
    max-height: 55dvh;
    overflow-y: auto;
    @include scroll-Y;
  }
}

.CyprusMapDistricts {
  fill: rgb(0, 76, 108);
  stroke-width: 1.04412px;
  transition: all 0.3s linear;
  cursor: pointer;

  &:hover {
    fill: rgb(4, 121, 172);
  }

  &.Selected {
    fill: #0b91cb;
    fill: #8aa8b2;
  }

  &.ShowOnlySelected {
    transform: scale(1.5) translate(15px, 5px);
    transform-origin: center;
  }

  &.HideUnSelected {
    transform: scale(0);
    opacity: 0;
  }
}

.DistrictNames {
  font-size: 0.73rem;
  fill: #fff;
  font-weight: 300;
  cursor: pointer;
}

.MapContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MapSize {
  height: 32rem;
  margin-top: -4rem;
  max-width: 100%;
  transform: scale(0.85);

  @media only screen and (min-width: 640px) {
    height: 50rem;
  }

  @media only screen and (min-width: 720px) {
    height: 58rem;
  }

  @media only screen and (min-width: 900px) {
    height: 72rem;
    margin-top: -9rem;
  }

  @media only screen and (min-width: 1200px) {
    height: 75rem;
  }

  @media only screen and (min-width: 1920px) {
    height: 78rem;
  }
}

@import '../../../sass/manifest';
.PillOptionContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0;

  .OptionButton {
    padding: 0.55rem 0.75rem;
    border: 0 none;
    background-color: lighten($lightGray, 10%);
    color: $v2maingreen;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s linear;
    box-shadow: inset 2px 2px 5px rgb(104, 104, 104);
    opacity: 0.7;

    &:first-child {
      border-right: 1px solid $v2maingreen;
      border-radius: 15px 0 0 15px;
    }

    &:last-child {
      border-left: 1px solid $v2maingreen;
      border-radius: 0 15px 15px 0;
    }

    &.Selected {
      background-color: $v2maingreen;
      color: #fff;
      box-shadow: inset 2px 2px 8px lighten($v2maingreen, 20%);
      opacity: 1;
    }
  }
}

@import '../../../../../../../sass/manifest';

.CheckboxItem {
  display: flex;
  align-items: center;
  gap: 0.45rem;
  padding: 0.2rem 0;
  position: relative;
  width: max-content;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 0 none;
    cursor: pointer;

    svg {
      font-size: 0.9rem;
      color: $periopsisOrange;
    }
  }

  .InfoCard {
    display: flex;
    flex-direction: column;
    padding: 1.15rem;
    position: absolute;
    left: calc(100% + 10px);
    width: 35rem;
    background-color: #fff;
    box-shadow: 1px 1px 5px $lightGray;
    border-radius: 10px;
    font-size: 0.9rem;
    line-height: 1.35;
    z-index: 999;

    h3 {
      padding-bottom: 0.1rem;
      margin-bottom: 0.4rem;
      border-bottom: 1px solid $periopsisOrange;
    }

    p {
      padding: 0.3rem 0;
    }
  }
}

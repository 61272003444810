@import '../../../sass/manifest';

.AddressContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.75rem;
  padding: 1.05rem;
  border: 1px solid $lightGray;
  border-radius: 8px;
  margin: 0.5rem 0;

  h3 {
    color: $v2maingreen;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .AddressTypeSelectionNote {
    padding: 0.5rem 0;
    line-height: 1.35;
    font-size: 0.9rem;
    color: $v2maingreen;

    &::first-letter {
      font-weight: 600;
      font-size: 0.98rem;
    }
  }

  .TwoEntry {
    display: flex;
    gap: 0.5rem;
    width: 100%;

    div {
      width: 100%;
    }

    &.SmallerRight {
      & > div:nth-child(1) {
        flex: 3;
      }

      & > div:nth-child(2) {
        flex: 1;
      }
    }
  }

  .MoreAddress {
    font-size: 0.75rem;
    margin: 0.3rem 0;
    color: $lightGray;
  }
}

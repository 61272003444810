.NewAddressWrapper {
  padding: 3rem 1.2rem;

  .NewAddressActions {
    padding: 0;
    margin: 0;
    justify-content: space-between;
    padding-top: 3rem;
    gap: 1rem;

    .ActionButton {
      max-width: 40%;
      width: 40%;
    }
  }
}

@import '../../../../../sass/manifest';

.ReportsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.35rem;
  padding: 0.5rem 0;

  .Common,
  .ReportsList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .ReportsList {
    padding: 0.4rem 0;
    border-radius: 0.5rem;
  }
}

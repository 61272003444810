.ResendBtnContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.35rem;
}

.BtnText {
  font-size: 0.98rem;
  color: gray;
  margin-top: 0.45rem;
}

@import '.././../../../../sass/manifest';

.AddUserSection {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  margin-bottom: 0.95rem;

  p {
    padding: 0.6rem 0.2rem;
    color: $v2maingreen;
    font-size: 0.92rem;
    line-height: 1.35;
  }

  .AddNewUserActions {
    border-bottom: 1px solid $v2maingreen;
    justify-content: center;

    padding: 1.05rem 0;

    button {
      padding: 0.78rem 0;
      min-width: 35%;
      text-transform: uppercase;
    }
  }
}

@import '../../../../../../sass/manifest';

.ContractIdRow {
  grid-template-columns: 1fr 8px max-content;
}

.SubServiceListWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style: none;
  padding: 0 0.8rem;
  height: 0;
  overflow: hidden;
  transition: all 0.2s linear;

  &.Show {
    @include scroll-Y;
    height: max-content;

    li {
      padding-bottom: 0.2rem;
      border-bottom: 1px solid lighten($lightGray, 15%);
    }
  }

  li {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.SelectedDistricts {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.4rem;

  padding: 0.1rem;

  span {
    padding: 0.35rem 0.8rem;
    color: #fff;
    background-color: $v2maingreen;
    border-radius: 10px;
  }
}

.Address {
  padding: 0.6rem 0.3rem 0.3rem 0.3rem;
  margin-top: 0.4rem;
  border-top: 1px solid $lightGray;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  color: darken($lightGray, 9%);

  .AddressOptions {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-top: 0.8rem;
  }

  .AddressHeading {
    display: flex;
    justify-content: space-between;
    h4 {
      font-size: 0.85rem;
    }

    button {
      cursor: pointer;
      background-color: transparent;
      border: 0 none;
      font-size: 0.8rem;
      color: $v2maingreen;
    }
  }
}

.OverallTotal {
  padding-top: 0.25rem;
  font-weight: 700;
  margin-top: 0.25rem;
  border-top: 1px solid $lightGray;

  &.NoBorder {
    border: 0 none;
  }
}

.ValidityNote {
  @include introParagraph;
  display: flex;
  max-width: 100%;
  padding: 0.2rem 0.6rem 0.8rem 0.6rem;
  text-align: justify;
  hyphens: auto;
  color: $lightGray;
}

.DownloadSection {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  .DownloadLink {
    text-decoration: none;
    color: $v2maingreen;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    padding: 0.3rem;
  }
}

.AddressLimitMessage {
  padding: 0.2rem 0;
  font-size: 0.8rem;
  color: $danger;
}

@import '../../../../sass/manifest';

.DropDownWrapper {
  display: flex;
  position: relative;
  width: 100%;

  span {
    color: $danger;
    position: absolute;
    left: 12rem;
    top: 0.3rem;
  }

  .GaeaDropDownSelect {
    padding: 0.4rem 0rem;
    border: 0 none;
    border-bottom: 1px solid $lightGray;
    font-size: 0.92rem;
    width: 100%;
    background-color: transparent;
    color: #808080;

    &:focus {
      border-bottom: 1px solid $v2maingreen;
    }
  }

  .GaeaDropDownSelect.DefaultValueStyle {
    font-size: 0.9rem;
    color: #808080;
  }
}

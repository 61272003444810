@import '../../../sass/manifest';

.ViewPortContainer {
  display: flex;
  background: $v2maingreen;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;

  .ConfirmationContainer {
    position: absolute;
    width: 85vw;
    padding: 1rem;
    background-color: #fff;
    border-radius: 0px;
    box-shadow: none;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    color: $periopsisGray;
    height: 100vh;
    height: 100dvh;

    .ImageContainer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.75rem;

      img {
        width: 7.5rem;
      }
    }

    .ConfirmationMessageSection {
      border-top: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
    }

    .ConfirmationMessageSection {
      text-align: left;
      font-size: 0.8rem;
      padding: 0.8rem 0.2rem 0.8rem 0.2rem;
      margin: 1rem 0 1rem 0;
      letter-spacing: 0.1rem;
      line-height: 1.1rem;
    }

    .ConfirmationActions {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      width: 100%;

      button {
        width: 100%;
      }
    }

    .Timer {
      font-size: 0.8rem;
      margin-top: 0.3rem;
      text-align: center;

      span {
        font-weight: 600;
        color: $v2maingreen;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .ViewPortContainer {
    .ConfirmationContainer {
      position: absolute;
      width: 92vw;
      padding: 1rem;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 1px 1px 4px black;
      top: 10%;
      left: 50%;
      transform: translate(-50%, 0%);
      height: 70vh;
      height: 70dvh;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .ViewPortContainer {
    .ConfirmationContainer {
      position: absolute;
      width: 92vw;
      padding: 1rem;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 1px 1px 4px black;
      top: 10%;
      left: 50%;
      transform: translate(-50%, 0%);
      height: 68vh;
      height: 68dvh;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .ViewPortContainer {
    .ConfirmationContainer {
      width: 85vw;
      border-radius: 0px;
      box-shadow: none;
      top: 0%;
      left: 50%;
      height: 100vh;
      height: 100dvh;
    }
  }
}

@media only screen and (min-width: 768px) {
  .ViewPortContainer {
    .ConfirmationContainer {
      width: 55vw;
      padding: 2rem;
      border-radius: 10px;
      box-shadow: 1px 1px 4px black;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $periopsisGray;
      height: unset;
      height: unset;

      .ImageContainer {
        img {
          width: 8.5rem;
        }
      }

      .ConfirmationMessageSection {
        text-align: left;
        font-size: 0.8rem;
        padding: 1.3rem 0.5rem 1.3rem 0.5rem;
        margin: 2rem 0 1.2rem 0;
        letter-spacing: 0.1rem;
        line-height: 1.3rem;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .ViewPortContainer {
    .ConfirmationContainer {
      width: 48vw;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .ViewPortContainer {
    .ConfirmationContainer {
      width: 40vw;

      .ImageContainer {
        img {
          width: 9.5rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .ViewPortContainer {
    .ConfirmationContainer {
      width: 30vw;

      .ImageContainer {
        img {
          width: 10.5rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .ViewPortContainer {
    .ConfirmationContainer {
      width: 23vw;

      .ImageContainer {
        img {
          width: 11.5rem;
        }
      }
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

$body-bg: #f0f2f5;
$nav-bg-dark-blue: rgb(11, 82, 223);
$nav-bg-light-blue: rgb(35, 104, 241);
$gray: #9b9b9b;
$white: #fff;
$logo-font: 'Righteous', cursive;
$focus-color: gold;
$back-contrast-color: #e3e3e3;
$primary: $nav-bg-light-blue;
$danger: rgb(219, 25, 25);
$warning: #ff9900;
$info: rgb(0, 157, 255);
$success: yellowgreen;
$v2maingreen: #004c6c;
$v2maingreenOpac: rgba(0, 76, 108, 0.85);
$periopsisGray: #8aa8b2;
$lightGray: lighten($gray, 5);
$activeColor: $v2maingreen;
$periopsisOrange: #d95c2d;
$periopsisOrangeOpac: #d95b2dd3;
$darkenColor: darken($activeColor, 10%);
$notification: #efd6c1;
$importantPageMessage: rgb(233, 236, 155);
$ig-small-screen: 768px !default;
$ig-xsmall-screen: 480px !default;
$ig-white: #fff !default;
$ig-black: #000 !default;
$ig-blue: #337ab7 !default;
$ig-background-black: rgba(0, 0, 0, 0.4) !default;
$ig-transparent: rgba(0, 0, 0, 0) !default;
$ig-shadow: 0 2px 2px lighten($ig-black, 10%);
$homebgBackup: rgb(26, 235, 182);
$disabled: #8aa8b2;
$homebgLinear: linear-gradient(
  45deg,
  rgba(26, 235, 182, 1) 0%,
  rgba(22, 245, 189, 1) 35%,
  rgba(255, 255, 255, 1) 100%
);

$box-shadow: 2px 2px 5px darken($gray, 10%);
$inset-box-shadow: 1px 1px 3px black;
$radialBG: radial-gradient(
  circle,
  rgba(0, 76, 108, 1) 0%,
  rgba(0, 98, 139, 1) 48%,
  rgba(0, 79, 112, 1) 100%
);

$gradientBG: linear-gradient(
  160deg,
  rgba(0, 76, 108, 1) 0%,
  rgba(11, 116, 161, 1) 42%,
  rgba(0, 116, 164, 1) 100%
);

@import '../../../../../sass/manifest';

.FormInformationContainer {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  max-height: 75vh;
  max-height: 75dvh;
  padding: 0.8rem 0;
  font-size: 0.9rem;
  line-height: 1.35;
  width: 100%;

  color: $v2maingreen;

  p {
    padding: 0.3rem 0;
  }
}

.FieldInformationWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin: 0.5rem 0;

  h4 {
    font-size: 1rem;
    padding: 0.2rem 0;
    margin-bottom: 0.2rem;
    border-bottom: 1px solid $periopsisOrange;
  }
}

.SectionWrapper {
  display: flex;
  flex-direction: column;
  margin: 0.8rem 0;
  gap: 0.3rem;

  h1 {
    font-size: 1.15rem;
    text-transform: uppercase;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid $v2maingreen;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .FormInformationContainer {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    max-height: 75vh;
    max-height: 75dvh;
    padding: 0.9rem 0;
    font-size: 1.05rem;
    line-height: 1.35;
    width: 100%;

    color: $v2maingreen;

    p {
      padding: 0.4rem 0;
    }
  }

  .FieldInformationWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin: 0.8rem 0;

    h4 {
      font-size: 1.15rem;
      padding: 0.2rem 0;
      margin-bottom: 0.2rem;
      border-bottom: 1px solid $periopsisOrange;
    }
  }

  .SectionWrapper {
    display: flex;
    flex-direction: column;
    margin: 0.8rem 0;
    gap: 0.3rem;

    h1 {
      font-size: 1.65rem;
      text-transform: uppercase;
      margin-bottom: 0.3rem;
      border-bottom: 1px solid $v2maingreen;
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../../../../sass/manifest';

.CVVIntroContainer {
  display: flex;
  flex-direction: column;
  padding: 1.2rem 1.5rem;
  background-color: #fff;
  position: absolute;
  z-index: 60000;
  max-width: 40%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  box-shadow: 2px 2px 8px black;
  gap: 1rem;

  .HeadingSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0;
    border-bottom: 1px solid $v2maingreen;

    button {
      display: flex;
      padding: 0;
      font-size: 1.22rem;
      background-color: transparent;
      border-radius: 50%;
      border: 0 none;
      cursor: pointer;
      color: $v2maingreen;
    }
  }

  p {
    @include introParagraph;
  }

  .ImageContainer {
    display: flex;
    max-width: 80%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    img {
      max-width: 95%;
    }
  }
}

@import '../../../../../../../../sass/manifest';

.CheckoutCard {
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;

  .HeadingSection {
    display: flex;
    padding: 0 0 1rem 0;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 1.1rem;
      color: $v2maingreen;
    }
  }
}

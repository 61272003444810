.ModalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 500;
}

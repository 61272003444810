.SectionExplanationContainer {
  color: rgb(172, 172, 172);
  text-align: center;

  .HeadingSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.8rem;

    h2 {
      font-size: 0.9rem;
      margin: 0;
      margin-left: 0.3rem;
    }

    svg {
      margin-bottom: 0.3rem;
    }
  }

  p {
    margin-bottom: 0.6rem;
    font-size: 0.78rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .SectionExplanationContainer {
    text-align: left;
    .HeadingSection {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;
      justify-content: flex-start;

      h2 {
        font-size: 0.83rem;
        margin: 0;
        margin-left: 0.3rem;
      }

      svg {
        margin-bottom: 0.3rem;
      }
    }

    p {
      margin-bottom: 0.3rem;
      font-size: 0.73rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .SectionExplanationContainer {
    .HeadingSection {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.8rem;
      justify-content: flex-start;

      h2 {
        font-size: 0.88rem;
        margin: 0;
        margin-left: 0.3rem;
      }

      svg {
        margin-bottom: 0.3rem;
      }
    }

    p {
      margin-bottom: 0.3rem;
      font-size: 0.76rem;
    }
  }
}

@media only screen and (min-width: 900px) {
  .SectionExplanationContainer {
    .HeadingSection {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h2 {
        font-size: 1.2rem;
      }
    }

    p {
      margin-bottom: 0.4rem;
      font-size: 1rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

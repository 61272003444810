@import './variables.scss';

@mixin logo($color: white) {
  font-family: sans-serif;
  letter-spacing: 0.1em;
  color: $color;
}

@mixin primary($hasHoverEffect: false) {
  background-color: $v2maingreen;
  color: #fff;
  border: 1px solid $v2maingreen;

  @if $hasHoverEffect {
    &:hover {
      background-color: darken($v2maingreen, 2);
      box-shadow: 0 0 10px lighten($v2maingreen, 5%);
    }
  }
}

@mixin primaryLight($hasHoverEffect: false) {
  background-color: $lightGray;
  color: #fff;
  border: 1px solid $lightGray;

  @if $hasHoverEffect {
    &:hover {
      background-color: lighten($lightGray, 8%);
      box-shadow: 0 0 10px lighten($lightGray, 5%);
    }
  }
}

@mixin primaryLightBlue($hasHoverEffect: false) {
  background-color: lighten($v2maingreen, 18%);
  color: #fff;
  border: 1px solid lighten($v2maingreen, 18%);

  @if $hasHoverEffect {
    &:hover {
      background-color: lighten($v2maingreen, 35%);
      box-shadow: 0 0 10px lighten($v2maingreen, 5%);
    }
  }
}

@mixin peach($hasHoverEffect: false) {
  background-color: #e99f83;
  color: #fff;
  border: 1px solid #e99f83;

  @if $hasHoverEffect {
    &:hover {
      background-color: darken(#e99f83, 5%);
      box-shadow: 0 0 10px darken(#e99f83, 5%);
    }
  }
}

@mixin danger($hasHoverEffect: false) {
  background-color: $danger;
  color: #fff;
  border: 1px solid $danger;

  @if $hasHoverEffect {
    &:hover {
      background-color: darken($danger, 2);
      box-shadow: 0 0 10px lighten($danger, 5);
    }
  }
}

@mixin warning($hasHoverEffect: false) {
  background-color: $warning;
  color: #f0f0f0;
  border: 1px solid $warning;

  @if $hasHoverEffect {
    &:hover {
      background-color: darken($warning, 2);
      box-shadow: 0 0 10px lighten($warning, 5);
    }
  }
}

@mixin info($hasHoverEffect: false) {
  background-color: $info;
  color: #f0f0f0;
  border: 1px solid $info;

  @if $hasHoverEffect {
    &:hover {
      background-color: darken($info, 2);
      box-shadow: 0 0 10px lighten($info, 5);
    }
  }
}

@mixin success($hasHoverEffect: false) {
  background-color: $success;
  color: #f0f0f0;
  border: 1px solid $success;

  @if $hasHoverEffect {
    &:hover {
      box-shadow: 0 0 10px lighten($success, 5);
      background-color: darken($success, 2);
    }
  }
}

@mixin main($hasHoverEffect: false) {
  background-color: $v2maingreen;
  color: #f0f0f0;
  border: 1px solid $v2maingreen;

  @if $hasHoverEffect {
    &:hover {
      box-shadow: 0 0 10px lighten($v2maingreen, 5);
      background-color: darken($v2maingreen, 2);
    }
  }
}

@mixin orange($hasHoverEffect: false) {
  background-color: $periopsisOrange;
  color: #f0f0f0;
  border: 1px solid $periopsisOrange;

  @if $hasHoverEffect {
    &:hover {
      box-shadow: 0 0 10px lighten($periopsisOrange, 5);
      background-color: darken($periopsisOrange, 2);
    }
  }
}

@mixin gray($hasHoverEffect: false) {
  background-color: $periopsisGray;
  color: #f0f0f0;
  border: 1px solid $periopsisGray;

  @if $hasHoverEffect {
    &:hover {
      box-shadow: 0 0 10px lighten($periopsisGray, 5);
      background-color: darken($periopsisGray, 2);
    }
  }
}

@mixin plain($hasHoverEffect: false) {
  background-color: #fff;
  color: $v2maingreen;
  border: 1px solid $v2maingreen;

  @if $hasHoverEffect {
    &:hover {
      box-shadow: 0 0 10px lighten($v2maingreen, 5);
    }
  }
}

@mixin displayFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin widthMaxContent {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

@mixin heightMaxContent {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

@mixin formElementInvalid {
  background-color: rgba(255, 0, 0, 0.384);
  border-radius: 0.45em 0.45em 0 0;
  border-bottom: 1px solid rgb(255, 14, 14);
  color: #fff !important;
}

@mixin formModalContent {
  padding: 1.2rem 0.6rem;
  color: $gray;
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 1.75rem;
}

@mixin FlexCenter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin scroll-Y {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    width: 5px;
    background: $lightGray;
    border-radius: 20px;
    box-shadow: inset 1px 1px 3px rgb(82, 82, 82);
  }

  &::-webkit-scrollbar-thumb {
    background: $v2maingreen;
    border-radius: 20px;
  }

  scrollbar-width: thin;
  scrollbar-color: $v2maingreen;
}

@mixin scroll-shape($width) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    width: $width;
    background-color: $lightGray;
    border-radius: 20px;
    box-shadow: inset 1px 1px 3px black;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $v2maingreen;
    border-radius: 20px;
  }

  scrollbar-width: thin;
}

@mixin ChunkContainer {
  margin: 0.5rem 0;

  h5 {
    color: darken($gray, 10%);
    margin: 0 0 0.2rem 0.1rem;
  }
}

@mixin reportActionCreator {
  margin-bottom: 0.6rem;
  margin-top: 0.6rem;
  padding: 0 0.3rem;
}

@mixin reportInfoSection {
  padding: 0 0.5rem;
}

@mixin columnDataContainer($maxWidth: 95%) {
  display: flex;
  flex-direction: column;
  max-width: $maxWidth;
  margin: 0rem auto;
  padding: 0.3rem;
}

@mixin vendor-prefix($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}

@mixin home-boxes {
  background-color: rgba(255, 255, 255, 0.65);
  box-shadow: 4px 4px 6px black, 0px 0px 22px $v2maingreen;
  padding: 0.25rem;
  border-radius: 10px;
  overflow: hidden;
  backdrop-filter: blur(10px);
  border: 1px solid $v2maingreen;
}

@mixin home-box-shadows {
  box-shadow: 4px 4px 6px black, 0px 0px 22px $v2maingreen;
}

@mixin image-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

@mixin home-page-container($image: '', $min-height: calc(100vh - 70px)) {
  background-image: url($image);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  min-height: $min-height;
  position: relative;
}

@mixin container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  &::before {
    display: table;
    content: ' ';
  }
  &::after {
    display: table;
    content: ' ';
    clear: both;
  }

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
}

@mixin column-medium-10 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 992px) {
    width: 83.33333333%;
    float: left;
  }
}

@mixin column-medium-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 992px) {
    width: 50%;
    float: left;
  }
}

@mixin column-medium-8 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 992px) {
    width: 66.66666667%;
    float: left;
  }
}

@mixin column-medium-offset-2 {
  @media (min-width: 992px) {
    margin-left: 16.66666667%;
  }
}

@mixin section-title {
  margin-bottom: 4.37rem;
}

@mixin column-xsmall-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  float: left;
}

@mixin column-medium-offset-1 {
  @media (min-width: 992px) {
    margin-left: 8.33333333%;
  }
}

@mixin column-large-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 1200px) {
    width: 50%;
    float: left;
  }
}
@mixin column-small-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 768px) {
    width: 50%;
    float: left;
  }
}

@mixin column-medium-3 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 992px) {
    width: 25%;
    float: left;
  }
}

@mixin row {
  margin-right: -0.98rem;
  margin-left: -0.98rem;

  &::before {
    display: table;
    content: ' ';
  }
  &::after {
    display: table;
    content: ' ';
    clear: both;
  }
}

@mixin TextCenter(
  $background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)
) {
  padding: 100px 0;
  background: $background;
  color: #fff;
  text-align: center;
}

@mixin rowDataContainer($gridColumns: 30px 1fr 2fr 18px) {
  display: grid;
  grid-template-columns: $gridColumns;
  padding: 1rem 0;
  width: 100%;

  span {
    font-weight: 600;
    font-size: 0.76rem;
  }

  p {
    font-size: 0.75rem;
  }

  svg {
    color: $danger;
  }

  svg:first-child {
    color: $v2maingreen;
  }

  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: 45px 1fr 2fr 20px;
    padding: 0.8rem 0;
    width: 100%;
    align-items: center;

    p {
      font-size: unset;
    }

    span {
      font-weight: 600;
      font-size: unset;
    }
  }
}

@mixin adminForms {
  padding: 0.9rem;
  width: 98%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: $box-shadow;

  @media (min-width: 980px) {
    padding: 1.7rem 2.5rem;
    width: 50%;
  }

  @media (min-width: 1200px) {
    padding: 2rem 3rem;
    width: 45%;
    margin-top: 8rem;
  }
}

@mixin adminFormActionContainer {
  margin-top: 1.5rem;
  button {
    width: 100%;
    height: 2.2rem;
  }
}

@mixin introParagraph {
  $fontSize: 0.8rem;
  font-size: $fontSize;
  line-height: calc(1.45 * $fontSize);
  letter-spacing: 0.92px;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 3) {
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 4) {
  }

  @media only screen and (min-width: 600px) {
  }

  @media only screen and (min-width: 768px) {
  }

  @media only screen and (min-width: 900px) {
  }

  @media only screen and (min-width: 1200px) {
  }

  @media only screen and (min-width: 1536px) {
    $fontSize: 0.9rem;
    font-size: $fontSize;
    line-height: calc(1.45 * $fontSize);
    letter-spacing: 0.92px;
  }

  @media only screen and (min-width: 1920px) {
  }
}

@import '../../../sass/manifest';

.RoWMainContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lighten($color: $gray, $amount: 15%);
  position: relative;
  align-items: center;

  .OptionalInfoSection {
    font-size: 0.8rem;
    color: lighten($color: $gray, $amount: 15%);
    padding: 0 0 0.6rem 0.6rem;
  }

  .EditableRowDataContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    width: 100%;
    color: $v2maingreen;
    align-items: center;
    justify-items: center;
    max-width: 95%;
    gap: 0.3rem;

    &.Undoable {
      grid-template-columns: 30px 1fr 2fr 18px 18px;
    }

    p {
      font-size: 0.85rem;
    }

    span {
      font-size: 0.89rem;
      font-weight: 600;
    }

    input {
      padding: 0.4rem;
      max-width: 80%;
      color: $v2maingreen;
      background-color: #fff;
      font-weight: 600;
      border-radius: 10px;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .RoWMainContainer {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid lighten($color: $gray, $amount: 15%);
    position: relative;
    align-items: center;

    .OptionalInfoSection {
      font-size: 0.8rem;
      color: lighten($color: $gray, $amount: 15%);
      padding: 0 0 0.6rem 0.6rem;
    }

    .EditableRowDataContainer {
      display: grid;
      grid-template-columns: 30px 1fr 2fr 18px;
      padding: 1rem 0;
      width: 100%;
      color: $v2maingreen;
      align-items: center;
      justify-items: flex-start;

      &.Undoable {
        grid-template-columns: 30px 1fr 2fr 18px 18px;
      }

      p {
        font-size: 0.85rem;
      }

      span {
        font-size: 0.89rem;
        font-weight: 600;
      }

      input {
        padding: 0.4rem;
        max-width: 80%;
        color: $v2maingreen;
        background-color: #fff;
        font-weight: 600;
        border-radius: 10px;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .RoWMainContainer {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid lighten($color: $gray, $amount: 15%);
    position: relative;
    align-items: center;

    .OptionalInfoSection {
      font-size: 0.8rem;
      color: lighten($color: $gray, $amount: 15%);
      padding: 0 0 0.6rem 0.6rem;
    }

    .EditableRowDataContainer {
      display: grid;
      grid-template-columns: 30px 1fr 2fr 18px;
      padding: 1rem 0;
      width: 100%;
      color: $v2maingreen;
      align-items: center;
      justify-items: flex-start;

      &.Undoable {
        grid-template-columns: 30px 1fr 2fr 18px 18px;
      }

      span {
        font-size: 1rem;
      }

      input {
        padding: 0.4rem;
        max-width: 80%;
        color: $v2maingreen;
        background-color: #fff;
        font-weight: 600;
        border-radius: 10px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

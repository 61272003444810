@import '../../../../../sass/manifest';

.Table {
  display: flex;
  flex-direction: column;
  h3 {
    width: 100%;
    text-align: center;
    padding: 0.3rem 0;
    background-color: $v2maingreen;
    color: #fff;
    font-size: 0.9rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .Table {
    h3 {
      padding: 0.5rem 0;
      font-size: 1.05rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .Table {
    h3 {
      padding: 0.6rem 0;
      font-size: 1.25rem;
    }
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

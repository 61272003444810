.SideBar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 4px;
  z-index: 999999999999999999999999999;
  font-size: 0.7rem;
  display: none;
}

@media (min-width: 800px) {
  .SideBar {
    display: block;
    font-size: 0.95rem;
  }
}

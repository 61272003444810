.Content {
  border-radius: 0;
  padding: 0.4rem 2.2rem 2rem 2.2rem;
}

.ConfirmationErrorImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 16rem;
  margin: 2rem auto;

  img {
    width: 100%;
  }
}

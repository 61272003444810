@import '../../../sass/variables.scss';
@import '../../../sass/mixins.scss';

.SideMenuContainer {
  display: none;
}

@media (min-width: 900px) {
  .SideMenuContainer {
    @include scroll-Y;
    display: flex;
    position: fixed;
    left: 0;
    top: 70px;
    height: calc(100vh - 70px);
    background: #fff;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 4rem 0;
    z-index: 9;
    width: 6rem;
    transition: width 0.15s ease-in-out;

    &.Expanded {
      width: 15rem;
    }

    .UpperIcons,
    .LowerIcons {
      width: 85%;
    }
  }
}

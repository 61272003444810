@import '../../../sass/variables.scss';
@import '../../../sass/mixins.scss';

.DashboardContainer {
  @include displayFlex;
  flex-direction: column;
  position: relative;
  width: initial;
  margin: 0 auto;
  // margin-top: 85px;

  .ListWrapper {
    display: flex;
  }

  .CenterMap {
    justify-content: center;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .DashboardContainer {
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .DashboardContainer {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .DashboardContainer {
    width: 100%;

    .ListWrapper {
      overflow-y: auto;
      overflow-x: hidden;
      @include scroll-Y;
      max-height: 65vh;
      max-height: 65dvh;
    }
  }
}

@media only screen and (min-width: 1280px) {
  .DashboardContainer {
    .ListWrapper {
      max-height: 67vh;
      max-height: 67dvh;
    }
  }
}

@media only screen and (min-width: 1360px) {
  .DashboardContainer {
    .ListWrapper {
      max-height: 66vh;
      max-height: 66dvh;
    }
  }
}

@media only screen and (min-width: 1420px) {
  .DashboardContainer {
    .ListWrapper {
      max-height: 70vh;
      max-height: 70dvh;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .DashboardContainer {
    width: 100%;

    .ListWrapper {
      max-height: 67vh;
      max-height: 67dvh;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .DashboardContainer {
    .ListWrapper {
      max-height: 69vh;
      max-height: 69dvh;
    }
  }
}

@media only screen and (min-width: 1680px) {
  .DashboardContainer {
    .ListWrapper {
      max-height: 73vh;
      max-height: 73dvh;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .DashboardContainer {
    width: 90%;

    .ListWrapper {
      max-height: 74vh;
      max-height: 74dvh;
    }
  }
}

@import '../../../../../../sass/manifest';

.RadioButtons {
  padding: 0.2rem;
  align-items: center;
  justify-content: space-evenly;
  width: 85%;
  margin: 0 auto;
}

.FormExplanation {
  display: flex;
  padding: 0.6rem 0;
  font-size: 0.85rem;
  color: $v2maingreen;
}

.OrganizationSelectionSection {
  display: flex;
  width: 100%;
  margin-bottom: 1.2rem;
}

.SelectOrganization {
  width: 100%;
  margin: 1.2rem 0 1.2rem 0;
}

.SignUpForm {
  display: flex;
  height: 95%;
  justify-content: space-evenly;
  gap: 0.55rem;
  opacity: 1;
  transform: scale(1);
  transition: all 1s linear;

  .FormActions {
    gap: 1.5rem;

    &.Centered {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  &.HideForm {
    opacity: 0;
    transform: scale(0.1);
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .FormExplanation {
    font-size: 0.92rem;
  }

  .SignUpForm {
    .PasswordConditionsContainer {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      h3 {
        padding-bottom: 0.2rem;
        border-bottom: 1px solid #fff;
      }

      .PasswordConditions {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .RadioButtons {
    width: 50%;
  }

  .SignUpForm {
    display: flex;
    height: 90%;
    justify-content: space-evenly;
    gap: 0.8rem;
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .FormExplanation {
    font-size: 1.05rem;
  }

  .SignUpForm {
    .PasswordConditionsContainer {
      gap: 0.8rem;

      .PasswordConditions {
        gap: 0.4rem;
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .SignUpForm {
    .PasswordConditionsContainer {
      gap: 0.9rem;

      .PasswordConditions {
        gap: 0.6rem;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .SignUpForm {
    .PasswordConditionsContainer {
      gap: 0.7rem;

      .PasswordConditions {
        gap: 0.4rem;
      }
    }
  }
}

.PasswordConditionsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h3 {
    padding-bottom: 0.2rem;
    border-bottom: 1px solid #fff;
  }

  .PasswordConditions {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }
}

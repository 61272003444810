@import '../../../../sass/variables.scss';

.LinkContent {
  color: $v2maingreen;
  text-decoration: none;
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  gap: 0.3rem;

  &:hover {
    background-color: $v2maingreen;
    color: #fff;
  }

  .LabelIcon,
  .ButtonIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.68rem;
    padding: 1rem 0.4rem;
    transition: all 0.25s ease-in-out;
    background-color: transparent;
    width: 100%;
    gap: 0.3rem;

    label {
      cursor: pointer;
      text-align: center;
      margin-top: 0.1rem;
    }

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      font-size: 0.75rem;
    }

    &.Active {
      background-color: $v2maingreen;
      color: #fff;

      &.Expanded {
        flex-direction: row;
        align-items: center;
        min-height: 42px;
      }
    }

    &.Expanded {
      flex-direction: row;
      align-items: center;
      min-height: 42px;

      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        margin-top: 0;

        label {
          margin-left: 0.8rem;
        }
      }

      label {
        font-size: 1rem;
        margin-left: 0.95rem;
        margin-top: 0;
      }
    }
  }

  .ButtonIcon button {
    background-color: transparent;
    border: none;
    color: $v2maingreen;
    cursor: pointer;
    display: flex;
    gap: 0.3rem;

    span {
      font-size: 0.82rem;
      text-align: center;
    }
  }

  .ButtonIcon:hover button {
    color: #fff;
  }

  .LinkLabel {
    font-size: 0.82rem;
    text-align: center;
  }
}

.ImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.67rem;
  height: 6.67rem;
  overflow: hidden;
  border-radius: 50%;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
  }
}

@import '../../../../../sass/manifest';

.FilterMainContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  width: 100dvw;
  max-width: 100vw;
  max-width: 100dvw;
  background-color: #f0f2f5;
  z-index: 1;

  .ContentContainer {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0.5rem;
    width: inherit;
    gap: 0.3rem;
    width: 100%;

    .UserContracts {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 1rem;
      padding: 0.5rem 0 0.75rem 0;
      border-bottom: 1px solid $periopsisOrange;

      .ContractSection,
      .IncidentsCount {
        flex: 1;
      }

      .ContractSection {
        display: flex;
        align-items: center;

        p {
          padding-bottom: 0.1rem;
          width: max-content;
          font-size: 1.05rem;
          color: $v2maingreen;
        }
      }
    }

    .IncidentFilterActions {
      display: flex;
      flex-direction: column;

      .StatusFiltering {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.1rem 0;
        justify-content: center;
        gap: 0.5rem;
      }

      .FilterBySection {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem 2rem;
        margin: 0;

        .FilterBySelection {
          display: flex;
          gap: 0.5rem;
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .FilterMainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: unset;
    background-color: #f0f2f5;
    z-index: 1;

    .ContentContainer {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 0.5rem;
      width: inherit;
      gap: 0.3rem;
      width: 100%;

      .UserContracts {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 1rem;
        padding: 0.5rem 0 0.75rem 0;
        border-bottom: 1px solid $periopsisOrange;

        .ContractSection,
        .IncidentsCount {
          flex: 1;
        }

        .ContractSection {
          display: flex;
          align-items: center;

          p {
            padding-bottom: 0.1rem;
            width: max-content;
            font-size: 1.05rem;
            color: $v2maingreen;
          }
        }
      }

      .IncidentFilterActions {
        display: flex;
        flex-direction: column;

        .StatusFiltering {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 0.1rem 0;
          justify-content: center;
          gap: 0.5rem;
        }

        .FilterBySection {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem 2rem;
          margin: auto;

          .FilterBySelection {
            display: flex;
            gap: 0.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .FilterMainContainer {
    display: flex;
    flex-direction: column;
    width: 96%;
    background-color: #f0f2f5;
    top: 4rem;
    z-index: 1;
    margin: 0 auto;

    .ContentContainer {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 0.5rem;
      width: inherit;
      gap: 0.4rem;
      max-width: 100%;

      .UserContracts {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 1rem;
        padding: 0.5rem 0 0.75rem 0;
        border-bottom: 1px solid $periopsisOrange;

        .ContractSection,
        .IncidentsCount {
          flex: 1;
        }

        .ContractSection {
          display: flex;
          align-items: center;

          p {
            padding-bottom: 0.1rem;
            width: max-content;
            font-size: 1.05rem;
            color: $v2maingreen;
          }
        }
      }

      .IncidentFilterActions {
        display: flex;
        flex-direction: column;

        .StatusFiltering {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 0.1rem 0;
          justify-content: center;
          gap: 0.5rem;
        }

        .FilterBySection {
          display: grid;
          grid-template-columns: max-content;
          gap: 1.5rem 2.5rem;
          margin: auto;

          .FilterBySelection {
            display: flex;
            gap: 0.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .FilterMainContainer {
    .ContentContainer {
      width: 100%;
      .IncidentFilterActions {
        .FilterBySection {
          grid-template-columns: repeat(2, 1fr);
          gap: 1.5rem 2.5rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .FilterMainContainer {
    width: 93%;
    .ContentContainer {
      width: 100%;
      .UserContracts {
        .IncidentsCount {
          p {
            width: 2.45rem;
            height: 2.45rem;

            font-size: 0.95rem;
          }
        }
      }

      .IncidentFilterActions {
        .FilterBySection {
          grid-template-columns: repeat(3, 1fr) max-content;
          gap: 2.5rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
  .FilterMainContainer {
    width: 100%;

    .ContentContainer {
      width: 100%;
    }
  }
}

@import '../../../../sass/manifest';

.LoginFormContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  height: 68vh;
  height: 68dvh;
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.LoginLoadingContainer {
  display: flex;
  height: 68vh;
  height: 68dvh;
  width: 100%;
  position: relative;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .LoginFormContainer {
    min-height: 100%;
    width: 90%;
    height: 67vh;
    height: 67dvh;
  }

  .LoginLoadingContainer {
    height: 67vh;
    height: 67dvh;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .LoginFormContainer {
    min-height: 100%;
    height: 75vh;
    height: 75dvh;
    width: 90%;
  }

  .LoginLoadingContainer {
    height: 75vh;
    height: 75dvh;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .LoginFormContainer {
    min-height: 100%;
    height: 78vh;
    height: 78dvh;
    width: 90%;
  }

  .LoginLoadingContainer {
    height: 78vh;
    height: 78dvh;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .LoginFormContainer {
    width: 75%;
    height: 65vh;
    height: 65dvh;
    margin-top: 2rem;
  }

  .LoginLoadingContainer {
    height: 65vh;
    height: 65dvh;
  }
}

@media only screen and (min-width: 768px) {
  .LoginFormContainer {
    width: 60%;
    margin-top: 5rem;
  }

  .LoginLoadingContainer {
    height: 93vh;
    height: 93dvh;
  }
}

@media only screen and (min-width: 900px) {
  .LoginFormContainer {
    width: 60%;
    margin-top: unset;
    min-height: 35rem;
  }
}

@media only screen and (min-width: 1200px) {
  .LoginFormContainer {
    width: 55%;
  }

  .LoginLoadingContainer {
    height: 96vh;
    height: 96dvh;
  }
}

@media only screen and (min-width: 1536px) {
  .LoginFormContainer {
    width: 60%;
  }
}

@media only screen and (min-width: 1920px) {
  .LoginFormContainer {
    width: 40%;
  }
}

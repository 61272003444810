@import '../../../sass/manifest';

.Menu {
  display: none;
}

@media (min-width: 920px) {
  .Menu {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: max-content;
    min-width: 14rem;
    box-shadow: 1px 1px 3px black;
    min-height: max-content;
    max-width: 480px;
    position: sticky;
    right: 1rem;
    z-index: 9999;

    .MenuOptions {
      @include scroll-Y;
      display: flex;
      flex-direction: column;
      max-height: 65vh;
      overflow-y: hidden;
      overflow: auto;
    }
  }
}

@import '../../../../../../sass/manifest';

.PageNavigation {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.3rem;
  width: 100%;
  color: $v2maingreen;
  font-size: 1.5rem;

  svg {
    cursor: pointer;
    transition: color 0.4s linear;
    &:hover {
      color: darken($v2maingreen, 6);
    }
  }

  .Selected {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.7rem;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    span {
      color: #fff;
      background-color: $v2maingreen;
      border-radius: 0.9rem;
      padding: 0.4rem;
      min-width: max-content;
      text-align: center;
      margin: 0 0.3rem;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .PageNavigation {
    display: flex;
    justify-content: space-between;

    padding: 0.75rem 0.3rem;
    width: 100%;
    color: $v2maingreen;
    font-size: 1.4rem;

    svg {
      cursor: pointer;
      transition: color 0.4s linear;
      &:hover {
        color: darken($v2maingreen, 6);
      }
    }

    .Selected {
      display: flex;
      flex-wrap: wrap;
      font-size: 0.7rem;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
      span {
        color: #fff;
        background-color: $v2maingreen;
        border-radius: 0.9rem;
        padding: 0.3rem;
        min-width: max-content;
        text-align: center;
        margin: 0 0.2rem;
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .PageNavigation {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0.7rem;
    width: 100%;
    color: $v2maingreen;
    font-size: 1.5rem;
    max-width: 100%;

    svg {
      cursor: pointer;
      transition: color 0.4s linear;
      &:hover {
        color: darken($v2maingreen, 6);
      }
    }

    .Selected {
      display: flex;
      flex-wrap: wrap;
      font-size: 0.6rem;
      gap: 0.17rem;
      align-items: center;
      justify-content: center;

      span {
        padding: 0.23rem;
        min-width: max-content;
        text-align: center;
        margin: 0 0.2rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .PageNavigation {
    padding: 1rem;
    .Selected {
      font-size: 0.7rem;
      gap: 0.3rem;

      span {
        padding: 0.3rem;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .PageNavigation {
    .Selected {
      font-size: 0.72rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
  .PageNavigation {
    .Selected {
      font-size: 0.78rem;

      span {
        padding: 0.35rem 0.53rem;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .PageNavigation {
    .Selected {
      font-size: 0.83rem;

      span {
        padding: 0.38rem 0.58rem;
      }
    }
  }
}

.MapStyleLayers {
  position: absolute;
  z-index: 4;
  right: 10px;
  top: 180px;

  .MapStyleLayer_ControlButton {
    padding: 0.33rem;
    background-color: #fff;
    border: 0 none;
    opacity: 0.85;
    margin-top: 0.6rem;
    border-radius: 0.3rem;
    box-shadow: 1px 1px 3px black;
    cursor: pointer;
    transition: opacity 0.3s linear;

    &:hover {
      opacity: 0.95;
    }

    svg {
      font-size: large;
    }
  }

  .ControlButton_LayerOptionsContainer {
    position: absolute;
    right: -400px;
    bottom: -105%;
    width: max-content;
    display: flex;
    padding: 0.4rem;
    background-color: rgba(255, 255, 255, 0.685);
    box-shadow: 1px 1px 3px black;
    backdrop-filter: blur(10px);
    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s linear;

    &.Show {
      right: 0;
      opacity: 1;
    }
  }
}

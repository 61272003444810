@import '../../../../sass/manifest';

.NavLink {
  @include FlexCenter;
  font-weight: 500;
  overflow: hidden;
  position: relative;
  width: max-content;
  cursor: pointer;
  padding: 0.3rem 0.9rem;
  width: 100%;
  margin-bottom: 0.5rem;

  border-radius: 0.6rem;
  transition: all 0.5s ease-in-out;

  .NavLink_IconContainer {
    @include FlexCenter;

    svg {
      color: $gray;
      font-size: large;
    }
  }

  .LinkBlock {
    text-decoration: none;

    color: $gray;
    transition: all 0.5s ease-in-out;
    text-shadow: none;
    padding: 0.8rem 0.6rem;
    font-size: 1.1rem;
    min-width: max-content;
    width: 100%;

    .LinkBlock_Underline {
      display: none;
    }
  }

  &:hover {
    color: #fff;

    svg,
    .LinkBlock {
      color: $gray;
      text-shadow: 0 0 3px #fff;
      .LinkBlock_Underline {
        left: 0%;
      }
    }
  }
}

@media (min-width: 920px) {
  .NavLink {
    padding: 0.3rem;
    transition: all 0.5s ease-in-out;
    margin-bottom: 0;

    .NavLink_IconContainer {
      svg {
        color: #fff;
      }
    }

    .LinkBlock {
      text-decoration: none;
      color: #fff;
      transition: all 0.5s ease-in-out;
      text-shadow: none;
      padding: 0 0.6rem;
      font-size: 0.9rem;
      min-width: 4.2rem;

      .LinkBlock_Underline {
        position: absolute;
        background-color: #fff;
        box-shadow: 0 0 20px #fff;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: -100%;
        transition: all 0.5s ease-in-out;
      }
    }

    &:hover {
      color: #fff;

      svg,
      .LinkBlock {
        color: #fff;
        text-shadow: 0 0 3px #fff;
        .LinkBlock_Underline {
          left: 0%;
        }
      }
    }
  }
}

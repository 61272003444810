@import '../../../../sass/variables.scss';
.IOSCheckBoxContainer {
  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label {
    position: relative;
    display: inline-block;
    width: 132px;
    height: 40px;
    background-color: lighten($v2maingreen, 10%);
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease-out;
    margin: 0;

    &::before {
      content: '';
      display: inline-block;
      width: 34px;
      height: 34px;
      border-radius: 100%;
      background-color: white;
      position: absolute;
      top: 3px;
      right: 4px;
      transition: all 0.3s ease-out;
      z-index: 0;
    }
    &::after {
      content: attr(data-deny);
      color: white;
      position: absolute;
      top: 9px;
      left: 14px;
      font-size: 19px;
      font-weight: 300;
      transition: all 0.3s ease-out;
      z-index: 0;
    }
  }

  input[type='checkbox']:checked + label {
    background-color: $lightGray;
  }

  input[type='checkbox']:checked + label::before {
    right: 94px;
  }

  input[type='checkbox']:checked + label::after {
    content: attr(data-permit);
    left: 54px;
  }
}

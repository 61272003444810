@import '../../../../../sass/manifest';

.SwimmingPoolImageModalContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.651);
  backdrop-filter: blur(4px);
  align-items: center;
  justify-content: center;

  .ModalContainer {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 80vh;
    height: 80dvh;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 10px black;
    padding: 0 0.95rem;

    .HeaderSection {
      display: flex;
      padding: 0.8rem;
      margin-bottom: 0.4rem;
      justify-content: flex-end;
      border-bottom: 1px solid $lightGray;

      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-size: 1.5rem;
        color: $v2maingreen;
      }
    }

    .ContentContainer {
      display: flex;
      width: 100%;
      overflow: hidden;
      flex-direction: column;
      align-items: center;

      .ImageContainer {
        display: flex;
        width: 100%;
        overflow: hidden;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex: 1;

        .Image {
          width: 100%;
          height: auto;
          border-radius: 10px;
          object-position: center;
          object-fit: cover;
        }
      }

      .DataContainer {
        display: flex;
        flex-direction: column;
        color: $v2maingreen;
        width: 100%;
        flex: 1;

        .DetectionDate {
          display: flex;
          padding: 0.5rem 0;
          font-weight: 600;
          gap: 0.3rem;
          border-bottom: 1px solid #ccc;
          margin-bottom: 0.5rem;
          width: 100%;
        }

        .Coordinates {
          display: grid;
          grid-template-columns: 5rem 0.5rem max-content;
          gap: 0.3rem;
          margin-bottom: 0.5rem;
          width: 100%;
        }

        .AddressSection {
          display: flex;
          flex-direction: column;
          gap: 0.2rem;

          p {
            font-weight: 600;
          }
        }
      }
    }

    .LoadingContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 50vh;
      min-height: 50dvh;
      width: 100%;
      color: $v2maingreen;

      p {
        text-align: center;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .SwimmingPoolImageModalContainer {
    .ModalContainer {
      width: 80%;

      .ContentContainer {
        flex-direction: row;
        align-items: unset;

        .ImageContainer {
          width: 100%;

          .Image {
            width: 95%;
          }
        }

        .DataContainer {
          align-items: unset;

          .AddressSection {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;

            p {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .SwimmingPoolImageModalContainer {
    .ModalContainer {
      .ContentContainer {
        .ImageContainer {
          .Image {
            width: auto;
            height: 98%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .SwimmingPoolImageModalContainer {
    .ModalContainer {
      .ContentContainer {
        .ImageContainer {
          .Image {
            width: 90%;
            height: 98%;
          }
        }
      }
    }
  }
}

@import '../../../../../../../sass/manifest';

.List {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 0.9rem 0.7rem;

  .HeadingSection {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $v2maingreen;
    justify-content: space-between;
    padding-bottom: 0.3rem;

    h3 {
      font-size: 1.03rem;
      color: $lightGray;
    }
    span {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      cursor: pointer;
      gap: 0.3rem;
      svg {
        color: $v2maingreen;
        font-size: 1.15rem;
        cursor: pointer;
      }
    }
  }

  &.RemoveSpace {
    padding-top: 0;

    p {
      font-size: 0.85rem;
      color: $lightGray;
    }
  }
}

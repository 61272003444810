@import '../../../sass/manifest';

.InfoBoxWrapper {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    border: 0 none;
    cursor: pointer;

    svg {
      font-size: 1.05rem;
      color: $v2maingreen;
    }
  }

  .InfoBox {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: #fff;
    z-index: 100;
    box-shadow: 2px 2px 5px rgb(69, 69, 69);
    width: max-content;
    border-radius: 15px;
    overflow: hidden;
    z-index: 1000;
    max-width: 100%;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .InfoBoxWrapper {
    button {
      svg {
        font-size: 1.05rem;
        color: $v2maingreen;
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .InfoBoxWrapper {
    .InfoBox {
      max-width: 90%;
    }
  }
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../sass/manifest';

.IncidentListItemContainer {
  display: flex;
  flex-direction: column;
  max-width: 21.33rem;
  width: 21.33rem;
  border-radius: 0.8rem;
  overflow: hidden;
  border: 1px solid $v2maingreen;
  text-decoration: none;
  box-shadow: 2px 2px 10px $v2maingreen;
  transition: all 0.2s linear;
  background-color: #fff;

  &:hover {
    box-shadow: 0 0 15px $v2maingreen;
    transform: scale(1.02);
  }

  &:active {
    box-shadow: 0 0 1px $v2maingreen;
    transform: scale(0.98);
  }

  .IncidentImageContainer {
    display: flex;
    width: inherit;
    max-height: 17rem;

    background-image: url('../../../../../assets/blur_image.png');
    background-size: cover;
    background-position: center;

    .IncidentImage {
      max-width: 100%;
      height: 17rem;
      width: 21.33rem;
      object-fit: cover;
      object-position: center;
      animation: skeleton-loading 1s infinite alternate;
    }
  }

  .DataMainContainer {
    display: flex;
    flex-direction: column;
    padding: 0.1rem 0.8rem;

    .TypeDateMainContainer {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
    }

    .IncidentDataContainer,
    .IncidentActionsContainer {
      padding: 0.75rem 0.35rem;
      display: flex;
    }

    .IncidentDataContainer {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .GarbageIconContainer {
        max-width: 4rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        div {
          // max-width: 80%;
          width: 3.2rem;
          height: 3.2rem;
          animation: skeleton-loading 1s infinite alternate;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
      }

      .TypeContent,
      .DetectionDateContainer {
        display: flex;
        align-items: center;
      }

      .TypeContent {
        div {
          width: 10rem;
          height: 1.3rem;
          animation: skeleton-loading 1s infinite alternate;
        }
      }

      .DetectionDateContainer {
        flex-direction: column;
        align-items: flex-start;
        div {
          width: 11rem;
          height: 0.8rem;
          animation: skeleton-loading 1s infinite alternate;
        }
      }
    }

    .Actions {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $v2maingreen;
      margin-top: 0.3rem;
      padding-top: 0.9rem;

      .Action {
        width: 30%;
        height: 1rem;
        animation: skeleton-loading 1s infinite alternate;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .IncidentListItemContainer {
    display: flex;
    flex-direction: column;
    max-width: 16.8rem;
    width: 16.8rem;
    border-radius: 0.8rem;
    overflow: hidden;
    border: 1px solid $v2maingreen;
    text-decoration: none;
    box-shadow: 2px 2px 10px $v2maingreen;
    transition: all 0.2s linear;
    background-color: #fff;

    .IncidentImageContainer {
      display: flex;
      width: inherit;
      max-height: 13rem;

      background-image: url('../../../../../assets/blur_image.png');
      background-size: cover;
      background-position: center;

      .IncidentImage {
        max-width: 100%;
        height: 13rem;
        width: 16.8rem;
        object-fit: cover;
        object-position: center;
        animation: skeleton-loading 1s infinite alternate;
      }
    }

    .DataMainContainer {
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;

      .TypeDateMainContainer {
        display: flex;
        flex-direction: column;
        gap: 0.12rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.55rem 0.2rem;
        display: flex;
      }

      .IncidentDataContainer {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .GarbageIconContainer {
          max-width: 3rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          div {
            width: 2.3rem;
            height: 2.3rem;
            animation: skeleton-loading 1s infinite alternate;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }

        .TypeContent,
        .DetectionDateContainer {
          display: flex;
          align-items: center;
        }

        .TypeContent {
          div {
            width: 10rem;
            height: 1.3rem;
            animation: skeleton-loading 1s infinite alternate;
          }
        }

        .DetectionDateContainer {
          flex-direction: column;
          align-items: flex-start;

          div {
            width: 11rem;
            height: 0.8rem;
            animation: skeleton-loading 1s infinite alternate;
          }
        }
      }

      .Actions {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $v2maingreen;
        margin-top: 0.1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.3rem;

        .Action {
          width: 30%;
          height: 1rem;
          animation: skeleton-loading 1s infinite alternate;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .IncidentListItemContainer {
    display: flex;
    flex-direction: column;
    max-width: 16rem;
    width: 16rem;
    border-radius: 0.8rem;
    overflow: hidden;
    border: 1px solid $v2maingreen;
    text-decoration: none;
    box-shadow: 2px 2px 10px $v2maingreen;
    transition: all 0.2s linear;
    background-color: #fff;

    .IncidentImageContainer {
      display: flex;
      width: inherit;
      max-height: 13rem;

      background-image: url('../../../../../assets/blur_image.png');
      background-size: cover;
      background-position: center;

      .IncidentImage {
        max-width: 100%;
        height: 13rem;
        width: 16rem;
        object-fit: cover;
        object-position: center;
        animation: skeleton-loading 1s infinite alternate;
      }
    }

    .DataMainContainer {
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;

      .TypeDateMainContainer {
        display: flex;
        flex-direction: column;
        gap: 0.12rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.55rem 0.2rem;
        display: flex;
      }

      .IncidentDataContainer {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .GarbageIconContainer {
          max-width: 3rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          div {
            width: 2.3rem;
            height: 2.3rem;
            animation: skeleton-loading 1s infinite alternate;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }

        .TypeContent,
        .DetectionDateContainer {
          display: flex;
          align-items: center;
        }

        .TypeContent {
          div {
            width: 10rem;
            height: 1.3rem;
            animation: skeleton-loading 1s infinite alternate;
          }
        }

        .DetectionDateContainer {
          flex-direction: column;
          align-items: flex-start;

          div {
            width: 11rem;
            height: 0.8rem;
            animation: skeleton-loading 1s infinite alternate;
          }
        }
      }

      .Actions {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $v2maingreen;
        margin-top: 0.1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.3rem;

        .Action {
          width: 30%;
          height: 1rem;
          animation: skeleton-loading 1s infinite alternate;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .IncidentListItemContainer {
    max-width: 15.5rem;
    width: 15.5rem;

    .IncidentImageContainer {
      max-height: 14rem;

      .IncidentImage {
        height: 14rem;
        width: 15.5rem;
      }
    }

    .DataMainContainer {
      .TypeDateMainContainer {
        gap: 0.2rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.5rem 0.2rem;
      }

      .IncidentDataContainer {
        .GarbageIconContainer {
          div {
            width: 2.2rem;
            height: 2.2rem;
          }
        }

        .TypeContent {
          div {
            width: 8rem;
            height: 1.3rem;
          }
        }

        .DetectionDateContainer {
          div {
            width: 10rem;
          }
        }
      }

      .Actions {
        margin-top: 0.3rem;
        padding-top: 0.9rem;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .IncidentListItemContainer {
    max-width: 16.5rem;
    width: 16.5rem;

    .IncidentImageContainer {
      max-height: 13rem;

      .IncidentImage {
        max-width: 100%;
        height: 13rem;
        width: 16.5rem;
      }
    }

    .DataMainContainer {
      .TypeDateMainContainer {
        gap: 0.1rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.5rem 0.2rem;
        display: flex;
      }

      .IncidentDataContainer {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .DetectionDateContainer {
          div {
            width: 10rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .IncidentListItemContainer {
    max-width: 19.3rem;
    width: 19.3rem;

    .IncidentImageContainer {
      max-height: 17rem;

      .IncidentImage {
        height: 16rem;
        width: 19.3rem;
      }
    }

    .DataMainContainer {
      .TypeDateMainContainer {
        gap: 0.3rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.65rem 0.25rem;
      }

      .IncidentDataContainer {
        .GarbageIconContainer {
          div {
            width: 2.8rem;
            height: 2.8rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1360px) {
  .IncidentListItemContainer {
    max-width: 17.5rem;
    width: 17.5rem;

    .IncidentImageContainer {
      max-height: 16rem;

      .IncidentImage {
        height: 15.4rem;
        width: 17.5rem;
      }
    }

    .DataMainContainer {
      padding: 0 0.5rem;

      .IncidentDataContainer {
        .GarbageIconContainer {
          max-width: 4rem;

          img {
            width: 2.5rem;
            height: 2.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .IncidentListItemContainer {
    max-width: 19rem;
    width: 19rem;
    .IncidentImageContainer {
      max-height: 17rem;

      .IncidentImage {
        width: 19rem;
      }
    }

    .DataMainContainer {
      padding: 0.1rem 0.8rem;

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.7rem 0.3rem;
      }

      .IncidentDataContainer {
        .GarbageIconContainer {
          max-width: 4rem;

          div {
            width: 3rem;
            height: 3rem;
          }
        }

        .TypeContent {
          div {
            width: 8rem;
            height: 1.3rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .IncidentListItemContainer {
    max-width: 21.33rem;
    width: 21.33rem;
    .IncidentImageContainer {
      max-height: 17rem;

      .IncidentImage {
        height: 17rem;
        width: 21.33rem;
      }
    }

    .DataMainContainer {
      padding: 0.45rem 0.8rem 0.25rem 0.8rem;

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.75rem 0.35rem;
      }

      .IncidentDataContainer {
        .GarbageIconContainer {
          max-width: 4rem;

          div {
            width: 3.2rem;
            height: 3.2rem;
          }
        }

        .TypeContent {
          div {
            width: 8rem;
            height: 1.3rem;
          }
        }
      }
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

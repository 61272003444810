@import '../../../../../../../../sass/manifest';

.TotalPaymentByPaymentType {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 0 0;
  color: $v2maingreen;

  h3 {
    font-size: 1.05rem;
  }
}

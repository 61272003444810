@import '../../../../../../../sass/manifest';

.CheckoutOrderDetailsContainer {
  display: flex;
  flex-direction: column;

  .ServiceHeadingContainer {
    display: flex;
    flex-direction: column;
    color: $v2maingreen;

    span {
      font-size: 0.8rem;
    }

    .SelectedPackage {
      display: flex;
      gap: 0.25rem;
      align-items: center;

      h3 {
        font-size: 0.9rem;
      }

      span {
        font-style: italic;
        font-size: 0.75rem;
      }
    }
  }

  .PricingPerServiceContent {
    display: flex;
    flex-direction: column;
    gap: 0.95rem;
    margin-top: 1rem;
    padding-bottom: 0.7rem;
    margin-bottom: 0.7rem;
    border-bottom: 1px solid $lightGray;

    &.removeBorder {
      border: 0 none;
      margin-bottom: 0;
      padding-bottom: 0;
      margin-top: 0;
      gap: 0.5rem;
    }
  }
}

@import '../../../../../../../sass/manifest';

.RegistrationForm {
  display: flex;
  gap: 0.65rem;
  height: 95%;
  justify-content: space-evenly;

  .DomainError {
    padding: 0.6rem;
    background-color: $danger;
    color: #fff;
    font-size: 0.95rem;
    line-height: 1.35;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
  }

  .DropdownsSection {
    display: flex;
    flex-direction: column;
    gap: 0.65rem;
  }

  .FormSubmitButtons {
    flex-direction: column;
    gap: 0.8rem;
    margin-top: 1.05rem;
    .ActionButton {
      flex: 1;
      max-width: 100%;
    }

    &.Continue {
      justify-content: flex-end;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .RegistrationForm {
    display: flex;
    gap: 0.65rem;
    height: 95%;
    justify-content: space-evenly;

    .DomainError {
      padding: 0.6rem;
      background-color: $danger;
      color: #fff;
      font-size: 0.95rem;
      line-height: 1.35;
      letter-spacing: 1px;
      margin-bottom: 0.5rem;
    }

    .DropdownsSection {
      display: flex;
      flex-direction: column;
      gap: 0.65rem;
    }

    .FormSubmitButtons {
      flex-direction: row;
      gap: 0.8rem;
      margin-top: 1.05rem;
      .ActionButton {
        flex: 1;
        max-width: 100%;
      }

      &.Continue {
        justify-content: flex-end;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

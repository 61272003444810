@import '../../../sass/manifest';

.UserAdminPageContainer {
  display: flex;
  flex-direction: column;
  max-width: 98%;
  margin: 0 auto 0 auto;
  padding: 0.8rem 0;
  width: 100%;

  .Tabs {
    display: flex;
    gap: 0.2rem;
    border-bottom: 8px solid $v2maingreen;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .UserAdminPageContainer {
    display: flex;
    flex-direction: column;
    max-width: 98%;
    margin: 0 auto 0 auto;
    padding: 0.8rem 0;
    width: 100%;

    .Tabs {
      display: flex;
      gap: 0.2rem;
      border-bottom: 8px solid $v2maingreen;
    }
  }
}

@media only screen and (min-width: 768px) {
  .UserAdminPageContainer {
    max-width: 96%;
  }
}

@media only screen and (min-width: 900px) {
  .UserAdminPageContainer {
    max-width: 94%;
  }
}

@media only screen and (min-width: 1200px) {
  .UserAdminPageContainer {
    max-width: 86%;
  }
}

@media only screen and (min-width: 1536px) {
  .UserAdminPageContainer {
    max-width: 80%;
  }
}

@media only screen and (min-width: 1920px) {
  .UserAdminPageContainer {
    max-width: 78%;
  }
}

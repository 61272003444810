@import '../../../../sass/manifest';

.Button {
  @include FlexCenter;
  position: relative;
  padding: 10px 15px;
  color: #fff;
  font-size: 0.8rem;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  // margin-top: 40px;
  letter-spacing: 0.15rem;
  cursor: pointer;
  background-color: #004c6c;
  border: 0 none;
  outline-color: $nav-bg-light-blue;
  z-index: 100;

  span {
    position: absolute;
    display: block;
  }

  &.Disabled {
    cursor: not-allowed;
    background-color: #8aa8b2;
    color: #f0f0f0;
    width: 100%;
  }

  &.Disabled:hover {
    background-color: #8aa8b2;
    box-shadow: none;
    color: #f0f0f0;
  }

  &:hover {
    background: #00628d;
    color: #fff;
    border-radius: 0.5em;
    box-shadow: 0 0 3px #00628d, 0 0 8px #00628d, 0 0 20px #03e9f4;
  }

  i {
    margin-right: 0.4em;
    margin-top: 2px;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .Button {
    font-size: 0.8rem;

    letter-spacing: 0.15rem;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .Button {
    padding: 10px 15px;
    font-size: 1rem;
    letter-spacing: 0.15rem;
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../../../../sass/manifest';

.DirectionButton {
  position: absolute;
  border: 0 none;
  background-color: transparent;
  z-index: 100;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  transition: all 0.25s linear;
  display: none;

  &.primary {
    &:hover {
      transform: translate(0, -50%) scale(1.15);

      svg {
        color: lighten($v2maingreen, 10%);
      }
    }

    svg {
      color: $v2maingreen;
      font-size: 2rem;
    }
  }

  &.orange {
    &:hover {
      transform: translate(0, -50%) scale(1.15);

      svg {
        color: lighten($periopsisOrange, 10%);
      }
    }

    svg {
      color: $periopsisOrange;
      font-size: 2rem;
    }
  }

  &.Left {
    left: 0.5rem;
  }

  &.Right {
    right: 0.5rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .DirectionButton {
    position: absolute;
    border: 0 none;
    background-color: transparent;
    z-index: 100;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    transition: all 0.25s linear;
    display: block;

    &.primary {
      &:hover {
        transform: translate(0, -50%) scale(1.15);

        svg {
          color: lighten($v2maingreen, 10%);
        }
      }

      svg {
        color: $v2maingreen;
        font-size: 2rem;
      }
    }

    &.orange {
      &:hover {
        transform: translate(0, -50%) scale(1.15);

        svg {
          color: lighten($periopsisOrange, 10%);
        }
      }

      svg {
        color: $periopsisOrange;
        font-size: 2rem;
      }
    }

    &.Left {
      left: 0.5rem;
    }

    &.Right {
      right: 0.5rem;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

@import '../../../sass/variables.scss';
@import '../../../sass/mixins.scss';

.DataContainer {
  @include columnDataContainer(99%);

  .ContentWrapper {
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
  .DataContainer {
    @include columnDataContainer(94%);

    &.Narrow {
      @include columnDataContainer(80%);
    }

    .ContentWrapper {
      padding: 0.65rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .DataContainer {
    @include columnDataContainer(92%);

    &.Narrow {
      @include columnDataContainer(75%);
    }

    .ContentWrapper {
      padding: 0.65rem;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .DataContainer {
    &.Narrow {
      @include columnDataContainer(70%);
    }
  }
}

@media only screen and (min-width: 1920px) {
  .DataContainer {
    &.Narrow {
      @include columnDataContainer(65%);
    }
  }
}

@import '../../../../sass/manifest';

.ListItemWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid transparent;

  &.Selected {
    border: 1px solid $v2maingreen;
  }

  .NotificationListItemContainer {
    display: flex;
    padding: 0.48rem 0.5rem;
    width: 100%;
    background-color: #fff;
    border: 0 none;
    cursor: pointer;
    transition: all 0.25s linear;
    border-radius: 3px;
    outline: 0 none;

    &:hover {
      box-shadow: 0;
    }

    &:focus {
      outline: 0 none;
    }

    .NotificationContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: inherit;

      .MainContent {
        display: flex;
        align-items: center;
        gap: 0.9rem;

        .Envelope {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.8rem;
          height: 1.8rem;
          background-color: $notification;
          border-radius: 50%;

          &.Seen {
            background-color: lighten($lightGray, 5%);
          }

          svg {
            font-size: 0.85rem;
            color: #fff;
          }
        }

        .Subject {
          display: flex;
          flex-direction: column;
          gap: 0.1rem;
          align-items: flex-start;
          color: $v2maingreen;
          font-size: 0.68rem;
        }
      }

      .NotificationDot {
        width: 0.6rem;
        height: 0.6rem;
        background-color: darken($notification, 15%);
        border-radius: 50%;

        &.Seen {
          background-color: lighten($lightGray, 5%);
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .ListItemWrapper {
    .NotificationListItemContainer {
      display: flex;
      padding: 0.48rem 0.5rem;
      width: 100%;
      background-color: #fff;
      border: 0 none;
      cursor: pointer;
      transition: all 0.25s linear;
      border-radius: 3px;

      &:hover {
        box-shadow: 0;
      }

      &:focus {
        outline: 0 none;
      }

      .NotificationContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: inherit;

        .MainContent {
          display: flex;
          align-items: center;
          gap: 0.9rem;

          .Envelope {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.8rem;
            height: 1.8rem;
            background-color: $notification;
            border-radius: 50%;

            &.Seen {
              background-color: lighten($lightGray, 5%);
            }

            svg {
              font-size: 0.85rem;
              color: #fff;
            }
          }

          .Subject {
            display: flex;
            flex-direction: column;
            gap: 0.1rem;
            align-items: flex-start;
            color: $v2maingreen;
            font-size: 0.68rem;
          }
        }

        .NotificationDot {
          width: 0.6rem;
          height: 0.6rem;
          background-color: darken($notification, 15%);
          border-radius: 50%;

          &.Seen {
            background-color: lighten($lightGray, 5%);
          }
        }
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .ListItemWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    border: 0 none;

    &.Selected {
      border: 0 none;
    }

    .NotificationListItemContainer {
      display: flex;
      padding: 0.6rem 0.8rem;
      width: 100%;
      background-color: #fff;
      border: 0 none;
      cursor: pointer;
      transition: all 0.25s linear;
      border-radius: 3px;
      outline: initial;

      &:hover {
        box-shadow: 0 0 10px $v2maingreen;
      }

      &:focus {
        outline: 2px solid $v2maingreen;
      }

      .NotificationContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: inherit;

        .MainContent {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .Envelope {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            background-color: $notification;
            border-radius: 50%;

            &.Seen {
              background-color: lighten($lightGray, 5%);
            }

            svg {
              font-size: 1rem;
              color: #fff;
            }
          }

          .Subject {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            align-items: flex-start;
            color: $v2maingreen;
            font-size: 0.65rem;
          }
        }

        .NotificationDot {
          width: 0.8rem;
          height: 0.8rem;
          background-color: darken($notification, 15%);
          border-radius: 50%;

          &.Seen {
            background-color: lighten($lightGray, 5%);
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .ListItemWrapper {
    .NotificationListItemContainer {
      padding: 0.68rem 0.88rem;

      .NotificationContent {
        .MainContent {
          gap: 0.8rem;

          .Envelope {
            width: 2.3rem;
            height: 2.3rem;

            svg {
              font-size: 1.1rem;
            }
          }

          .Subject {
            gap: 0.25rem;
            font-size: 0.72rem;
          }
        }

        .NotificationDot {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .ListItemWrapper {
    .NotificationListItemContainer {
      padding: 0.89rem 1.08rem;

      .NotificationContent {
        .MainContent {
          gap: 0.9rem;

          .Envelope {
            width: 3rem;
            height: 3rem;

            svg {
              font-size: 1.8rem;
            }
          }

          .Subject {
            gap: 0.3rem;

            font-size: 0.85rem;
          }
        }

        .NotificationDot {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .ListItemWrapper {
    .NotificationListItemContainer {
      padding: 0.89rem 1.08rem;

      .NotificationContent {
        .MainContent {
          gap: 0.9rem;

          .Envelope {
            width: 3rem;
            height: 3rem;

            svg {
              font-size: 1.8rem;
            }
          }

          .Subject {
            gap: 0.3rem;

            font-size: 0.85rem;
          }
        }

        .NotificationDot {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .ListItemWrapper {
    .NotificationListItemContainer {
      .NotificationContent {
        .MainContent {
          .Subject {
            font-size: 0.88rem;
          }
        }
      }
    }
  }
}

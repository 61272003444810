.PackageTypeSection {
  margin-bottom: 0.9rem;
  font-size: 0.75rem;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid greenyellow;
  display: flex;
  width: 100%;
  justify-content: space-between;

  h3 {
    font-weight: 600;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0 none;
    font-size: 1.05rem;
    color: #fff;
    cursor: pointer;
  }
}

@import '../../../sass/variables.scss';

.Ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;

  color: $v2maingreen;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px $v2maingreen;
  z-index: 150000;
  animation: animateBoxShadow 1.4s linear infinite;

  &:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 6px solid transparent;
    border-top: 6px solid $v2maingreen;
    border-right: 6px solid $v2maingreen;
    border-radius: 50%;
    animation: animate 1.4s linear infinite;
  }

  .Text {
    font-size: 20px;
    animation: animateText 1.4s linear infinite;
  }
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate {
  0% {
    transform: rotate(0deg);
    border-top: 5px solid $v2maingreen;
    border-right: 5px solid $v2maingreen;
  }
  50% {
    transform: rotate(180deg);
    border-top: 5px solid $nav-bg-light-blue;
    border-right: 5px solid $nav-bg-light-blue;
  }

  100% {
    transform: rotate(360deg);
    border-top: 5px solid $v2maingreen;
    border-right: 5px solid $v2maingreen;
  }
}

@keyframes animateText {
  0% {
    opacity: 0;
    color: $v2maingreen;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    color: $nav-bg-light-blue;
    transform: scale(1.2);
  }

  100% {
    opacity: 0;
    color: $v2maingreen;
    transform: scale(0.3);
  }
}

@keyframes animateBoxShadow {
  0% {
    box-shadow: 0 0 0 $nav-bg-light-blue;
  }
  50% {
    box-shadow: 0 0 25px $v2maingreen;
  }

  100% {
    box-shadow: 0 0 1px $nav-bg-light-blue;
  }
}

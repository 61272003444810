@import '../../../../../sass/manifest';

.IncidentListItemContainer {
  display: flex;
  flex-direction: column;
  border-radius: 0;
  overflow: hidden;
  border: 0 none;
  text-decoration: none;
  box-shadow: none;
  transition: all 0.2s linear;
  background-color: #fff;
  position: relative;
  width: 100%;
  margin: 0;
  max-width: 98vw;
  max-width: 98dvw;

  &:hover {
    box-shadow: none;
    transform: scale(1);
  }

  &:active {
    box-shadow: none;
    transform: scale(1);
  }

  .CleanDetectedBySystem {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: lighten($periopsisOrange, 20%);
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    transform: rotate(90deg);

    svg {
      font-size: 1.35rem;
    }
  }

  .IncidentImageContainer {
    display: flex;
    width: inherit;
    max-height: 62vh;
    max-height: 62dvh;

    background-image: url('../../../../../assets/blur_image.png');
    background-size: cover;
    background-position: center;

    .IncidentImage {
      height: 62vh;
      height: 62dvh;
      width: 100%;
      object-fit: cover;
      object-position: center;
      animation: skeleton-loading 1s infinite alternate;
    }

    .ImageLoader {
      max-height: 62vh;
      max-height: 62dvh;
      max-width: max-content;
      overflow: hidden;
      background-color: rgba(0, 76, 108, 0.45);
    }
  }

  .DataMainContainer {
    display: flex;
    flex-direction: column;
    padding: 0.2rem 0.4rem;

    .TypeDateMainContainer {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
    }

    .IncidentDataContainer,
    .IncidentActionsContainer {
      padding: 0.7rem 0.3rem;
      display: flex;
    }

    .IncidentDataContainer {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .GarbageIconContainer {
        max-width: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          width: 3rem;
          height: 3rem;
          animation: skeleton-loading 1s infinite alternate;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
      }

      .TypeContent,
      .DetectionDateContainer {
        display: flex;
        color: $v2maingreen;
        width: 100%;
        align-items: center;
      }

      .DetectionDateContainer {
        flex-direction: column;
        align-items: flex-start;
        font-size: 0.9rem;
        font-weight: 500;
      }

      .TypeContent {
        text-transform: uppercase;
        font-size: 0.8rem;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .IncidentListItemContainer {
    flex-direction: column;
    max-width: 16.8rem;
    width: 16.8rem;
    min-width: unset;
    border-radius: 0.8rem;
    border: 1px solid $v2maingreen;
    box-shadow: 2px 2px 10px $v2maingreen;

    &:hover {
      box-shadow: 0 0 10px $v2maingreen;
      transform: scale(1.01);
    }

    &:active {
      box-shadow: 0 0 1px $v2maingreen;
      transform: scale(0.98);
    }

    .CleanDetectedBySystem {
      display: flex;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 35px;
      height: 35px;
      background-color: $periopsisOrange;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 50%;
      transform: rotate(90deg);

      svg {
        font-size: 1.35rem;
      }
    }

    .IncidentImageContainer {
      display: flex;
      width: inherit;
      max-height: 13rem;

      background-image: url('../../../../../assets/blur_image.png');
      background-size: cover;
      background-position: center;

      .IncidentImage {
        max-width: 100%;
        height: 13rem;
        width: 16.8rem;
        object-fit: cover;
        object-position: center;
        animation: skeleton-loading 1s infinite alternate;
      }

      .ImageLoader {
        max-width: 100%;
        height: 13rem;
        width: 16.8rem;
      }
    }

    .DataMainContainer {
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;

      .TypeDateMainContainer {
        display: flex;
        flex-direction: column;
        gap: 0rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.55rem 0.2rem;
        display: flex;
      }

      .IncidentDataContainer {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .GarbageIconContainer {
          max-width: 3rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          img {
            width: 2.3rem;
            height: 2.3rem;
            animation: skeleton-loading 1s infinite alternate;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }

        .TypeContent,
        .DetectionDateContainer {
          display: flex;
          color: $v2maingreen;
          width: 100%;
          align-items: center;
        }

        .DetectionDateContainer {
          flex-direction: column;
          align-items: flex-start;
          font-size: 0.75rem;
          font-weight: 500;
        }

        .TypeContent {
          text-transform: uppercase;
          font-size: 0.68rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .IncidentListItemContainer {
    flex-direction: column;
    max-width: 16rem;
    width: 16rem;
    min-width: unset;

    &:hover {
      box-shadow: 0 0 10px $v2maingreen;
      transform: scale(1.01);
    }

    &:active {
      box-shadow: 0 0 1px $v2maingreen;
      transform: scale(0.98);
    }

    .CleanDetectedBySystem {
      display: flex;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 35px;
      height: 35px;
      background-color: $periopsisOrange;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 50%;
      transform: rotate(90deg);

      svg {
        font-size: 1.35rem;
      }
    }

    .IncidentImageContainer {
      display: flex;
      width: inherit;
      max-height: 13rem;

      background-image: url('../../../../../assets/blur_image.png');
      background-size: cover;
      background-position: center;

      .IncidentImage {
        max-width: 100%;
        height: 13rem;
        width: 16rem;
        object-fit: cover;
        object-position: center;
        animation: skeleton-loading 1s infinite alternate;
      }

      .ImageLoader {
        max-width: 100%;
        height: 13rem;
        width: 16rem;
      }
    }

    .DataMainContainer {
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;

      .TypeDateMainContainer {
        display: flex;
        flex-direction: column;
        gap: 0rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.55rem 0.2rem;
        display: flex;
      }

      .IncidentDataContainer {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .GarbageIconContainer {
          max-width: 3rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          img {
            width: 2.3rem;
            height: 2.3rem;
            animation: skeleton-loading 1s infinite alternate;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }

        .TypeContent,
        .DetectionDateContainer {
          display: flex;
          color: $v2maingreen;
          width: 100%;
          align-items: center;
        }

        .DetectionDateContainer {
          flex-direction: column;
          align-items: flex-start;
          font-size: 0.75rem;
          font-weight: 500;
        }

        .TypeContent {
          text-transform: uppercase;
          font-size: 0.68rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .IncidentListItemContainer {
    max-width: 15.5rem;
    width: 15.5rem;

    .IncidentImageContainer {
      max-height: 14rem;

      .IncidentImage {
        max-width: 100%;
        height: 14rem;
        width: 15.5rem;
      }
    }

    .DataMainContainer {
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;

      .TypeDateMainContainer {
        display: flex;
        flex-direction: column;
        gap: 0rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.5rem 0.2rem;
        display: flex;
      }

      .IncidentDataContainer {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .GarbageIconContainer {
          max-width: 3rem;

          img {
            width: 2.2rem;
            height: 2.2rem;
          }
        }

        .DetectionDateContainer {
          font-size: 0.8rem;
          font-weight: 500;
        }

        .TypeContent {
          font-size: 0.65rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .IncidentListItemContainer {
    max-width: 16.5rem;
    width: 16.5rem;

    .IncidentImageContainer {
      max-height: 13rem;

      .IncidentImage {
        max-width: 100%;
        height: 13rem;
        width: 16.5rem;
      }

      .ImageLoader {
        max-width: 100%;
        height: 13rem;
        width: 16.5rem;
      }
    }

    .DataMainContainer {
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;

      .TypeDateMainContainer {
        display: flex;
        flex-direction: column;
        gap: 0rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.5rem 0.2rem;
        display: flex;
      }

      .IncidentDataContainer {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .GarbageIconContainer {
          max-width: 4rem;

          img {
            width: 2.5rem;
            height: 2.5rem;
          }
        }

        .DetectionDateContainer {
          font-size: 0.8rem;
          font-weight: 500;
        }

        .TypeContent {
          font-size: 0.7rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .IncidentListItemContainer {
    max-width: 19.3rem;
    width: 19.3rem;

    .IncidentImageContainer {
      max-height: 17rem;

      .IncidentImage {
        height: 16rem;
        width: 19.3rem;
      }
    }

    .DataMainContainer {
      padding: 0 0.5rem;

      .TypeDateMainContainer {
        gap: 0rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.65rem 0.25rem;
      }

      .IncidentDataContainer {
        .GarbageIconContainer {
          max-width: 4rem;

          img {
            width: 2.8rem;
            height: 2.8rem;
          }
        }

        .DetectionDateContainer {
          font-size: 0.85rem;
          font-weight: 500;
        }

        .TypeContent {
          font-size: 0.75rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1360px) {
  .IncidentListItemContainer {
    max-width: 17.5rem;
    width: 17.5rem;

    .IncidentImageContainer {
      max-height: 16rem;

      .IncidentImage {
        height: 15.4rem;
        width: 17.5rem;
      }
    }

    .DataMainContainer {
      padding: 0 0.5rem;

      .TypeDateMainContainer {
        gap: 0rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.65rem 0.25rem;
      }

      .IncidentDataContainer {
        .GarbageIconContainer {
          max-width: 4rem;

          img {
            width: 2.5rem;
            height: 2.5rem;
          }
        }

        .DetectionDateContainer {
          font-size: 0.85rem;
          font-weight: 500;
        }

        .TypeContent {
          font-size: 0.75rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .IncidentListItemContainer {
    max-width: 19rem;
    width: 19rem;

    &:hover {
      box-shadow: 0 0 15px $v2maingreen;
      transform: scale(1.02);
    }

    .IncidentImageContainer {
      max-height: 16rem;

      .IncidentImage {
        height: 16rem;
        width: 19rem;
      }

      .ImageLoader {
        max-width: 100%;
        height: 16rem;
        width: 19rem;
      }
    }

    .DataMainContainer {
      padding: 0.1rem 0.8rem;

      .TypeDateMainContainer {
        gap: 0.1rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.7rem 0.3rem;
      }

      .IncidentDataContainer {
        .GarbageIconContainer {
          max-width: 4rem;

          img {
            width: 3rem;
            height: 3rem;
          }
        }

        .DetectionDateContainer {
          font-size: 0.88rem;
        }

        .TypeContent {
          font-size: 0.75rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .IncidentListItemContainer {
    max-width: 21.33rem;
    width: 21.33rem;

    .CleanDetectedBySystem {
      top: 10px;
      right: 10px;
      width: 35px;
      height: 35px;
    }

    .IncidentImageContainer {
      max-height: 17rem;
      .IncidentImage {
        height: 17rem;
        width: 21.33rem;
      }

      .ImageLoader {
        max-width: 100%;
        height: 17rem;
        width: 21.33rem;
      }
    }

    .DataMainContainer {
      padding: 0.45rem 0.8rem 0.25rem 0.8rem;
      .TypeDateMainContainer {
        gap: 0.1rem;
      }

      .IncidentDataContainer,
      .IncidentActionsContainer {
        padding: 0.75rem 0.35rem;
      }

      .IncidentDataContainer {
        .GarbageIconContainer {
          max-width: 4rem;

          img {
            width: 3.2rem;
            height: 3.2rem;
          }
        }

        .DetectionDateContainer {
          font-size: 0.9rem;
          font-weight: 500;
        }

        .TypeContent {
          font-size: 0.75rem;
        }
      }
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

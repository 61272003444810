@import '../../../sass/manifest';

.CodeInputContainer {
  display: flex;
  width: 42px;
  height: 42px;
  max-width: 42px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid $lightGray;
  outline: 0 none;
  transition: all 0.3s linear;
  align-items: center;
  justify-content: center;

  &:focus-within {
    border: 1px solid $v2maingreen;
  }

  input {
    width: inherit;
    border: 0 none;
    background-color: transparent;
    outline: 0 none;
    padding: 0.4rem;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.25rem;
    color: $v2maingreen;
  }
}

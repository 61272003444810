@import '../../../../../../../sass/manifest';

.AnswerItem {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  font-size: 0.92rem;
  cursor: pointer;
  color: $v2maingreen;

  &.Bold {
    font-weight: 600;

    &.Disabled {
      color: $v2maingreen;
      cursor: pointer;
    }
  }

  &.Disabled {
    color: lighten($lightGray, 15%);
    cursor: not-allowed;
  }

  .OptionInput {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 20px;
    transition: all 0.15s ease-out 0s;
    background: $lightGray;
    box-shadow: inset 1px 1px 3px rgb(109, 109, 109);
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    outline: none;
    position: relative;
    z-index: 1000;
    border-radius: 50%;

    &:disabled,
    &:disabled:hover {
      box-shadow: none;
      background: lishten($lightGray, 10%);
    }

    &:hover {
      background: darken($lightGray, 10%);
    }
    &:checked,
    &:checked:hover {
      background: $v2maingreen;
    }

    &:checked::before {
      width: 20px;
      height: 20px;
      display: flex;
      content: '\26AA';
      font-size: 10px;
      font-weight: bold;
      position: absolute;
      align-items: center;
      justify-content: center;
    }

    &:checked::after {
      -webkit-animation: click-wave 0.65s;
      -moz-animation: click-wave 0.65s;
      animation: click-wave 0.65s;
      background: $v2maingreen;
      content: '';
      display: block;
      position: relative;
      z-index: 100;
      border-radius: 50%;
    }
  }
}

@keyframes click-wave {
  0% {
    height: 20px;
    width: 20px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 100px;
    width: 100px;
    margin-left: -40px;
    margin-top: -40px;
    opacity: 0;
  }
}

@import '../../../../sass/manifest';

.NotificationItemContainer {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  text-decoration: none;
  align-items: center;
  padding: 0.35rem 0.65rem;
  color: $v2maingreen;

  .Content {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.9rem;
    padding: 0.6rem 0.8rem;
    border-bottom: 1px solid $v2maingreen;
    transition: background-color 0.25s linear;

    &:hover {
      background-color: lighten($lightGray, 25%);
    }
  }

  .Icon {
    width: 3.5rem;
    height: 3.5rem;
  }
}

.NotificationProcess {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  min-width: 20rem;
  padding: 1.9rem 1.1rem;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  color: $v2maingreen;

  p {
    font-size: 1.08rem;
  }

  svg {
    font-size: 2.4rem;
    color: $periopsisOrange;
  }
}

@import '../../../../../sass/manifest';

.DocumentContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
  gap: 0.4rem;
  border: 0 none;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &.Proforma,
  &.Invoice,
  &.Receipt {
    border-left: 4px solid black;
  }

  &.Proforma {
    border-color: lighten($periopsisOrange, 20%);
  }
  &.Invoice {
    border-color: lighten($periopsisOrange, 2%);
  }
  &.Receipt {
    border-color: $v2maingreen;
  }

  .ServiceType {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0;
    border-bottom: 1px solid $periopsisOrange;
    width: 100%;

    p {
      font-size: 0.8rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    span {
      font-size: 0.73rem;
    }
  }

  .DocumentId,
  .ProformaInvoiceDoc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    p {
      font-size: 0.72rem;
    }
  }

  .BillingPeriod {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Periods {
      display: flex;
      gap: 0.15rem;
      p {
        font-size: 0.8rem;
      }
    }
  }

  .PaymentData {
    display: flex;
    font-size: 0.75rem;
    padding-top: 0.2rem;
    margin-top: 0.35rem;
    border-top: 1px solid $periopsisOrange;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .Overall {
      display: flex;
      gap: 0.5rem;
    }
  }

  .ClickMessage {
    display: flex;
    position: absolute;
    max-width: 2rem;
    height: 100%;
    // left: -0.5rem;
    left: -2.1rem;
    top: 0;
    background-color: $periopsisOrange;
    writing-mode: vertical-lr;
    align-items: center;
    justify-content: center;
    padding: 0 0.3rem;
    font-weight: 600;
    color: #fff;
    letter-spacing: 1.1px;
    transition: all 0.3s linear;
    z-index: 9999;

    &.Visible {
      left: -0.35rem;
    }

    &.Proforma {
      background-color: lighten($periopsisOrange, 20%);
    }
    &.Invoice {
      background-color: lighten($periopsisOrange, 2%);
    }
    &.Receipt {
      background-color: $v2maingreen;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .DocumentContainer {
    display: flex;
    flex-direction: column;
    padding: 1.35rem;
    background-color: #fff;
    gap: 0.4rem;
    border: 0 none;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &.Proforma,
    &.Invoice,
    &.Receipt {
      border-left: 8px solid black;
    }

    &.Proforma {
      border-color: lighten($periopsisOrange, 20%);
    }
    &.Invoice {
      border-color: lighten($periopsisOrange, 2%);
    }
    &.Receipt {
      border-color: $v2maingreen;
    }

    .ServiceType {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.3rem 0;
      border-bottom: 1px solid $periopsisOrange;
      width: 100%;

      p {
        font-size: 1.05rem;
        font-weight: 600;
        text-transform: uppercase;
      }
      span {
        font-size: 0.9rem;
      }
    }

    .DocumentId,
    .ProformaInvoiceDoc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      p {
        font-size: 0.9rem;
      }
    }

    .BillingPeriod {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .Periods {
        display: flex;
        gap: 0.15rem;
        p {
          font-size: 0.9rem;
        }
      }
    }

    .PaymentData {
      display: flex;
      font-size: 0.85rem;
      padding-top: 0.3rem;
      margin-top: 0.45rem;
      border-top: 1px solid $periopsisOrange;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .Overall {
        display: flex;
        gap: 0.5rem;
      }
    }

    .ClickMessage {
      display: flex;
      position: absolute;
      max-width: 2rem;
      height: 100%;
      // left: -0.5rem;
      left: -2.1rem;
      top: 0;
      background-color: $periopsisOrange;
      writing-mode: vertical-lr;
      align-items: center;
      justify-content: center;
      padding: 0 0.3rem;
      font-weight: 600;
      color: #fff;
      letter-spacing: 1.1px;
      transition: all 0.3s linear;
      z-index: 9999;

      &.Visible {
        left: -0.35rem;
      }

      &.Proforma {
        background-color: lighten($periopsisOrange, 20%);
      }
      &.Invoice {
        background-color: lighten($periopsisOrange, 2%);
      }
      &.Receipt {
        background-color: $v2maingreen;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

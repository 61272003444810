@import '../../../../sass/manifest';

.PhoneCodesContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 3rem;

  .OptionSelect {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: transparent;
    border: 0 none;
    border-bottom: 1px solid $lightGray;
    cursor: pointer;
    color: darken($gray, 5);
    padding-bottom: 0.38rem;

    &:focus {
      background-color: $v2maingreen;
      color: #fff;
    }

    &.Disabled:focus {
      background-color: transparent;
      color: darken($gray, 5);
      cursor: progress;
    }

    &::after {
      content: '\25bc';
      padding-left: 0.25rem;
      font-size: 0.8rem;
    }
  }

  .Options {
    display: flex;
    flex-direction: column;
    height: 0rem;
    overflow-y: hidden;
    position: absolute;
    top: 100%;
    width: max-content;
    z-index: -1;
    box-shadow: 2px 5px 10px black;
    scrollbar-width: thin;
    background-color: #fff;
    transition: all 0.24s linear;

    &.Show {
      height: 10rem;
      z-index: 9900;
    }

    .CountryList {
      height: inherit;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: $lightGray;
      box-shadow: inset 1px 1px 3px black;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $v2maingreen;
    }

    .NoCountry {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.45rem 0.3rem;
      width: 100%;
      gap: 0.4rem;

      svg {
        color: $danger;
        font-size: 0.85rem;
      }
    }

    .OptionWrapper {
      display: flex;
      width: 100%;
      background-color: #fff;
      transition: all 0.24s linear;

      &.Selected {
        background-color: $v2maingreen;
        .Option {
          color: #fff;
          border-bottom: 1px solid transparent;
        }
      }

      .Option {
        background-color: transparent;
        border: 0 none;
        display: grid;
        grid-template-columns: 15px 25px 1fr;
        padding: 0.45rem 0.3rem;
        border-bottom: 1px solid $lightGray;
        gap: 1rem;
        justify-items: flex-start;
        cursor: pointer;
        width: 100%;
        color: $v2maingreen;
      }

      &:hover {
        background-color: $v2maingreen;

        .Option {
          color: #fff;
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}

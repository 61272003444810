@import '../../../sass/manifest';

.MainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.CaptchaContainer {
  display: flex;
  max-height: max-content;
  max-width: max-content;
  margin: 0 auto;
  opacity: 0;
  height: 0;
  transition: all 0.35s ease-in-out;

  &.Show {
    opacity: 1;
    height: 4.2rem;
    margin-top: 0.3rem;
  }
}

iframe {
  min-height: 5rem !important;
  margin-top: 0;
  max-width: inherit;
}

.LoadingRecaptcha {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-weight: 600;
  font-size: 1.05rem;
  color: $v2maingreen;
  animation: recaptchaloading 0.6s linear infinite alternate;
}

@keyframes recaptchaloading {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

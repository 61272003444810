@import '../../../../../../../../sass/manifest';

.OrganizationDataContainer {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  h3 {
    font-size: 0.95rem;
  }

  .UpdateActions {
    justify-content: flex-end;
  }

  .AddressSection {
    display: flex;
    flex-direction: column;
    padding: 0 0.3rem;
    margin-top: 0.4rem;
    color: $lightGray;
    font-size: 0.8rem;

    .HeadingSection {
      display: flex;
      margin-top: 0.4rem;
    }

    .addressDataConatiner {
      display: flex;
      flex-wrap: wrap;
      padding: 0.5rem 0;
      gap: 0.55rem;
    }

    .AddressDetailsSection {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin: 0.6rem 0;
    }
  }
  .AreasOfInterestSection {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.8rem;
    padding: 0 0.3rem;
    color: $lightGray;
    position: relative;
    height: fit-content;

    .DataSection {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
    }

    .AreaOfInterestCardActions {
      width: 100%;
      justify-content: space-between;
      flex-direction: column;
      gap: 0.4rem;

      .Navigation {
        min-width: 11rem;
        width: 100%;
      }
    }

    .AreaOfInterestList {
      display: flex;
      max-width: 100%;
      width: inherit;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 8px;
      padding: 0.6rem 0.1rem;
      scrollbar-width: none;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .AreaOfInterestItem {
        width: inherit;
        min-width: 100%;
        scroll-snap-align: center;
      }
    }

    .HeadingSection {
      display: flex;
      justify-content: space-between;

      .TotalArea {
        display: flex;
        gap: 0.4rem;
        font-size: 0.82rem;
      }
    }

    .Area {
      padding: 0.5rem;
      border: 1px solid $v2maingreen;
      border-radius: 10px;
      box-shadow: 1px 1px 3px black;

      .AreaNumber {
        display: flex;
        border-bottom: 1px solid $v2maingreen;
        margin-bottom: 0.3rem;

        span {
          padding: 0.6rem;
          font-weight: 600;
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .OrganizationDataContainer {
    .AreasOfInterestSection {
      .AreaOfInterestCardActions {
        width: 100%;
        justify-content: space-between;
        flex-direction: row;

        .Navigation {
          min-width: 11rem;
          width: max-content;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

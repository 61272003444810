@import '../../../../../sass/manifest';

.LoginForm {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  justify-content: center;

  .LoginActionButton {
    width: 100%;
    align-items: center;
    justify-content: center;

    button {
      width: 100%;
    }

    .ResendVerificationEmailContainer {
      display: flex;
      flex-direction: column;

      p {
        font-size: 0.8rem;
        text-align: justify;
        hyphens: auto;
        padding: 0 0 0.6rem 0;
        color: $v2maingreen;
        line-height: 1.5;
      }

      .ResendActions {
        gap: 1.5rem;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .LoginForm {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    justify-content: center;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  .LoginForm {
    gap: 1.95rem;
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .LoginForm {
    gap: 2rem;
    .LoginActionButton {
      .ResendVerificationEmailContainer {
        p {
          font-size: 0.92rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1536px) {
  .LoginForm {
    gap: 2.35rem;
  }
}

@media only screen and (min-width: 1920px) {
}

.TablesList {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;

  .TableOptions {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0 1.5rem 0;
    align-items: center;
    justify-content: center;
    gap: 0.9rem;
  }
}

.StatisticsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.4rem;

  .ChartSection {
    display: flex;
    flex: 1;
    height: 75vh;
    height: 75dvh;
    align-items: center;
    justify-content: center;

    canvas {
      width: 19rem !important;
      height: 19rem !important;
      cursor: pointer;
    }
  }

  .DataTableSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    margin-top: 2rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .StatisticsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
    gap: 0.9rem;

    .ChartSection {
      display: flex;
      flex: 1;
      height: 70vh;
      height: 70dvh;
      align-items: center;
      justify-content: center;

      canvas {
        width: 28rem !important;
        height: 28rem !important;
        cursor: pointer;
      }

      &.Fullsceen {
        canvas {
          width: 40rem !important;
          height: 40rem !important;
          cursor: pointer;
        }
      }
    }

    .DataTableSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      width: 100%;
      margin-top: unset;
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 800px) {
  .StatisticsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
    gap: 0.9rem;

    .ChartSection {
      display: flex;
      flex: 1;
      height: 70vh;
      height: 70dvh;
      align-items: center;
      justify-content: center;

      canvas {
        width: 28rem !important;
        height: 28rem !important;
        cursor: pointer;
      }

      &.Fullsceen {
        canvas {
          width: 40rem !important;
          height: 40rem !important;
          cursor: pointer;
        }
      }
    }

    .DataTableSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
  .StatisticsWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.6rem;
    gap: 0.5rem;

    .ChartSection {
      display: flex;
      flex: 1;
      height: 54vh;
      height: 54dvh;
      align-items: center;
      justify-content: center;

      canvas {
        width: 24rem !important;
        height: 24rem !important;
        cursor: pointer;
      }

      &.Fullsceen {
        height: 65vh;
        height: 65dvh;

        canvas {
          width: 28rem !important;
          height: 28rem !important;
          cursor: pointer;
        }
      }
    }

    .DataTableSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .StatisticsWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.6rem;
    gap: 0.5rem;

    .ChartSection {
      display: flex;
      flex: 1;
      height: 54vh;
      height: 54dvh;
      align-items: center;
      justify-content: center;

      canvas {
        width: 25rem !important;
        height: 25rem !important;
        cursor: pointer;
      }
    }

    .DataTableSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .StatisticsWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.1rem;
    gap: unset;

    .ChartSection {
      display: flex;
      flex: 1;
      height: 67vh;
      height: 67dvh;
      align-items: center;
      justify-content: center;

      canvas {
        width: 40rem !important;
        height: 40rem !important;
        cursor: pointer;
      }

      &.Fullsceen {
        canvas {
          width: 43rem !important;
          height: 43rem !important;
          cursor: pointer;
        }
      }
    }

    .DataTableSection {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}

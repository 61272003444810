@import '../../../../../sass/manifest';

.FlowBarContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1rem;

  p {
    @include introParagraph;
    max-width: 95%;
    color: $v2maingreen;
  }
}

.CheckoutPageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0 0 0;
  align-items: center;

  .ContentContainer {
    display: flex;
    flex-direction: column;
    width: 99%;
    background-color: #fff;

    &.TransBackground {
      background-color: transparent;

      .MovableContentContainer {
        .ContentWrapper {
          padding: 1rem 0;
        }
      }
    }

    .HeadingSection {
      display: flex;
      justify-content: space-between;
      padding: 0.9rem 0.6rem;
      background-color: $v2maingreen;
      color: #fff;

      .LogoContainer {
        width: 8rem;

        img {
          max-width: 100%;
        }
      }
    }

    .SectionBars {
      display: flex;

      button {
        flex: 1;
        padding: 0.65rem 0;
        border: 0 none;
        background-color: #fff;
        color: $v2maingreen;
        font-weight: 500;
        cursor: pointer;
        border-right: 1px solid $v2maingreen;
        font-size: 0.8rem;

        &.Selected {
          background-color: $v2maingreen;
          color: #fff;
          border-right: unset;
        }

        &:last-child {
          border-right: unset;
        }

        &:disabled {
          opacity: 0.25;
          cursor: not-allowed;
          background-color: $lightGray;
          color: $v2maingreen;
        }
      }
    }

    .MovableContentContainer {
      display: flex;
      width: 100%;
      overflow-x: auto;
      scrollbar-width: none;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .LoadingWrapper {
        display: flex;
        min-height: 45vh;
        min-height: 45dvh;
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      .ContentWrapper {
        display: flex;
        width: 100%;
        // background-color: #fff;
        padding: 1rem 0.6rem;

        .Content {
          display: flex;
          flex-direction: column;
          width: 100%;
          min-width: 100%;
          padding: 0.4rem 2rem;
          scroll-snap-align: center;

          p {
            width: 100%;
          }
        }
      }
    }

    .PageActions {
      width: 100%;
      padding: 0.8rem 1.05rem;
      margin-top: 1.05rem;
      justify-content: space-between;
      background-color: #fff;

      .Button {
        width: 16rem;
        text-align: center;
        font-size: 0.9rem;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
  .FlowBarContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 1rem;

    p {
      @include introParagraph;
      max-width: 68%;
      color: $v2maingreen;
    }
  }

  .CheckoutPageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.5rem 0 0 0;
    align-items: center;

    .ContentContainer {
      display: flex;
      flex-direction: column;
      width: 74%;
      background-color: #fff;

      &.TransBackground {
        background-color: transparent;

        .MovableContentContainer {
          .ContentWrapper {
            padding: 1rem 0;
          }
        }
      }

      .HeadingSection {
        display: flex;
        justify-content: space-between;
        padding: 0.9rem 0.6rem;
        background-color: $v2maingreen;
        color: #fff;

        .LogoContainer {
          width: 8rem;

          img {
            max-width: 100%;
          }
        }
      }

      .SectionBars {
        display: flex;

        button {
          flex: 1;
          padding: 0.65rem 0;
          border: 0 none;
          background-color: #fff;
          color: $v2maingreen;
          font-weight: 600;
          cursor: pointer;
          border-right: 1px solid $v2maingreen;
          font-size: 0.87rem;

          &.Selected {
            background-color: $v2maingreen;
            color: #fff;
            border-right: unset;
          }

          &:last-child {
            border-right: unset;
          }

          &:disabled {
            opacity: 0.25;
            cursor: not-allowed;
            background-color: $lightGray;
            color: $v2maingreen;
          }
        }
      }

      .MovableContentContainer {
        display: flex;
        width: 100%;
        overflow-x: auto;
        scrollbar-width: none;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }

        .LoadingWrapper {
          display: flex;
          min-height: 45vh;
          min-height: 45dvh;
          width: 100%;
          align-items: center;
          justify-content: center;
        }

        .ContentWrapper {
          display: flex;
          width: 100%;
          // background-color: #fff;
          padding: 1rem 0.6rem;

          .Content {
            display: flex;
            flex-direction: column;
            width: 100%;
            min-width: 100%;
            padding: 0.4rem 2rem;
            scroll-snap-align: center;

            p {
              width: 100%;
            }
          }
        }
      }

      .PageActions {
        width: 100%;
        padding: 0.8rem 1.05rem;
        margin-top: 1.05rem;
        justify-content: space-between;
        background-color: #fff;

        .Button {
          width: 16rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .FlowBarContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 1rem;

    p {
      @include introParagraph;
      max-width: 50%;
      color: $v2maingreen;
    }
  }

  .CheckoutPageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2.5rem 0 0 0;
    align-items: center;

    .ContentContainer {
      display: flex;
      flex-direction: column;
      width: 68%;
      background-color: #fff;

      &.TransBackground {
        background-color: transparent;

        .MovableContentContainer {
          .ContentWrapper {
            padding: 1rem 0;
          }
        }
      }

      .HeadingSection {
        display: flex;
        justify-content: space-between;
        padding: 0.9rem 0.6rem;
        background-color: $v2maingreen;
        color: #fff;

        .LogoContainer {
          width: 8rem;

          img {
            max-width: 100%;
          }
        }
      }

      .SectionBars {
        display: flex;

        button {
          flex: 1;
          padding: 0.65rem 0;
          border: 0 none;
          background-color: #fff;
          color: $v2maingreen;
          font-weight: 600;
          cursor: pointer;
          border-right: 1px solid $v2maingreen;
          font-size: 0.9rem;

          &.Selected {
            background-color: $v2maingreen;
            color: #fff;
            border-right: unset;
          }

          &:last-child {
            border-right: unset;
          }

          &:disabled {
            opacity: 0.25;
            cursor: not-allowed;
            background-color: $lightGray;
            color: $v2maingreen;
          }
        }
      }

      .MovableContentContainer {
        display: flex;
        width: 100%;
        overflow-x: auto;
        scrollbar-width: none;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }

        .LoadingWrapper {
          display: flex;
          min-height: 45vh;
          min-height: 45dvh;
          width: 100%;
          align-items: center;
          justify-content: center;
        }

        .ContentWrapper {
          display: flex;
          width: 100%;
          // background-color: #fff;
          padding: 1rem 0.6rem;

          .Content {
            display: flex;
            flex-direction: column;
            width: 100%;
            min-width: 100%;
            padding: 0.4rem 2rem;
            scroll-snap-align: center;

            p {
              width: 100%;
            }
          }
        }
      }

      .PageActions {
        width: 100%;
        padding: 0.8rem 1.05rem;
        margin-top: 1.05rem;
        justify-content: space-between;
        background-color: #fff;

        .Button {
          width: 16rem;
        }
      }
    }
  }
}

@import '../../../../../sass/manifest';

.MainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  min-height: 50vh;
  min-height: 50dvh;

  .ResendVerificationEmailContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .HeadingSection {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 1.5rem;

      h3 {
        font-size: 1.6rem;
        align-self: flex-end;
        color: $v2maingreen;
        text-transform: uppercase;
      }

      .ImageContainer {
        display: flex;
        width: 5rem;
        height: 5rem;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    p {
      font-size: 0.8rem;
      text-align: justify;
      hyphens: auto;
      padding: 0 0 0.6rem 0;
      color: $v2maingreen;
      line-height: 1.5;
    }

    .ResendActions {
      gap: 1.5rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .MainContainer {
    max-width: 60%;
    .ResendVerificationEmailContainer {
      p {
        font-size: 1.09rem;
      }
    }
  }
}

@import '../../../../sass/manifest';

.NotificationWindowContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.6rem 0.5rem;
  max-height: inherit;
  overflow-y: auto;
  overflow-x: hidden;

  @include scroll-Y;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .NotificationWindowContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0.8rem 1.05rem;
    max-height: inherit;
    overflow-y: auto;
    overflow-x: hidden;

    @include scroll-Y;
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

@media only screen and (min-width: 1920px) {
}

.Backdrop {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 0;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
  transition: all 0.1s ease-in-out;
  pointer-events: none;

  &.Show {
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 20000;
    left: 0;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    pointer-events: unset;
  }
}

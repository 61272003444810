@import '../../../../../sass/manifest';

.VisitorsListLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  min-height: 10rem;
}

.Action {
  justify-content: flex-end;
  padding: 1.5rem 0.3rem;
  gap: 0.9rem;
}

.ObservedTypeField {
  display: flex;
  padding: 0.75rem 0 0 0;
  align-items: center;
  gap: 0.75rem;

  svg {
    color: $periopsisOrange;
    font-size: 0.92rem;
  }
}

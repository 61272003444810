.CyprusMapDistricts {
  fill: rgb(0, 76, 108);
  stroke-width: 1.04412px;
  transition: all 0.3s linear;
  cursor: pointer;

  &:hover {
    fill: rgb(4, 121, 172);
  }

  &.Selected {
    fill: #0b91cb;
    fill: #8aa8b2;
  }

  &.ShowOnlySelected {
    transform: scale(1.5) translate(15px, 5px);
    transform-origin: center;
  }

  &.HideUnSelected {
    transform: scale(0);
    opacity: 0;
  }
}

.DistrictNames {
  font-size: 0.65rem;
  fill: #fff;
  font-weight: 500;
  cursor: pointer;
}

//  style='fill: rgb(0, 76, 108); stroke-width: 1.04412px'

@import '../../../../../sass/manifest';

.TimelineListWrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .TimelineListContainer {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0.3rem 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scroll-snap-type: x mandatory;

    &.RemoveScroll {
      overflow: hidden;
      align-items: center;
      justify-content: center;
    }

    .DirectionButton {
      position: absolute;
      border: 0 none;
      background-color: transparent;
      z-index: 100;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      transition: all 0.25s linear;

      &:hover {
        transform: translate(0, -50%) scale(1.15);

        svg {
          color: lighten($v2maingreen, 10%);
        }
      }

      svg {
        color: $v2maingreen;
        font-size: 2rem;
      }

      &.Left {
        left: 0.5rem;
      }

      &.Right {
        right: 0.5rem;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

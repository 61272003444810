@import '../../../sass/variables.scss';
@import '../../../sass/mixins.scss';

.MapDirectionBoardContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  max-width: 100vw;
  max-width: 100dvw;
  z-index: 25;
  background-color: #fff;
  height: 100%;
  box-shadow: 1px 2px 4px black;
  left: -450px;
  transition: all 0.3s ease-in-out;
  top: 0;

  .MobileCloseButton {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    svg {
      font-size: 1.3rem;
      cursor: pointer;
    }
  }

  &.Show {
    left: 0.1px;
  }

  .LocationEntrySection {
    display: flex;
    flex-direction: column;
    padding: 1rem 0.5rem 2rem 0.5rem;
    row-gap: 0.8rem;
    border-bottom: 1px solid lighten($gray, 5);
    margin: 0 0.4rem;

    .LocationEntry {
      display: flex;
      align-items: center;

      & > div {
        width: 86%;
      }

      svg {
        margin: 0;
        width: fit-content;
        margin-right: 0.5rem;
      }
    }

    .LocationOptions {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .OptionButton {
        display: flex;
        padding: 0.2rem 0.6rem;
        color: $v2maingreen;
        background-color: transparent;
        border: 1px solid $v2maingreen;
        border-radius: 10px;
        cursor: pointer;

        &.Unavailable {
          background-color: #f0f0f0;
          border: 1px solid $lightGray;
          color: $lightGray;
          cursor: pointer;
        }
      }
    }
  }

  .Directions {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .DirectionSteps {
      @include scroll-Y;
      display: flex;
      flex-direction: column;
      padding: 0.6rem;
      row-gap: 0.8rem;
      margin-top: 0.4rem;
    }
  }

  .DataSummarySection {
    padding: 0.5rem;
    margin: 0.3rem 0.4rem;
    border-radius: 0.3rem;
  }

  .HideButton {
    position: absolute;
    top: 50%;
    right: -29px;
    transform: translate(0, -50%);
    z-index: 8;

    button {
      padding: 1rem 0.5rem;
      border: 0 none;
      background-color: #fff;
      box-shadow: 3px 1px 3px black;
      border-radius: 0 0.4rem 0.4rem 0;
      cursor: pointer;
      color: $v2maingreen;
    }
  }
}

@media (min-width: 460px) {
  .MapDirectionBoardContainer {
    width: 320px;
    max-width: 100vw;
    height: 100%;
    left: -326px;
    transition: all 0.3s ease-in-out;

    .MobileCloseButton {
      display: none;
    }

    &.Show {
      left: 0.1px;
    }

    .LocationEntrySection {
      display: flex;
      flex-direction: column;
      padding: 1rem 0.5rem 2rem 0.5rem;
      row-gap: 0.8rem;
      border-bottom: 1px solid lighten($gray, 5);
      margin: 0 0.4rem;

      .LocationEntry {
        display: flex;
        align-items: center;

        & > div {
          width: 86%;
        }

        svg {
          margin: 0;
          width: fit-content;
          margin-right: 0.5rem;
        }
      }
    }

    .Directions {
      display: flex;
      flex-direction: column;
      overflow: auto;

      .DirectionSteps {
        @include scroll-Y;
        display: flex;
        flex-direction: column;
        padding: 0.6rem;
        row-gap: 0.8rem;
        margin-top: 0.4rem;
      }
    }

    .DataSummarySection {
      padding: 0.5rem;
      margin: 0.3rem 0.4rem;

      border-radius: 0.3rem;
    }

    .HideButton {
      position: absolute;
      top: 50%;
      right: -29px;
      transform: translate(0, -50%);

      button {
        padding: 1rem 0.5rem;
        border: 0 none;
        background-color: #fff;
        border-radius: 0 0.4rem 0.4rem 0;
        cursor: pointer;
        box-shadow: 3px 1px 3px black;
        color: $v2maingreen;
      }
    }
  }
}

@media (min-width: 640px) {
  .MapDirectionBoardContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 480px;
    max-width: 100vw;
    max-width: 100dvw;
    z-index: 8;
    background-color: #fff;
    height: 100%;
    left: -480px;
    transition: all 0.3s ease-in-out;

    .MobileCloseButton {
      display: none;
    }

    &.Show {
      left: 0.1px;
      position: fixed;
      top: 0;
      z-index: 120;
    }

    .LocationEntrySection {
      display: flex;
      flex-direction: column;
      padding: 1rem 0.5rem 2rem 0.5rem;
      row-gap: 0.8rem;
      border-bottom: 1px solid lighten($gray, 5);
      margin: 0 0.4rem;

      .LocationEntry {
        display: flex;
        align-items: center;

        & > div {
          width: 86%;
        }

        svg {
          margin: 0;
          width: fit-content;
          margin-right: 0.5rem;
        }
      }
    }

    .Directions {
      display: flex;
      flex-direction: column;
      overflow: auto;

      .DirectionSteps {
        @include scroll-Y;
        display: flex;
        flex-direction: column;
        padding: 0.6rem;
        row-gap: 0.8rem;
        margin-top: 0.4rem;
      }
    }

    .DataSummarySection {
      padding: 0.5rem;
      margin: 0.3rem 0.4rem;
      border-radius: 0.3rem;
    }

    .HideButton {
      position: absolute;
      top: 50%;
      right: -29px;
      transform: translate(0, -50%);

      button {
        padding: 1rem 0.5rem;
        border: 0 none;
        background-color: #fff;
        box-shadow: 3px 1px 3px black;
        border-radius: 0 0.4rem 0.4rem 0;
        cursor: pointer;
        color: $v2maingreen;
      }
    }
  }
}

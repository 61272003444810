@import '../../../sass/manifest';

.FormSectionContainer {
  display: flex;
  flex-direction: column;
  padding: 1.05rem;
  border: 1px solid $lightGray;
  border-radius: 8px;
  margin: 0.35rem 0;
  gap: 0.8rem;

  h3 {
    color: $v2maingreen;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .AddressTypeSelectionNote {
    padding: 0.5rem 0;
    line-height: 1.35;
    font-size: 0.9rem;
    color: $v2maingreen;

    &::first-letter {
      font-weight: 600;
      font-size: 0.98rem;
    }
  }
}

@import '../../../../../../sass/manifest';

.RegisteredUserData {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  margin-top: 1.6rem;

  .DownloadSection {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .DownloadLink {
      text-decoration: none;
      color: $v2maingreen;
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      gap: 0.3rem;
      padding: 0.3rem;
    }
  }

  .MessageSection {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    color: $v2maingreen;

    .Message {
      text-align: justify;
      hyphens: auto;
      @include introParagraph;
    }
  }

  .RegistrationData {
    list-style: none;
    padding: 0.5rem 0;

    .PinSection {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 0.95rem;
      width: 100%;
      .PinMessage {
        @include introParagraph;
        text-align: justify;
        hyphens: auto;
        color: $lightGray;
      }

      .PinRow {
        grid-template-columns: 1fr 8px max-content;
      }
    }
  }
}

@import '../../../../../sass/manifest';

.MainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .IncidentListContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    width: max-content;
    margin: 0;
    padding: 0.5rem 0 1.5rem 0;
    justify-items: center;

    .ItemContainer {
      display: flex;
      flex-direction: column;
      width: max-content;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .MainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: unset;

    .IncidentListContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.8rem;
      width: max-content;
      margin: 0 auto;
      padding: 0.3rem 0 1rem 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  .MainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .IncidentListContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.8rem;
      width: max-content;
      margin: 0 auto;
      padding: 0.3rem 0 1rem 0;
    }
  }
}

@media only screen and (min-width: 900px) {
  .MainContainer {
    .IncidentListContainer {
      grid-template-columns: repeat(3, 1fr);
      gap: 0.85rem;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .MainContainer {
    .IncidentListContainer {
      grid-template-columns: repeat(3, 1fr);
      gap: 0.8rem;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .MainContainer {
    .IncidentListContainer {
      gap: 0.85rem;
    }
  }
}

@media only screen and (min-width: 1360px) {
  .MainContainer {
    .IncidentListContainer {
      grid-template-columns: repeat(4, 1fr);
      gap: 0.85rem;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .MainContainer {
    .IncidentListContainer {
      grid-template-columns: repeat(4, 1fr);
      gap: 0.85rem;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .MainContainer {
    .IncidentListContainer {
      grid-template-columns: repeat(4, 1fr);
      gap: 1.2rem;
    }
  }
}

@import '../../../../sass/manifest';

.PasswordUpdate {
  display: flex;
  min-height: 90vh;
  min-height: 90dvh;
  justify-content: center;
  max-width: 98%;
}

.InputElements {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1.3rem;
}

.UpdateSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $v2maingreen;
  padding-top: 4.5rem;

  h2 {
    font-size: 1.35rem;
    padding: 0.9rem 0;
  }

  p {
    font-size: 0.92rem;
    max-width: 92%;
    padding: 0.9rem 0;
    text-align: center;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
}

@media only screen and (min-width: 600px) {
  .PasswordUpdate {
    max-width: 90%;
  }

  .UpdateSuccess {
    padding-top: 2rem;

    h2 {
      font-size: 1.2rem;
      padding: 0.9rem 0;
    }

    p {
      font-size: 1rem;
      max-width: 80%;
    }
  }
}

@media only screen and (min-width: 768px) {
  .PasswordUpdate {
    max-width: 70%;
    min-height: 80vh;
    min-height: 80dvh;
  }

  .UpdateSuccess {
    padding-top: 5rem;

    h2 {
      font-size: 1.45rem;
      padding: 0.9rem 0;
    }

    p {
      font-size: 1.05rem;
      max-width: 70%;
    }
  }
}

@media only screen and (min-width: 900px) {
  .PasswordUpdate {
    max-width: 60%;
    min-height: 75vh;
    min-height: 75dvh;
  }
}

@media only screen and (min-width: 1200px) {
  .PasswordUpdate {
    max-width: 58%;
    min-height: 70vh;
    min-height: 70dvh;
  }

  .UpdateSuccess {
    padding-top: unset;

    h2 {
      font-size: 1.75rem;
      padding: 0.9rem 0;
    }

    p {
      font-size: 1.15rem;
      max-width: 50%;
    }
  }
}

@media only screen and (min-width: 1536px) {
  .PasswordUpdate {
    max-width: 53%;
  }

  .UpdateSuccess {
    h2 {
      font-size: 1.85rem;
      padding: 1rem 0;
    }

    p {
      font-size: 1.18rem;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .PasswordUpdate {
    max-width: 48%;
  }
}
